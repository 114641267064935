import { useTranslation } from 'react-i18next';

import FraudCasesPreview from '../modules/fraudCases/UI/FraudCasesPreview';
import Meta from '../components/layout/Meta';
import PageLayout from '../layouts/PageLayout';

import { usePermission } from '../hooks/usePermission';
import { PermissionsEnum } from '../types';

function HomePage() {
  const { t } = useTranslation();
  const canViewFraudCases = usePermission([PermissionsEnum.FraudcasesList]);

  return (
    <>
      <Meta />
      <PageLayout title={t('fraud_cases.title_latests')}>
        {canViewFraudCases && <FraudCasesPreview title="" />}
      </PageLayout>
    </>
  );
}

export default HomePage;
