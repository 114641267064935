import { useTranslation } from 'react-i18next';
import ChatSkeleton from './ChatSkeleton';

const StartNewChat = () => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center justify-center h-full w-full flex-col gap-4   ">
      <div className="flex flex-col gap-3">
        <ChatSkeleton
          iconName="bell"
          type="white"
          wrapperClassNames="translate-x-2"
        />
        <ChatSkeleton
          iconName="infoNoOutline"
          type="gray"
          wrapperClassNames="-translate-x-2"
        />
        <ChatSkeleton
          iconName="present"
          type="white"
          wrapperClassNames="translate-x-2"
        />
      </div>
      <div className="">
        <p className="font-bold">{t('chat.start_new_conversation')}</p>
        <p>{t('chat.type_your_first_message_below')}</p>
      </div>
    </div>
  );
};

export default StartNewChat;
