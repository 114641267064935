import {
  getAuthStateFromLocalStorage,
  handleErrors,
  setHeaders,
} from '../../../utils';
import AuthApi from './auth.api';
import { translate as t } from '../../../config/i18n.config';

import {
  ActionResponse,
  AvailableRegionInterface,
  AvailableRegionsInterface,
  ErrorResponse,
} from '../../../types';
import { LoginResponseDataInterface } from './auth.types';
import { AxiosError } from 'axios';
import { globalWS } from '../../../init';
import {
  ACTIVE_REGION_KEY,
  AUTH_KEY,
  PERMISSIONS_KEY,
} from '../../../constants';
import queryClient from '../../../config/tanstackQueryConfig';

const api: AuthApi = new AuthApi();

class AuthStore {
  authenticateUser = async (
    email: string,
    password: string
  ): Promise<ActionResponse<LoginResponseDataInterface>> => {
    try {
      const res = await api.authenticateUser(email, password);
      const resData = res.data as LoginResponseDataInterface;
      const successMessage = t('auth.login_success');

      return {
        success: true,
        message: successMessage,
        data: resData,
      };
    } catch (error) {
      const { success, message } = handleErrors(error);
      return {
        success,
        message,
      };
    }
  };

  logoutUser = async () => {
    try {
      await globalWS.disconnect();
      const res = await api.logoutUser();
      const resData = res.data;
      const successMessage = t('auth.logout_success');
      localStorage.removeItem(AUTH_KEY);
      return {
        success: true,
        message: successMessage,
        data: resData,
      };
    } catch (error) {
      const { success, message } = handleErrors(error);
      return {
        success,
        message,
      };
    }
  };

  verifyCode = async (otpCode: string): Promise<ActionResponse<any>> => {
    try {
      const res = await api.verifyCode(otpCode);
      const resData = res.data as any;
      // await globalWS.connect(resData.access_token, resData.user_id);
      const successMessage = t('auth.welcome_message');

      return {
        success: true,
        message: successMessage,
        data: resData,
      };
    } catch (error) {
      const resError = error as AxiosError;
      const data = resError?.response?.data;

      interface ErrorResponseWithRes extends ErrorResponse {
        response?: string;
      }

      if (data) {
        const { message, response, success, code } =
          data as ErrorResponseWithRes;
        return {
          message: message || response || t('error'),
          success,
          code: code,
        };
      } else {
        return {
          message: t('error'),
          success: false,
        };
      }
    }
  };

  forgetPassword = async (email: string): Promise<ActionResponse<any>> => {
    try {
      const res = await api.forgetPassword(email);
      const resData = res.data as any;
      const successMessage = resData?.message || t('auth.welcome_message');

      return {
        success: true,
        message: successMessage,
        data: resData,
      };
    } catch (error) {
      const { success, message } = handleErrors(error);
      return {
        success,
        message,
      };
    }
  };

  resetPassword = async (
    token: undefined | string,
    password: string,
    repeatedPassword: string
  ) => {
    try {
      const res = await api.resetPassword(token, password, repeatedPassword);
      const resData = res.data as any;
      const successMessage = resData
        ? resData?.message || t('auth.password_reset_success')
        : t('error');

      return {
        success: true,
        message: successMessage,
        data: resData,
      };
    } catch (error) {
      const { success, message } = handleErrors(error);
      return {
        success,
        message,
      };
    }
  };

  createPassword = async (
    token: undefined | string,
    password: string,
    repeatedPassword: string
  ) => {
    try {
      const res = await api.createPassword(token, password, repeatedPassword);
      const resData = res.data as any;
      const successMessage = resData
        ? resData?.message || t('auth.password_create_success')
        : t('error');

      return {
        success: true,
        message: successMessage,
        data: resData,
      };
    } catch (error) {
      const { success, message } = handleErrors(error);
      return {
        success,
        message,
      };
    }
  };

  refreshToken = async ({
    invalidate = true,
  }): Promise<ActionResponse<any>> => {
    const { refreshToken: refreshTokenValue } =
      getAuthStateFromLocalStorage() || {};

    if (!refreshTokenValue) {
      localStorage.removeItem(AUTH_KEY);
      return { success: false, message: t('auth.no_refresh_token') };
    }

    try {
      setHeaders({ Authorization: `Bearer ${refreshTokenValue}` });
      const res = await api.refreshToken();
      const resData = res.data;
      const auth = {
        token: resData.access_token,
        expiresAt: resData.expires_at,
        refreshToken: resData.refresh_token,
        userId: resData.user_id,
        user: resData.user,
      };

      const regions = resData.regions;
      const transformedRegions: AvailableRegionsInterface = {
        allRegions: regions.allRegions,
        regions: regions.regions
          .filter((region: AvailableRegionInterface) => region.status === 1) // Filter regions with status 1
          .map((region: AvailableRegionInterface) => ({
            id: region.id,
            name: region.name,
          })),
        activeRegion: resData.activeRegion
          ? resData.activeRegion
          : localStorage.getItem(ACTIVE_REGION_KEY)
          ? parseInt(localStorage.getItem(ACTIVE_REGION_KEY) as string)
          : regions.regions.length > 0
          ? regions.regions[0].id
          : undefined,
      };

      // Seth auth to local storage
      localStorage.setItem(AUTH_KEY, JSON.stringify(auth));
      // set regions to local storage
      localStorage.setItem('regions', JSON.stringify(transformedRegions));
      // set permissions to local storage
      localStorage.setItem(
        PERMISSIONS_KEY,
        JSON.stringify(resData.permissions)
      );
      // set active region key
      transformedRegions.activeRegion &&
        localStorage.setItem(
          ACTIVE_REGION_KEY,
          transformedRegions.activeRegion.toString()
        );

      const successMessage =
        resData?.message || t('auth.session_refreshed_success');

      setHeaders({ Authorization: `Bearer ${auth.token}` });
      invalidate && (await queryClient.invalidateQueries());

      return {
        success: true,
        message: successMessage,
        data: resData,
      };
    } catch (error) {
      localStorage.removeItem(AUTH_KEY);
      const { success, message } = handleErrors(error);
      return {
        success,
        message,
      };
    }
  };
}

export default AuthStore;
