import {
  keepPreviousData,
  QueryKey,
  queryOptions,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { translate as t } from '../../../config/i18n.config';
import queryClient from '../../../config/tanstackQueryConfig';
import {
  ActionResponse,
  ErrorResponse,
  UploadPercentage,
  UploadFileInterface,
} from '../../../types';
import { handleErrors } from '../../../utils';
import { QUERY_KEY as searchQueryKey } from '../../search/services/search.store';
import { QUERY_KEY_STATISTICS } from '../../statistics/services/statistics.store';
import FraudCasesApi from './fraudCases.api';
import { FraudCaseReportEnum } from './fraudCases.types';

const QUERY_KEY = 'fraud-cases';
const QUERY_KEY_TYPES = 'fraud-cases-types';
const QUERY_KEY_CARD_TYPES = 'fraud-case-card-types';
const QUERY_KEY_TERMINAL_TYPES = 'fraud-cases-terminal-types';
const QUERY_KEY_COMMENTS = 'fraud-case-comments';
const QUERY_KEY_FILE = 'fraud-case-file';

const api: FraudCasesApi = new FraudCasesApi();

class FraudCaseStore {
  preloadFraudCases = async (
    page?: number,
    category?: string,
    organization?: any
  ) => {
    return await queryClient.fetchQuery(
      fraudCasesQueryOptions(page, category, organization)
    );
  };

  useGetFraudCases = (page?: number, category?: string, organization?: any) => {
    return useQuery(fraudCasesQueryOptions(page, category, organization));
  };

  preloadFraudCase = async (id?: number) => {
    return await queryClient.fetchQuery(fraudCaseQueryOptions(id));
  };

  useGetFraudCase = (id?: number) => {
    return useQuery(fraudCaseQueryOptions(id));
  };

  useGetFraudCasesTypes = (enabled?: boolean | null) => {
    return useQuery(fraudCasesTypesQueryOptions(enabled));
  };

  useGetFraudCardTypes = (enabled?: boolean | null) => {
    return useQuery(fraudCasesCardTypesQueryOptions(enabled));
  };

  useGetFraudCasesTerminalTypes = (enabled?: boolean | null) => {
    return useQuery(fraudCasesTerminalTypesQueryOptions(enabled));
  };

  addFraudCase = async (
    data: FormData,
    onUpload?: (
      percentage: UploadPercentage,
      files?: UploadFileInterface[]
    ) => void
  ): Promise<ActionResponse<any>> => {
    try {
      const res = await api.addFraudCase(data, onUpload);
      const resData = res.data;
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [searchQueryKey] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_STATISTICS] });
      return {
        success: true,
        message:
          resData?.message || t('fraud_cases.fraud_case_successfully_added'),
        data: resData,
      };
    } catch (error) {
      const resError = error as AxiosError;
      const data = resError?.response?.data;
      const code = resError?.response?.request?.status || null;

      if (data) {
        const { message, success } = data as ErrorResponse;
        const newMessage = message || data;
        return {
          message: newMessage || t('error'),
          success,
          code,
        };
      } else {
        return {
          message: t('error'),
          success: false,
        };
      }
    }
  };

  editFraudCase = async (
    id: number,
    data: any
  ): Promise<ActionResponse<any>> => {
    try {
      const res = await api.editFraudCase(id, data);
      const resData = res.data;
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY, { id }] });
      return {
        success: true,
        message:
          resData?.message || t('fraud_cases.fraud_case_successfully_edited'),
        data: resData,
      };
    } catch (error) {
      const { message, success } = handleErrors(error);
      return { message, success };
    }
  };

  getFraudCaseReports = async ({
    payload,
  }: {
    payload: FormData;
  }): Promise<ActionResponse<Blob>> => {
    try {
      const res = await api.getFraudCaseReports(payload);
      const resData = res.data;

      return {
        success: true,
        message:
          resData?.message ||
          t('fraud_cases.fraud_case_report_successfuly_retrieved'),
        data: resData,
      };
    } catch (error) {
      const { message, success } = handleErrors(error);
      return { message, success };
    }
  };

  getFraudCaseReport = async ({
    id,
    type,
  }: {
    id: string;
    type: FraudCaseReportEnum;
  }): Promise<ActionResponse<Blob>> => {
    try {
      const res = await api.getFraudCaseReport({ id, type });
      const resData = res.data;

      return {
        success: true,
        message:
          resData?.message ||
          t('fraud_cases.fraud_case_report_successfuly_retrieved'),
        data: resData,
      };
    } catch (error) {
      const { message, success } = handleErrors(error);
      return { message, success };
    }
  };

  /* Comments */
  useGetFraudCaseComments = ({
    id,
    page,
    rowsPerPage = 5,
    enabled = true,
  }: {
    id?: string;
    page?: number;
    rowsPerPage?: number;
    enabled?: boolean | null;
  }) => {
    return useQuery(
      fraudCasesCommentsQueryOptions({ id, page, rowsPerPage, enabled })
    );
  };

  addComment = async (id: number, data: any) => {
    try {
      const res = await api.addFraudCaseComment(id, data);
      const resData = res.data;
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_COMMENTS],
      });
      return {
        success: true,
        message: resData?.message || t('comments.comment_successfully_added'),
        data: resData,
      };
    } catch (error) {
      const { message, success } = handleErrors(error);
      return { message, success };
    }
  };

  deleteComment = async (id: string) => {
    try {
      const res = await api.deleteFraudCaseComment(id);
      const resData = res.data;
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_COMMENTS],
      });
      return {
        success: true,
        message: resData?.message || t('comments.comment_successfully_deleted'),
        data: resData,
      };
    } catch (error) {
      const { message, success } = handleErrors(error);
      return { message, success };
    }
  };

  /* Files */
  useGetFraudCaseFiles = ({
    id,
    enabled = true,
  }: {
    id: number;
    enabled?: boolean | null;
  }) => {
    return useQuery(fraudCaseFileOptions({ id, enabled }));
  };

  useGetFraudCaseFilesInfinite = ({
    id,
    enabled = true,
    page = 1,
  }: {
    id: number;
    enabled?: boolean | null;
    page: number;
  }) => {
    return useInfiniteQuery(
      fraudCaseFilesInfiniteQueryObject({ id, enabled, page })
    );
  };

  getFraudCaseFile = async (fileId: number) => {
    try {
      const res: any = await api.getFraudCaseFile(fileId);
      const fileUrl = window.URL.createObjectURL(res.data);
      return fileUrl;
    } catch (error) {
      console.error('error');
    }
  };

  deleteFraudCaseFile = async (fileId: number) => {
    try {
      const res = await api.deleteFraudCaseFile(fileId);
      const resData = res.data;
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_FILE],
      });
      queryClient.invalidateQueries({ queryKey: [searchQueryKey] });
      return {
        success: true,
        message: resData?.message || t('files.file_successfully_deleted'),
        data: resData,
      };
    } catch (error) {
      const { message, success } = handleErrors(error);
      return { message, success };
    }
  };

  addFraudCaseFiles = async ({
    id,
    data,
    onUpload,
  }: {
    id: number;
    data: any;
    onUpload: (
      percentage: UploadPercentage,
      files?: UploadFileInterface[]
    ) => void;
  }) => {
    try {
      const docs = data?.documents;

      if (docs.length === 0) {
        return {
          success: false,
          message: null,
        };
      }

      const res = await api.addFraudCaseFiles(id, data, onUpload);
      const resData = res.data;

      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_FILE],
      });
      queryClient.invalidateQueries({ queryKey: [searchQueryKey] });

      const hasMultipleDocs = Array.isArray(docs) && docs.length > 1;
      const successMsg = hasMultipleDocs
        ? t('documents.documents_successfully_uploaded')
        : t('documents.document_successfully_uploaded');

      return {
        success: true,
        message: resData?.message || successMsg,
        data: resData,
      };
    } catch (error) {
      const resError = error as AxiosError;
      const data = resError?.response?.data;
      const code = resError?.response?.request?.status || null;

      if (data) {
        const { message, success } = data as ErrorResponse;
        const newMessage = message || data;

        return {
          message: newMessage || t('error'),
          success,
          code: code,
        };
      } else {
        return {
          message: t('error'),
          success: false,
        };
      }
    }
  };
}

export default FraudCaseStore;

/**
 * Returns query options for fetching fraud cases with a standard query.
 *
 * @returns {UseQueryOptions<any, Error>} The query options object for use with `useQuery`.
 */

export const fraudCasesQueryOptions = (
  page?: number,
  category?: string,
  organization?: any
): UseQueryOptions<any, Error> =>
  queryOptions({
    queryKey: [QUERY_KEY, { page, category, organization }] as QueryKey,
    queryFn: async (): Promise<any> => {
      const response: AxiosResponse<any> = await api.getFraudCases(
        page,
        category,
        organization
      );
      return response.data;
    },
    placeholderData: keepPreviousData,
  });

/**
 * Returns query options for fetching single fraud case with a standard query.
 *
 * @returns {UseQueryOptions<any, Error>} The query options object for use with `useQuery`.
 */
export const fraudCaseQueryOptions = (
  id?: number
): UseQueryOptions<any, Error> =>
  queryOptions({
    queryKey: [QUERY_KEY, { id }] as QueryKey,
    queryFn: async (): Promise<any> => {
      const response: AxiosResponse<any> = await api.getFraudCase(id);
      return response.data;
    },
    enabled: !!id,
    placeholderData: keepPreviousData,
  });

/**
 * Returns query options for fetching fraud cases types with a standard query.
 *
 * @returns {UseQueryOptions<any, Error>} The query options object for use with `useQuery`.
 */
export const fraudCasesTypesQueryOptions = (
  enabled: boolean | null = true
): UseQueryOptions<any, Error> =>
  queryOptions({
    queryKey: [QUERY_KEY_TYPES] as QueryKey,
    queryFn: async (): Promise<any> => {
      const response: AxiosResponse<any> = await api.getFraudCasesTypes();
      return response.data;
    },
    placeholderData: keepPreviousData,
    enabled: enabled ? true : false,
  });

/**
 * Returns query options for fetching fraud cases card types with a standard query.
 *
 * @returns {UseQueryOptions<any, Error>} The query options object for use with `useQuery`.
 */
export const fraudCasesCardTypesQueryOptions = (
  enabled: boolean | null = true
): UseQueryOptions<any, Error> =>
  queryOptions({
    queryKey: [QUERY_KEY_CARD_TYPES] as QueryKey,
    queryFn: async (): Promise<any> => {
      const response: AxiosResponse<any> = await api.getFraudCasesCardTypes();
      return response.data;
    },
    placeholderData: keepPreviousData,
    enabled: enabled ? true : false,
  });

/**
 * Returns query options for fetching fraud cases terminal types with a standard query.
 *
 * @returns {UseQueryOptions<any, Error>} The query options object for use with `useQuery`.
 */
export const fraudCasesTerminalTypesQueryOptions = (
  enabled: boolean | null = true
): UseQueryOptions<any, Error> =>
  queryOptions({
    queryKey: [QUERY_KEY_TERMINAL_TYPES] as QueryKey,
    queryFn: async (): Promise<any> => {
      const response: AxiosResponse<any> =
        await api.getFraudCasesTerminalTypes();
      return response.data;
    },
    placeholderData: keepPreviousData,
    enabled: enabled ? true : false,
  });

/**
 * Returns query options for fetching fraud case comments with a standard query.
 *
 * @returns {UseQueryOptions<any, Error>} The query options object for use with `useQuery`.
 */
export const fraudCasesCommentsQueryOptions = ({
  id,
  page,
  rowsPerPage,
  enabled = true,
}: {
  id?: string;
  page?: number;
  rowsPerPage?: number;
  enabled?: boolean | null;
}): UseQueryOptions<any, Error> =>
  queryOptions({
    queryKey: [QUERY_KEY_COMMENTS, { id, page }] as QueryKey,
    queryFn: async (): Promise<any> => {
      const response: AxiosResponse<any> = await api.getFraudCaseComments(
        Number(id),
        page,
        rowsPerPage
      );
      return response.data;
    },
    enabled: !!id && enabled ? true : false,
    placeholderData: keepPreviousData,
  });

const fraudCaseFileOptions = ({
  id,
  enabled,
}: {
  id: number;
  enabled?: boolean | null;
}): UseQueryOptions<any, Error> =>
  queryOptions({
    queryKey: [QUERY_KEY_FILE, { id }] as QueryKey,
    queryFn: async (): Promise<any> => {
      const response: AxiosResponse<any> = await api.getFraudCaseFiles({
        id: Number(id),
      });
      return response.data;
    },
    enabled: !!id && enabled ? true : false,
    placeholderData: keepPreviousData,
  });

export const fraudCaseFilesInfiniteQueryObject = ({
  id,
  enabled = true,
  page = 1,
}: {
  id: number;
  enabled?: boolean | null;
  page?: number;
}): UseInfiniteQueryOptions<any, Error> => ({
  queryKey: [QUERY_KEY_FILE, { id }], // Include `id` in the query key for caching
  queryFn: async ({ pageParam = page ?? 1 }) => {
    const pageToFetch = typeof pageParam === 'number' ? pageParam : page ?? 1;
    const response: AxiosResponse<any> = await api.getFraudCaseFiles({
      id,
      page: pageToFetch,
    });
    return response.data;
  },
  initialPageParam: page ?? 1, // Start fetching from page 1
  getNextPageParam: (lastPage) => {
    const { _page, _page_count } = lastPage;
    return _page < _page_count ? _page + 1 : undefined;
  },
  enabled: !!id && enabled ? true : false, // Only enable if `id` is valid and `enabled` is true
  placeholderData: keepPreviousData, // Keep previous data for smooth UI updates
});

// const deleteFraudCaseFileOptions = (fileId: number): UseQueryOptions<any, Error> => queryOptions({
// 	queryKey: [QUERY_KEY_FILE, { fileId }] as QueryKey,
// 	queryFn: async (): Promise<any> => {
// 		const response: AxiosResponse<any> = await api.deleteFraudCaseFile(
// 			Number(fileId)
// 		)
// 		return response.data
// 	},
// 	enabled: !!fileId,
// 	placeholderData: keepPreviousData
// 	})
