import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import Button from '../../components/shared/Button';
import Meta from '../../components/layout/Meta';
import PageLayout from '../../layouts/PageLayout';
import PageTitleWithActions from '../../layouts/PageLayout/TitleWithActions';
import DocumentReports from '../../modules/documents/UI';
import { PermissionsEnum } from '../../types';
import { useRedirect } from '../../hooks/useRedirect';
import { usePermission } from '../../hooks/usePermission';

function DocumentsPage() {
  const { t } = useTranslation();
  const { slug } = useParams<{ slug: string }>();
  const canCreateDocument = usePermission([PermissionsEnum.DocumentsCreate]);
  const canListDocuments = usePermission([PermissionsEnum.DocumentsList]);
  const shouldRedirect =
    canCreateDocument === false && canListDocuments === false;

  useRedirect({
    redirect: shouldRedirect,
  });

  const allowedSlugs = {
    decisions: t('documents.decisions'),
    workreports: t('documents.workreports'),
  };

  const pageTitle =
    allowedSlugs[slug as keyof typeof allowedSlugs] || t('documents.documents');

  return (
    <>
      <Meta title={pageTitle} />
      <PageLayout>
        <PageTitleWithActions title={pageTitle}>
          {slug &&
            Object.keys(allowedSlugs).includes(slug) &&
            canCreateDocument && (
              <Link to="add" replace className="focus:outline-none group">
                <Button tabIndex={-1}>{t('documents.new_document')}</Button>
              </Link>
            )}
        </PageTitleWithActions>
        {canListDocuments && <DocumentReports slug={slug} />}
      </PageLayout>
    </>
  );
}

export default DocumentsPage;
