import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { LanguageTypes } from '../types';

import en from '../locales/en.json';
import sr from '../locales/sr.json';
import sr_cyr from '../locales/sr_cyr.json';

const resources = {
  en: {
    translation: en,
  },
  sr: {
    translation: sr,
  },
  sr_cyr: {
    translation: sr_cyr,
  },
};

// Get the stored language from localStorage, default to 'sr' if not found
const savedLang = localStorage.getItem('lang') || LanguageTypes.SR_CYR;

i18n.use(initReactI18next).init({
  resources,
  lng: savedLang, // Initialize with the stored or default language
  fallbackLng: LanguageTypes.SR_CYR,
  keySeparator: '.',
  interpolation: {
    escapeValue: false,
  },
});

// Export `i18n` as the default export and `translate` (for hardcoded text) as a named export
export default i18n;
export const translate = i18n.t;
