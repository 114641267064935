import http from '../../../utils/http';
import { RoleFormInterface, PermissionsIdsInterface } from './role.types';

class RolesApi {
  /* Roles */
  async getRoles(page?: number) {
    const params = new URLSearchParams();

    if (page) {
      params.append('page', page.toString());
    }

    // Construct the full URL with the query string
    const url = `/roles${params.toString() ? `?${params.toString()}` : ''}`;

    return http.get(url);
  }

  async addRole({ name, status, allPermissions }: RoleFormInterface) {
    return http.post(`/roles`, {
      name,
      status: 1, //status
      allPermissions,
    });
  }

  async updateRole({ id, name, status, allPermissions }: RoleFormInterface) {
    return http.post(`/roles/${id}`, {
      name,
      status: 1, //status,
      allPermissions,
    });
  }

  async deleteRole(id: number) {
    return http.delete(`/roles/${id}`);
  }

  /* Permissions */
  async getPermissions(page?: number) {
    return http.get(`/permissions?page=${page}`);
  }

  async getPermissionsPerRole(id: number) {
    return http.get(`/roles/${id}/permissions`);
  }

  async updateRolePermissions(
    id: number,
    permissionIds: PermissionsIdsInterface
  ) {
    const formData = new FormData();

    permissionIds.forEach((permissionId) => {
      formData.append('permissionIds[]', permissionId.toString());
    });

    return http.post(`/roles/${id}/permissions`, formData);
  }
}

export default RolesApi;
