import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoaderData } from 'react-router-dom';
import { toast } from 'react-toastify';
import { UseInfiniteQueryResult } from '@tanstack/react-query';

import PageTitleWithActions from '../../../layouts/PageLayout/TitleWithActions';
import Button from '../../../components/shared/Button';
import { FakeCheckbox } from '../../../components/shared/CheckboxInput';
import InfoMessage from '../../../components/shared/InfoMessage';
import Modal, { ModalHandleInterface } from '../../../components/shared/Modal';
import PermissionsList from './PermissionsList';
import RoleForm from './RoleForm';
import RolesList from './RolesList';

import { useInfiniteScrollSentinel } from '../../../hooks/useInfiniteScroll';
import { usePermission } from '../../../hooks/usePermission';
import { PermissionsEnum } from '../../../types';
import { debounce, hasPermission, showToast } from '../../../utils';

import {
  InfinitePermissionsResponseDataInterface,
  RoleFormInterface,
  RoleInterface,
} from '../services/role.types';

import RolesStore from '../services/roles.store';
const rolesStore: RolesStore = new RolesStore();

interface RolesModalInterface {
  role?: RoleFormInterface;
  isOpen: boolean;
}

const INIT_FORM_OPTIONS: RolesModalInterface = {
  role: undefined,
  isOpen: false,
};

const Roles = () => {
  const { t } = useTranslation();
  /* Preloaded roles */
  const roles = useLoaderData() as RoleInterface[];

  /* Permissions */
  const canCreateRole = usePermission([PermissionsEnum.RoleCreate]);
  const canEditRole = usePermission([PermissionsEnum.RoleEdit]);
  const canDeleteRole = usePermission([PermissionsEnum.RoleDelete]);
  const canListPermissions = usePermission([PermissionsEnum.PermissionsList]);
  const canSetPermissions = usePermission([PermissionsEnum.RolePermissionsSet]);
  const canGetPermissionsPerRole = usePermission([
    PermissionsEnum.RolePermissionsGet,
  ]);

  const [selectedRole, setSelectedRole] = useState<number | undefined>(
    undefined
  );

  const selectedRoleName = roles?.find(
    (role) => role.id === selectedRole
  )?.name;

  /* Permissions per role */
  const { data: permissionsPerRoleData } = rolesStore.useGetPermissionsPerRole(
    selectedRole,
    canGetPermissionsPerRole ? true : false
  );
  const [permissionsPerRole, setPermissionPerRole] = useState<
    Record<number, number[]>
  >({});
  const [optPermissionsPerRoleData, setOptPermissionPerRoleData] = useState<
    Record<number, number[]>
  >({});

  /* Permissions */
  const {
    data: permissionsData,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  }: UseInfiniteQueryResult<
    InfinitePermissionsResponseDataInterface,
    Error
  > = rolesStore.useGetPermissions(1, canListPermissions ? true : false);

  const permissions = permissionsData?.pages.flatMap(
    (page) => page?._embedded?.permissions || []
  );

  const isAllPermissionsGrantedForSelectedRole =
    roles?.find((role) => role.id === selectedRole)?.allPermissions ?? 0;

  /* Infinite scroll management */
  const permissionsSentinelRef = useRef<HTMLDivElement>(null);

  /* Modal management */
  const dialogRef = useRef<ModalHandleInterface>(null);
  const [modalOptions, setModalOptions] =
    useState<RolesModalInterface>(INIT_FORM_OPTIONS);

  /**
   * Effects
   */

  // Infinite Scroll
  useInfiniteScrollSentinel({
    sentinelRef: permissionsSentinelRef,
    hasNextPage,
    loadMore: fetchNextPage,
    isLoading: isFetchingNextPage,
  });

  // Set initial selected role
  useEffect(() => {
    if (!canListPermissions || selectedRole) return;

    if (roles && roles.length > 0 && selectedRole === undefined) {
      setSelectedRole(roles[0].id);
    }
  }, [canListPermissions, roles, selectedRole]);

  // Toggle Modal
  useEffect(() => {
    if (!canCreateRole && !canEditRole) return;

    modalOptions.isOpen
      ? dialogRef.current?.open()
      : dialogRef.current?.close();
  }, [canCreateRole, canEditRole, modalOptions.isOpen]);

  // Initialize `permissionsPerRole` state when `permissionsPerRoleData` or `selectedRole` changes
  useEffect(() => {
    if (isAllPermissionsGrantedForSelectedRole && permissions) {
      setPermissionPerRole((prev) => ({
        ...prev,
        [selectedRole as number]: permissions.map(
          (permission: any) => permission.id
        ),
      }));
    }

    if (
      !isAllPermissionsGrantedForSelectedRole &&
      permissionsPerRoleData?.permissions
    ) {
      setPermissionPerRole((prev) => ({
        ...prev,
        [selectedRole as number]: permissionsPerRoleData.permissions.map(
          (permission: any) => permission.id
        ),
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAllPermissionsGrantedForSelectedRole,
    permissionsPerRoleData,
    selectedRole,
  ]);

  /**
   *  Handlers
   */

  // Update modal form options
  const handleUpdateFormOptions = (role?: RoleFormInterface): void => {
    if (!canCreateRole || !canEditRole) return;
    setModalOptions({
      isOpen: true,
      role: role ?? undefined,
    });
  };

  // Handles modal close
  const handleCloseModal = useCallback(async (id?: number) => {
    dialogRef.current && dialogRef.current.close();
    if (id) {
      handleSelectedRole(id);
    }
    setModalOptions((prevState) => ({
      ...prevState,
      isOpen: false,
    }));
  }, []);

  // Handles selected role
  const handleSelectedRole = (id: number) => {
    setSelectedRole(id);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateRolePermissions = useCallback(
    debounce(
      async (
        role: number,
        permissions: number[],
        previousPermissions: number[]
      ) => {
        if (!canGetPermissionsPerRole) return;
        // Perform the request to update the role permissions
        const { success, message } = await rolesStore.updateRolePermissions(
          role,
          permissions
        );

        setPermissionPerRole((prev) => ({
          ...prev,
          [role]: permissions,
        }));

        if (!success) {
          setPermissionPerRole((prev) => ({
            ...prev,
            [role]: previousPermissions,
          }));
          setOptPermissionPerRoleData((prev) => ({
            ...prev,
            [role]: previousPermissions,
          }));
          showToast(message, success);
        }
      },
      250 // Adjust the debounce delay as needed
    ),
    []
  );

  // Handles permission changes for selected role
  const handleCheckedItemsChange = useCallback(
    async (updatedPermissions: number[]) => {
      if (!canSetPermissions) return;
      toast.dismiss();

      if (!selectedRole) {
        showToast(t('roles.choose_role'), false);
        return;
      }

      if (updatedPermissions.length === 0) {
        showToast(t('permissions.role_must_have_permission'), false);
        return;
      }

      // Maintain a backup of the previous state
      const previousPermissions = permissionsPerRole[selectedRole] || [];

      // Optimistically update the state
      setOptPermissionPerRoleData((prev) => ({
        ...prev,
        [selectedRole as number]: updatedPermissions,
      }));

      // Call the debounced function
      debouncedUpdateRolePermissions(
        selectedRole,
        updatedPermissions,
        previousPermissions
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      canSetPermissions,
      selectedRole,
      permissionsPerRole,
      debouncedUpdateRolePermissions,
    ]
  );

  return (
    <>
      <div className="pks-layout-col-xl">
        {/* Page Title */}
        <PageTitleWithActions title={t('roles.roles_and_permissions_title')}>
          {canCreateRole && (
            <Button onClick={() => handleUpdateFormOptions()}>
              {t('roles.add_role')}
            </Button>
          )}
        </PageTitleWithActions>

        {/* Roles and Permissions */}
        <div className="flex flex-col lg:flex-row gap-8">
          {/* Roles */}
          <div className="flex-1 h-full">
            <div className="pks-layout-col-md h-full">
              <h2 className="flex items-center lg:min-h-[46px]">
                {t('roles.roles')}
              </h2>
              <RolesList
                roles={roles}
                selected={selectedRole}
                onClick={handleSelectedRole}
                onUpdate={handleUpdateFormOptions}
              />
            </div>
          </div>
          {/* Permissions */}
          {canListPermissions && (
            <div className="flex-1">
              <div className="pks-layout-col-md">
                <div className="flex flex-col gap-4 lg:flex-row lg:items-center lg:flex-nowrap">
                  <h2>{t('permissions.permissions')}</h2>
                  <p className="lg:min-h-[46px] text-sm hidden lg:flex lg:flex-wrap lg:items-center">
                    * {t('permissions.role_must_have_permission')}
                  </p>
                </div>
                <div className="pks-layout-col">
                  <span>
                    {t('permissions.role_permissions')}{' '}
                    <b>{selectedRoleName}</b>{' '}
                    <span className="lg:hidden">
                      {`(${t('permissions.role_must_have_permission')})`}.
                    </span>
                  </span>
                  {selectedRole &&
                    (modalOptions.isOpen === false ? (
                      <RoleForm
                        role={{ id: selectedRole, name: selectedRoleName }}
                        onClose={handleCloseModal}
                        isChecked={
                          isAllPermissionsGrantedForSelectedRole ? true : false
                        }
                        checkboxForm
                      />
                    ) : (
                      <FakeCheckbox
                        label={t('permissions.allow_all_permissions')}
                        checked={
                          isAllPermissionsGrantedForSelectedRole ? true : false
                        }
                      ></FakeCheckbox>
                    ))}
                  {permissionsData ? (
                    <div>
                      {permissions && permissions.length > 0 && (
                        <div className="pks-layout-col-md">
                          <PermissionsList
                            hasAllPermissions={
                              isAllPermissionsGrantedForSelectedRole
                            }
                            permissions={permissions}
                            permissionsPerRole={
                              optPermissionsPerRoleData[
                                selectedRole as number
                              ] ||
                              permissionsPerRole[selectedRole as number] ||
                              []
                            }
                            onCheckedItemsChange={handleCheckedItemsChange}
                          />
                          {isFetchingNextPage && (
                            <InfoMessage
                              icon="info"
                              message={t('state.loading')}
                            />
                          )}
                          <div
                            ref={permissionsSentinelRef}
                            style={{ height: '1px' }}
                          />
                        </div>
                      )}
                    </div>
                  ) : (
                    <InfoMessage
                      icon="info"
                      message={
                        !isFetching
                          ? t('permissions.no_permissions_available')
                          : t('state.loading')
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        {/* Modal for Add / Edit Role Form */}
        {(canCreateRole || canEditRole) && modalOptions.isOpen && (
          <Modal
            ref={dialogRef}
            onClose={handleCloseModal}
            hasActionButton={
              canEditRole && modalOptions.role && canDeleteRole ? false : true
            }
          >
            <RoleForm
              role={modalOptions?.role}
              onClose={handleCloseModal}
              isChecked={isAllPermissionsGrantedForSelectedRole ? true : false}
            />
          </Modal>
        )}
      </div>
    </>
  );
};

export default Roles;

export async function loader() {
  if (!hasPermission([PermissionsEnum.RolesList])) {
    return null;
  }

  // Fetch the first page to determine total pages
  const firstPageResponse = await rolesStore.preloadRoles({ page: 1 });

  if (!firstPageResponse || !firstPageResponse._page_count) {
    // console.error('Failed to fetch roles or determine total pages');
    return null;
  }

  const totalPages = firstPageResponse._page_count;

  // Collect promises for all additional pages
  const pageFetchPromises = [];
  for (let page = 2; page <= totalPages; page++) {
    pageFetchPromises.push(rolesStore.preloadRoles({ page }));
  }

  // Wait for all page requests to resolve
  const responses = await Promise.all(pageFetchPromises);

  // Combine roles from all responses including the first page
  const roles = [
    ...(firstPageResponse?._embedded?.roles || []),
    ...responses.flatMap((response) => response?._embedded?.roles || []),
  ];

  return roles;
}
