import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useNavigate,
  useSearchParams,
  LoaderFunctionArgs,
} from 'react-router-dom';

import PageTitleWithActions from '../../../layouts/PageLayout/TitleWithActions';
import Button from '../../../components/shared/Button';
import Modal, { ModalHandleInterface } from '../../../components/shared/Modal';
import Pagination from '../../../components/shared/Pagination';
import SearchInput from '../../../components/shared/SearchInput';
import RegionForm from './RegionForm';
import RegionsList from './RegionsList';

import RegionsStore from '../services/regions.store';
import { RegionInterface } from '../services/regions.types';
import { useModal } from '../../../context/modal-context';
import { hasPermission } from '../../../utils';
import { PermissionsEnum } from '../../../types';
import TransitionLoader from '../../../components/shared/TransitionLoader/TransitionLoader';
import { usePermission } from '../../../hooks/usePermission';

interface RegionFormInterface {
  region: RegionInterface | undefined;
  isOpen: boolean;
}

const INIT_FORM_OPTIONS = {
  region: undefined,
  isOpen: false,
};

const regionsStore: RegionsStore = new RegionsStore();

/**
 * Manages the regions by displaying a list of regions, providing a search filter,
 * and allowing the addition or editing of regions through a modal form.
 *
 * @component
 * @returns {React.JSX.Element} The rendered Regions component.
 */
const Regions = (): React.JSX.Element => {
  const { t } = useTranslation();
  /* Permissions */
  const canCreateRegion = usePermission([PermissionsEnum.RegionCreate]);
  const canEditRegion = usePermission([PermissionsEnum.RegionEdit]);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Parse URL search parameters
  const page = Number(searchParams.get('page')) || 1;
  const [searchValue, setSearchValue] = useState(
    searchParams.get('search') || ''
  );

  searchParams.delete('search');

  // Create a reference for the modal dialog using a ref hook
  const dialogRef = useRef<ModalHandleInterface>(null);

  // Initialize form options state with default values
  const [formOptions, setFormOptions] =
    useState<RegionFormInterface>(INIT_FORM_OPTIONS);

  // Use the `useGetRegions` hook from the regions store to fetch region data
  const {
    data: regionsQueryData,
    isFetching,
    isLoading,
  } = regionsStore.useGetRegions({
    page,
    searchTerm: searchValue,
  });

  const regions = regionsQueryData?._embedded.regions;
  const currentPage = regionsQueryData?._page || 1;
  const totalPages = regionsQueryData?._page_count || 1;

  useEffect(() => {
    if (!canCreateRegion || !canEditRegion) return;
    formOptions.isOpen ? dialogRef.current?.open() : dialogRef.current?.close();
  }, [canCreateRegion, canEditRegion, formOptions.isOpen]);

  /**
   * Updates the options for the modal.
   *
   * @param {RegionInterface | null} [region] - The region to edit or `null` to create a new one.
   * If `region` is not provided, it indicates the creation of a new region.
   * @returns {void}
   */
  const updateFormOptions = (region?: RegionInterface | null): void => {
    setFormOptions({
      isOpen: true,
      region: region ?? undefined,
    });
  };

  const { openModal, closeModal } = useModal();

  const handleOpenAddRegionForm = (e: any) => {
    if (!canCreateRegion) return;
    e.stopPropagation();
    openModal(<RegionForm onClose={closeModal} />, t('regions.add_region'));
  };

  /**
   * Handles the search input change event.
   *
   * @param {string} debouncedValue - The debounced value of the search input.
   * This function updates the URL with the new search query and resets the page number to 1.
   * @returns {void}
   */
  const handleSearch = (debouncedValue: string): void => {
    setSearchValue(debouncedValue);
    navigate(`?page=1`, {
      replace: true,
    });
  };

  /**
   * Resets the form options to their initial state and closes the modal.
   *
   * This callback function is used to clear any data and reset the state of
   * the form options when the modal is closed.
   *
   * @callback
   * @returns {void}
   */
  const handleCloseModal = useCallback(() => {
    if (!canCreateRegion || !canEditRegion) return;
    setFormOptions(INIT_FORM_OPTIONS);
  }, [canCreateRegion, canEditRegion]);

  /**
   * Handles page changes in pagination.
   * Updates the URL with the new page number.
   *
   * @param {number} newPage - The new page number to navigate to.
   * @returns {void}
   */
  const handlePageChange = (newPage: number): void => {
    if (newPage !== currentPage) {
      navigate(`?page=${newPage.toString()}`, { replace: true });
    }
  };

  return (
    <>
      {/* Page Title with Action */}
      <PageTitleWithActions title={t('regions.regions')}>
        <div className="inline-flex flex-wrap gap-4">
          {canCreateRegion && (
            <Button
              className="flex-auto"
              onClick={(e) => handleOpenAddRegionForm(e)}
            >
              {t('regions.add_region')}
            </Button>
          )}
          {/* <Link
						to={ORGANIZATION_PATH}
						className='flex-auto focus:outline-none group'>
						<Button variant='secondary' tabIndex={-1}>
							Odustani
						</Button>
					</Link> */}
        </div>
      </PageTitleWithActions>

      <>
        {isLoading && <TransitionLoader />}
        {/* Regions List with Search Filter */}
        <div className="pks-layout-col-md">
          <SearchInput
            value={searchValue}
            onSearch={handleSearch}
            autofocus
            isFetching={isFetching}
          />
          <RegionsList
            regions={regions}
            onUpdate={updateFormOptions}
            searchValue={searchValue}
          />
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>

        {/* Modal for Add / Edit Region Form  */}
        {formOptions.isOpen && (
          <Modal ref={dialogRef} onClose={handleCloseModal}>
            <RegionForm
              region={formOptions.region}
              onClose={handleCloseModal}
            />
          </Modal>
        )}
      </>
    </>
  );
};

export default Regions;

/**
 * Loader function to prefetch regions.
 */
export async function loader({ request }: LoaderFunctionArgs) {
  const { searchParams } = new URL(request.url);
  const page = Number(searchParams.get('page')) || 1;
  const searchTerm = searchParams.get('search') || '';

  if (!hasPermission([PermissionsEnum.RegionsList])) return null;

  await regionsStore.preloadRegions({ page, searchTerm });
  return null;
}
