import {
  MainNavigationItemInterface,
  ContactIdEnum,
  ContactItemInterface,
  SocialMediaItemInterface,
  SocialMediaEnum,
} from '../types';

import { ROUTES } from '../constants/routes';

// Main Navigation Links
export const MAIN_NAVIGATION_LINKS: MainNavigationItemInterface[] = [
  {
    label: 'Opšti uslovi rada Foruma',
    url: ROUTES.DASHBOARD.ADMINISTRATION.FORUM.ROOT.absPath,
    children: [
      {
        label: 'Savet Foruma',
        url: ROUTES.DASHBOARD.ADMINISTRATION.FORUMS.FORUM_COUNCIL.absPath,
      },
      {
        label: 'Rad Foruma',
        url: ROUTES.DASHBOARD.ADMINISTRATION.FORUMS.FORUM_REPORTS.absPath,
      },
    ],
  },
  /* {
		label: 'Unos institucije',
		url: ROUTES.DASHBOARD.INSTITUTIONS.INSTITUTION_ENTRY.absPath,
	}, */
  {
    label: 'Edukacija i korisne informacije',
    url: ROUTES.DASHBOARD.EDUCATION.ROOT.absPath,
    children: [
      {
        label: 'Vesti',
        url: ROUTES.DASHBOARD.EDUCATION.NEWS.absPath,
      },
    ],
  },
  {
    label: 'Lista slučajeva',
    url: ROUTES.DASHBOARD.FRAUD_CASES.ROOT.absPath,
    children: [],
  },
];

// Footer Quick Links
export const QUICK_LINKS = [
  {
    label: 'Privredna komora Srbije',
    url: 'https://www.pks.rs/',
  },
  {
    label: 'Narodna banka Srbije',
    url: 'https://www.nbs.rs/',
  },
  {
    label: 'RATEL - Regulatorna agencija za elektronske komunikacije',
    url: 'https://www.ratel.rs/',
  },
  {
    label: 'Ministarstvo unutrašnjih poslova Republike Srbije',
    url: 'http://www.mup.rs/wps/portal/sr/',
  },
  {
    label: 'Evropska asocijacija za sigurne transakcije',
    url: 'https://www.association-secure-transactions.eu/',
  },
];

// Footer Contact Links
export const CONTACT_INFO_LINKS: ContactItemInterface[] = [
  {
    id: ContactIdEnum.Address,
    label: 'Resavska 13 - 15<br>11000 Beograd',
    url: 'https://maps.app.goo.gl/waoxQbgB1xmq3nkE6',
    ariaLabel: 'View on Google Maps',
    external: true,
  },
  // {
  //   id: ContactIdEnum.Phone,
  //   label: '+1 (234) 567-890',
  //   url: 'tel:+1234567890',
  //   ariaLabel: 'Call +1 (234) 567-890',
  // },
  {
    id: ContactIdEnum.Email,
    label: 'finansijske.institucije@pks.rs',
    url: 'mailto:finansijske.institucije@pks.rs',
    ariaLabel: 'Send an email to finansijske.institucije@pks.rs',
  },
];

// Social Media Links
export const SOCIAL_MEDIA_LINKS: SocialMediaItemInterface[] = [
  {
    id: SocialMediaEnum.Facebook,
    label: 'Facebook',
    url: 'https://www.facebook.com/Privrednakomora/',
  },
  {
    id: SocialMediaEnum.Twitter,
    label: 'Twitter',
    url: 'https://twitter.com/Privrednakomora',
  },
  {
    id: SocialMediaEnum.LinkedIn,
    label: 'LinkedIn',
    url: 'https://www.linkedin.com/company/serbian-chamber-of-commerce-and-industry',
  },
  {
    id: SocialMediaEnum.Instagram,
    label: 'Instagram (Privredna Komora Srbije)',
    url: 'https://www.instagram.com/privredna_komora_srbije',
  },
  {
    id: SocialMediaEnum.Instagram,
    label: 'Instagram (Katarina Ocoljić)',
    url: 'https://www.instagram.com/katarina.ocokoljic/',
  },
  {
    id: SocialMediaEnum.Youtube,
    label: 'YouTube',
    url: 'https://www.youtube.com/user/PKSMedia?feature=watch',
  },
];

// Login Page Link
export const LOGIN_PAGE_LINK = ROUTES.LOGIN.ROOT.absPath;

// Login Email Verificatiion Link
export const LOGIN_EMAIL_VERIFICATION_PAGE_LINK =
  ROUTES.LOGIN.EMAIL_VERIFICATION.absPath;

// News Page Link
export const NEWS_PAGE_LINK = ROUTES.DASHBOARD.EDUCATION.NEWS.absPath;

//Fraud Cases Page Link
export const FRAUD_CASES_LINK = ROUTES.DASHBOARD.FRAUD_CASES.ROOT.absPath;

// Institution Entry Link
export const INSTITUTION_ENTRY_LINK = '';
