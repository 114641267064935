import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  LoaderFunctionArgs,
  useLoaderData,
  useSearchParams,
} from 'react-router-dom';

import InfoMessage from '../../../components/shared/InfoMessage';
import Pagination from '../../../components/shared/Pagination';
import SearchInput from './SearchInput';
import SearchResultsList from './SearchResultsList';

import { useSearchTerm } from '../../../hooks/useSearchTerm';

import SearchStore from '../services/search.store';
import { hasPermission } from '../../../utils';
import { PermissionsEnum } from '../../../types';
const searchStore: SearchStore = new SearchStore();

const MIN_CHARS = 4;
const ROWS_PER_PAGE = 10;

const SearchResults = () => {
  const { t } = useTranslation();
  const { page: initialPage, q: initialQ } = useLoaderData() as {
    page: number;
    q: string;
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const { searchTerm, setSearchTerm, searchInputPrompt, isSearchTermTooShort } =
    useSearchTerm({
      value: initialQ,
      minChars: MIN_CHARS,
    });

  // Use the `useGetSearchResults` hook from the search store to fetch search results based on the debounced search term
  const { data, error } = searchStore.useGetSearchResults({
    page: initialPage,
    term: initialQ,
    rowsPerPage: ROWS_PER_PAGE,
  });

  // Calc total pages
  const totalPages = (data && Math.ceil(data.total / ROWS_PER_PAGE)) || 0;

  // Update search term when the initial query changes
  useEffect(() => {
    setSearchTerm(initialQ);
  }, [initialQ, setSearchTerm]);

  const handlePageChange = (newPage: number) => {
    if (newPage !== initialPage) {
      searchParams.set('page', newPage.toString()); // Update the page parameter

      // Conditionally set category if it exists
      if (searchTerm) {
        searchParams.set('q', searchTerm);
      }

      // Update the search parameters in the URL
      setSearchParams(searchParams);
    }
  };

  const handleSubmit = () => {
    if (!searchTerm || isSearchTermTooShort) {
      return;
    }

    // Create a new URLSearchParams instance to manipulate the query parameters
    const newParams = new URLSearchParams(searchParams);

    // Set the page to '1' (start from the first page)
    newParams.set('page', '1');

    // Update the 'q' parameter with the current search term
    newParams.set('q', searchTerm);

    // Push the updated parameters back to the URL
    setSearchParams(newParams);
  };

  return (
    <>
      <h1 className="break-all">
        {t('search.title_search_term')}: '{initialQ}'
      </h1>
      <div className="pks-layout-col-md">
        <SearchInput
          value={searchTerm}
          onSubmit={handleSubmit}
          onSearchTermChange={setSearchTerm}
        />

        {/* Info mesage - min char requirement */}
        {(!searchTerm || isSearchTermTooShort) && (
          <InfoMessage icon="info" message={searchInputPrompt} />
        )}

        {/* Search Results */}
        {data && (
          <SearchResultsList
            data={data}
            error={!!error}
            searchTerm={searchTerm}
            initialSearchTerm={initialQ}
            minChars={MIN_CHARS}
          />
        )}

        {/* Pagination */}
        <Pagination
          currentPage={initialPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default SearchResults;

export const loader = async ({ request }: LoaderFunctionArgs): Promise<any> => {
  const { searchParams } = new URL(request.url);

  if (!hasPermission([PermissionsEnum.Search])) {
    return null;
  }

  const page = Number(searchParams.get('page')) || 1;
  const q = searchParams.get('q') || '';

  await searchStore.preloadSearchResults({
    page,
    term: q,
    rowsPerPage: ROWS_PER_PAGE,
  });

  return { page, q };
};
