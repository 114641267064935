// src/layouts/LoginLayout.tsx
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, Outlet } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import Meta from '../../components/layout/Meta';
import Logo from '../../components/shared/Logo';
import PageLoader from '../PageLayout/Loader';

import { useAuth } from '../../context/auth-context';

const LoginLayout = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isAuth, isLoggingOut } = useAuth();
  const shouldNavigate = isAuth && !isLoggingOut;

  const pageTitle = t('auth.login_title');

  // Redirect to home if user authenticated
  useEffect(() => {
    shouldNavigate && navigate('/', { replace: true });
  }, [shouldNavigate, navigate]);

  // Show loader while checking if user authenticated
  if (shouldNavigate) {
    return <PageLoader init />;
  }

  return (
    <>
      <Meta title={pageTitle} />
      <div className="min-h-screen flex items-center justify-center p-4 sm:p-8">
        <div className="pks-layout-col-xl w-full">
          <Logo isLink={false} className="m-auto" />
          <div className="min-h-[507px]">
            <div className="pks-layout-col-xl max-w-[444px] m-auto p-4 bg-primary-50 rounded-lg sm:p-8">
              <h1 className="h2">{pageTitle}</h1>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginLayout;
