import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import Meta from '../../components/layout/Meta';
import Button from '../../components/shared/Button';
import PageLayout from '../../layouts/PageLayout';
import OrganizationForm from '../../modules/organizations/UI/OrganizationForm';

import { ORGANIZATION_PATH } from '../../constants';
import { PermissionsEnum } from '../../types';
import { usePermission } from '../../hooks/usePermission';
import { useRedirect } from '../../hooks/useRedirect';

function OrganizationFormPage() {
  const { t } = useTranslation();
  /* Permissions */
  const canViewOrganizationData = usePermission([
    PermissionsEnum.OrganizationData,
  ]);
  const canCreateOrganization = usePermission([
    PermissionsEnum.OrganizationCreate,
  ]);

  const { id } = useParams();
  const pageTitle: string = id
    ? t('institutions.edit_institution_title')
    : t('institutions.add_institution_title');
  const navigate = useNavigate();
  const shouldRedirect =
    (id && canViewOrganizationData === false) ||
    (!id && canCreateOrganization === false);

  useRedirect({
    redirect: shouldRedirect,
  });

  return (
    <>
      <span className="mb-8 flex gap-2 items-center w-fit justify-center b sm:flex-none focus:outline-none group">
        <Button
          onClick={() => navigate(ORGANIZATION_PATH)}
          type="button"
          variant="back"
          tabIndex={-1}
        >
          {t('global.back_to_list')}
        </Button>
      </span>
      <Meta title={pageTitle} />
      {!shouldRedirect && (
        <PageLayout className="max-w-[500px]" title={pageTitle}>
          <OrganizationForm />
        </PageLayout>
      )}
    </>
  );
}

export default OrganizationFormPage;
