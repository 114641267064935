import ChatUserIcon from './ChatUserIcon';
import { getTimeFromTimestamp } from '../../../components/shared/DropdownSelect/utils';
import { useTranslation } from 'react-i18next';

const ChatMessage = ({
  allUsers,
  senderId,
  userId,
  text,
  timestamp,
}: // userAvatar,
any) => {
  const { t } = useTranslation();
  const formattedTime = getTimeFromTimestamp(timestamp);
  const senderObject = allUsers.find(
    (user: any) => String(user.userId) === String(senderId)
  );

  const fullName = senderObject
    ? `${senderObject.firstName} ${senderObject.lastName}`
    : t('auth.unknown_user');

  const isMyMessage = +senderId === +userId;

  return isMyMessage ? (
    <div className={`flex gap-2 w-full  justify-end    mt-4`}>
      <div className="inline-block w-fit overflow-x-hidden">
        <span className="flex items-center justify-end ">
          <p className="text-xs text-secondary">{formattedTime}</p>
        </span>
        <p className="w-fit  break-words overflow-wrap break-all bg-primary-50 px-4 py-2 rounded-lg">
          {text}
        </p>
      </div>
    </div>
  ) : (
    <div className={`flex gap-2 w-fit mt-4`}>
      <ChatUserIcon id={senderId} userName={fullName} />
      <div className="inline-block w-fit overflow-x-hidden">
        <span className="flex items-center gap-2">
          <p className="font-bold">{fullName}</p>
          <p className="text-xs text-secondary">{formattedTime}</p>
        </span>
        <p className="w-fit  break-words overflow-wrap break-all bg-secondary-50 px-4 py-2 rounded-lg">
          {text}
        </p>
      </div>
    </div>
  );
};

export default ChatMessage;
