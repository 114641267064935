import { PermissionsEnum } from '../types';
import { PERMISSIONS_KEY } from '../constants';

export function getPermissionsFromLocalStorage() {
  // Retrieve and parse the JSON object from localStorage
  const savedData = localStorage.getItem(PERMISSIONS_KEY); // Replace 'permissionsData' with your actual key
  if (!savedData) return { allPermissions: false, permissions: [] };

  const parsedData = JSON.parse(savedData);

  // Extract allPermissions and permissions fields
  const { allPermissions, permissions } = parsedData;

  // Validate data types
  if (typeof allPermissions !== 'boolean') {
    //throw new Error('Invalid allPermissions value.');
    return { allPermissions: false, permissions: [] };
  }

  if (!Array.isArray(permissions)) {
    //throw new Error('Invalid permissions value.');
    return { allPermissions: false, permissions: [] };
  }

  return { allPermissions, permissions };
}

/**
 * Checks if the current user has the required permission(s).
 *
 * @param requiredPermissions - An array of required permissions to check.
 * @returns {boolean} - Returns true if the user has at least one of the required permissions, otherwise false.
 */
export function hasPermission(requiredPermissions: PermissionsEnum[]): boolean {
  const { allPermissions, permissions } = getPermissionsFromLocalStorage();

  // If the user has 'allPermissions', return true without further checks
  if (allPermissions) return true;

  if (!permissions) return false;

  // Check if the user has at least one of the required permissions
  return requiredPermissions.some((permission) =>
    permissions.includes(permission)
  );
}
