import { translate as t } from '../config/i18n.config';

export function getBaseUrl() {
  return process.env.REACT_APP_API_URL;
}

export function getPageTitle(title: string) {
  return `${title} | ${t('website.name_short')}`;
}

export function testRegex(regex: RegExp, input: string): boolean {
  return regex.test(input);
}

export function debounce<T extends (...args: any[]) => void>(
  func: T,
  delay: number = 250
): (...args: Parameters<T>) => void {
  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  return (...args: Parameters<T>) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
}

export function decodeHtmlEntities(text: string) {
  const textarea = document.createElement('textarea');
  textarea.innerHTML = text;
  return textarea.value;
}
