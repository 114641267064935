import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import ConfirmDenyDialog from '../../../components/shared/ModalNew/ConfirmDenyDialog';
import Icon from '../../../components/shared/Icon';
import NoSearchResultsInfoMessage from '../../../components/shared/InfoMessage/NoSearchResults';
import NoResultsInfoMessage from '../../../components/shared/InfoMessage/NoResults';
import OrganizationPreview from './OrganizationPreview';

import { useModal } from '../../../context/modal-context';
import { usePermission } from '../../../hooks/usePermission';
import { PermissionsEnum } from '../../../types';
import { formatDateWithPoints, showToast } from '../../../utils';

import OrganizationsStore from '../services/organizations.store';
const organizationStore: OrganizationsStore = new OrganizationsStore();

const OrganizationsList = ({ organizations, searchValue, onClick }: any) => {
  const { t } = useTranslation();
  /* Permissions */
  const canEditOrganization = usePermission([PermissionsEnum.OrganizationEdit]);
  const canViewOrganizationData = usePermission([
    PermissionsEnum.OrganizationData,
  ]);
  const canDeleteOrganization = usePermission([
    PermissionsEnum.OrganizationDelete,
  ]);

  const { openModal, closeModal } = useModal();

  const deleteInstitution = async (id: number) => {
    const { success, message } = await organizationStore.deleteOrganization(id);
    showToast(message, success);
  };

  const handleOpenModal = (e: any, organization: any) => {
    e.stopPropagation();
    if (!canViewOrganizationData) return;

    openModal(
      <OrganizationPreview
        organization={organization}
        regionName={organization.region.name}
      />,
      t('institutions.institution')
    );
  };

  const handleOpenDeleteOrganizationModal = (
    e: MouseEvent<HTMLButtonElement>,
    id: number
  ) => {
    e.stopPropagation();
    if (!canDeleteOrganization) return;
    openModal(
      <ConfirmDenyDialog
        infoMessage={t('institutions.prompt_institution_delete')}
        onConfirm={() => deleteInstitution(id)}
        onDeny={closeModal}
        closeModal={closeModal}
      />,
      t('institutions.title_institution_delete_preview')
    );
  };

  return (
    <ul className="flex flex-col gap-3">
      {organizations?.length === 0 ? (
        <li>
          {searchValue === '' ? (
            <NoResultsInfoMessage />
          ) : (
            <NoSearchResultsInfoMessage />
          )}
        </li>
      ) : (
        organizations?.map((organization: any) => {
          if (!organization) {
            return null;
          }

          const formatedDate = formatDateWithPoints(
            organization?.date_edited?.date || organization?.date_created?.date
          );

          return (
            <li
              onClick={(e: any) => handleOpenModal(e, organization)}
              key={organization.id}
              className={`pks-card-bg overflow-hidden rounded-lg ${
                canViewOrganizationData
                  ? 'cursor-pointer pks-card-bg-hover'
                  : ''
              }`}
            >
              <div className="flex gap-4 items-center justify-between ml-[6px] pl-[22px] pr-4 py-3 bg-white">
                <div className="flex gap-2 items-center">
                  <div className="pks-layout-col">
                    <p className="font-bold">{organization?.name}</p>
                    <span className="text-secondary-400">
                      {organization.region?.name}
                    </span>
                    <span className="text-secondary-400"></span>
                  </div>
                </div>
                <div className="inline-flex items-center flex-col lg:flex-row gap-4">
                  {canEditOrganization && canViewOrganizationData && (
                    <Link
                      to={`${organization.id}`}
                      className="transform scale-125 p-2"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Icon
                        name="pencil"
                        aria-hidden="true"
                        className="hover:text-primary-300"
                        tabIndex={-1}
                      />
                    </Link>
                  )}

                  {canDeleteOrganization && (
                    <button
                      aria-label={t('institutions.delete_institution')}
                      onClick={(e) =>
                        handleOpenDeleteOrganizationModal(e, organization.id)
                      }
                    >
                      <Icon
                        name="trash"
                        aria-hidden="true"
                        className="text-white"
                        tabIndex={-1}
                      />
                    </button>
                  )}
                </div>
              </div>
            </li>
          );
        })
      )}
    </ul>
  );
};

export default OrganizationsList;
