import { useTranslation } from 'react-i18next';
import { PermissionsEnum } from '../../../types';
import { hasPermission } from '../../../utils';

const RegionPreview = ({ region }: any) => {
  const { t } = useTranslation();
  const canViewRegionData = hasPermission([PermissionsEnum.RegionData]);
  const regionItemClassName = 'h3 font-normal flex flex-col gap-1';

  return (
    <>
      {canViewRegionData ? (
        <ul className="flex flex-col gap-3" id={region.region_id}>
          <li className={regionItemClassName}>
            <b>{t('regions.region')}</b> {region.name}
          </li>
        </ul>
      ) : null}
    </>
  );
};

export default RegionPreview;
