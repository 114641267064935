import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useNavigation, Form } from 'react-router-dom';
import {
  useForm,
  FormProvider,
  SubmitHandler,
  FieldValues,
} from 'react-hook-form';

import Input from '../../../components/shared/Input';
import CheckboxInput from '../../../components/shared/CheckboxInput';
import ModalButton from '../../../components/shared/Modal/ModalButton';
import RolesStore from '../services/roles.store';

import { REQUIRED_VALIDATION_RULE } from '../../../constants';
import { PermissionsEnum, RegionCheckboxEnum } from '../../../types';
import { hasPermission, showToast } from '../../../utils';
import Button from '../../../components/shared/Button';
import Icon from '../../../components/shared/Icon';

interface FormValuesInterface {
  role: string;
  allPermissions: boolean;
  status: RegionCheckboxEnum;
}

interface RoleFormProps {
  role?: any;
  isChecked: boolean;
  onClose?: (id?: number) => void;
  checkboxForm?: boolean;
}

const rolesStore = new RolesStore();

/**
 * Form for creating or editing a region.
 *
 * @component
 * @param {RoleFormProps} props - The props object for this component.
 * @param {any} [props.role] - The role to edit, if any. If not provided, a new role will be created.
 * @param {() => void} props.onClose - A function to call when the form modal is closed.
 *
 * @returns {React.JSX.Element} The rendered RegionForm component.
 */
const RoleForm = ({
  role,
  onClose,
  isChecked,
  checkboxForm = false,
}: RoleFormProps): React.JSX.Element => {
  const { t } = useTranslation();
  /*Permissions*/
  const canSetPermission = hasPermission([PermissionsEnum.RolePermissionsSet]);
  const canDeleteRole = hasPermission([PermissionsEnum.RoleDelete]);

  const [isDeleting, setIsDeleting] = useState<'submitting' | boolean>(false);

  const navigate = useNavigate();
  const navigation = useNavigation();
  const isSubmitting = navigation.state === 'submitting';
  const isEditing = Boolean(role);

  const handleDeleteRole = async (id: number) => {
    setIsDeleting('submitting');
    const { success, message } = await rolesStore.deleteRole(id);
    showToast(message, success);

    onClose?.(id);
    setIsDeleting(false);
    success && navigate('.');
  };

  // React Hook Form methods and state initialization
  const methods = useForm<FormValuesInterface>({
    defaultValues: {
      role: role?.name || '',
      allPermissions: isEditing ? isChecked : false,
    },
  });

  const { handleSubmit, reset } = methods;

  useEffect(() => {
    reset({
      role: isEditing ? role?.name : '',
      allPermissions: isEditing ? isChecked : false,
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role?.name, isEditing, isChecked]);

  /**
   * Handles form submission.
   */
  const onSubmit: SubmitHandler<FieldValues> = async (data): Promise<void> => {
    if (!canSetPermission) return;

    const { role: name, status, allPermissions } = data as FormValuesInterface;
    const transformedStatus = status
      ? RegionCheckboxEnum.Active
      : RegionCheckboxEnum.Inactive;

    const transformedAllPermissions = allPermissions
      ? RegionCheckboxEnum.Active
      : RegionCheckboxEnum.Inactive;

    const response = role?.id
      ? await rolesStore.updateRole({
          id: role.id,
          name,
          allPermissions: transformedAllPermissions,
          status: transformedStatus,
        })
      : await rolesStore.addRole({
          name,
          allPermissions: transformedAllPermissions,
          status: transformedStatus,
        });

    const { success, message } = response;

    let id: number | undefined = undefined;

    if (success) {
      const data = response.data;
      id = data?.id;
    }

    showToast(message, success);
    onClose?.(id);
    navigate('.', { replace: true });
  };

  return (
    <>
      {!checkboxForm && (
        <h3 className="h2">
          {isEditing
            ? isDeleting
              ? t('roles.title_role_delete_preview')
              : t('roles.edit_role')
            : t('roles.add_role')}
        </h3>
      )}
      <FormProvider {...methods}>
        <Form
          method="post"
          className="pks-layout-col-md w-full"
          onSubmit={!checkboxForm ? handleSubmit(onSubmit) : undefined}
        >
          <div className="pks-layout-col">
            {/* Role Input */}
            {!isDeleting && (
              <>
                {!checkboxForm && (
                  <Input
                    type="text"
                    id="role"
                    name="role"
                    label={`${t('inputs.role.label')}*`}
                    placeholder={t('inputs.role.placeholder')}
                    autoFocus
                    autoComplete="off"
                    validationRules={REQUIRED_VALIDATION_RULE}
                  />
                )}
                <CheckboxInput
                  id={`${isEditing ? `edit-role-${role?.id}` : 'add-role'}`}
                  name="allPermissions"
                  label={t('permissions.allow_all_permissions')}
                  defaultChecked={isEditing ? isChecked : false}
                  onChange={(e) => {
                    if (checkboxForm) {
                      handleSubmit(onSubmit)(e);
                    }
                  }}
                />
              </>
            )}
            {isDeleting && <p>{t('roles.prompt_role_delete')}</p>}
          </div>
          {!checkboxForm && (
            <div className="flex sm:flex-row gap-2">
              {!isDeleting && (
                <ModalButton
                  className={`w-full ${
                    !isEditing || (!canDeleteRole && isEditing)
                      ? 'sm:w-7/12'
                      : ''
                  }`}
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting
                    ? t('state.submitting')
                    : isEditing
                    ? t('global.edit')
                    : t('global.add')}
                </ModalButton>
              )}
              {isEditing && canDeleteRole && (
                <>
                  <Button
                    type="button"
                    className="flex-grow flex justify-center gap-2"
                    variant="danger"
                    disabled={isDeleting === 'submitting'}
                    isDisabled={isDeleting === 'submitting'}
                    onClick={() =>
                      !isDeleting
                        ? setIsDeleting(true)
                        : handleDeleteRole(role.id)
                    }
                  >
                    <Icon
                      name="trashCurrentColor"
                      className={`${
                        isDeleting === 'submitting'
                          ? 'text-gray-500'
                          : 'text-white'
                      }`}
                    />
                    {isDeleting === 'submitting'
                      ? t('state.deleting')
                      : t('global.delete')}
                  </Button>
                  {isDeleting && (
                    <Button
                      type="button"
                      variant="primary"
                      onClick={() => setIsDeleting(false)}
                    >
                      {t('global.cancel')}
                    </Button>
                  )}
                </>
              )}
            </div>
          )}
        </Form>
      </FormProvider>
    </>
  );
};

export default RoleForm;
