import { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../../components/shared/Icon';
import EmojiPicker, { Categories } from 'emoji-picker-react';
import { globalWS } from '../../../init';
import { useAuth } from '../../../context/auth-context';

const ChatMessageInput = ({ currentOpenChat, handleMoveChatToTop }: any) => {
  const { t } = useTranslation();
  const { userId, token } = useAuth();
  const [openEmojiPicker, setOpenEmojiPicker] = useState(false);
  const [rows, setRows] = useState(1);
  const [inputValue, setInputValue] = useState('');
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const emojiPickerRef = useRef<HTMLDivElement | null>(null);

  const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const textarea = e.target;
    const lineHeight = 24;

    textarea.rows = 1;
    const currentRows = Math.floor(textarea.scrollHeight / lineHeight);

    if (currentRows >= 2) {
      setRows(2);
    } else {
      setRows(1);
    }

    textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`;

    if (textarea.value.length < 30 || textarea.value.length === 0) {
      setRows(1);
      textarea.style.height = 'auto';
    }

    setInputValue(textarea.value);
  };

  const handleToggleEmojiPicker = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpenEmojiPicker((prev) => !prev);
  };

  const handleEmojiClick = (
    emojiObject: { emoji: string },
    event: React.MouseEvent
  ) => {
    event.stopPropagation();
    const emoji = emojiObject.emoji;
    setInputValue((prev) => prev + emoji);
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
    setOpenEmojiPicker(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target as Node)
    ) {
      setOpenEmojiPicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const sendChatMessage = async () => {
    let chatId = currentOpenChat?.id ? currentOpenChat.id : null;

    if (!chatId && currentOpenChat.userId) {
      const tmpUniqueId = `createChat${currentOpenChat.id}${currentOpenChat.firstName}`;

      chatId = await new Promise((resolve, reject) => {
        const handleSetChatId = (data: any) => {
          if (data.tmpUniqueId === tmpUniqueId) {
            resolve(data.id);
            globalWS.off('createChatReceived', handleSetChatId);
          }
        };

        globalWS.on('createChatReceived', handleSetChatId);
        globalWS.createChat(token, userId, currentOpenChat).catch(reject);
      });
    }

    globalWS.sendMessage({
      jwt: token,
      from: String(userId),
      chatId: String(chatId),
      type: 'message',
      message: inputValue,
      authid: String(userId),
      data: {},
    });

    globalWS.getCurrentChat(token, userId, String(chatId), 1);
    globalWS.getLastMessages();

    // handleMoveChatToTop(chatId);
    setInputValue('');
    setRows(1);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      if (event.shiftKey) {
        setInputValue((prev) => prev + '\n');
        event.preventDefault();
      } else {
        event.preventDefault();
        sendChatMessage();
        const textarea = event.target as HTMLTextAreaElement;
        textarea.style.height = 'auto';
      }
    }
  };

  const [height, setHeight] = useState(window.innerHeight < 500 ? 300 : 450);

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [height]);

  return (
    <div
      ref={emojiPickerRef}
      className={`lg:m-4  p-2 lg:rounded-lg  border flex items-end ${
        rows > 1 ? 'flex-col' : 'flex-row'
      } relative`}
    >
      <textarea
        ref={textareaRef}
        onKeyDown={handleKeyDown}
        className="w-full p-2 resize-none rounded focus:outline-none overflow-y-auto scrollbar"
        placeholder={t('chat.enter_your_message')}
        value={inputValue}
        rows={rows}
        onInput={handleInput}
        style={{ lineHeight: '24px', maxHeight: '200px' }}
      />
      <div className="flex gap-2 items-center p-2 ">
        {/* <Icon className="hover:cursor-pointer" name="chatAttachDocument" /> */}
        <div onClick={handleToggleEmojiPicker} className="relative ">
          <Icon className="hover:cursor-pointer" name="chatEmoji" />
        </div>
        <div className="border-r-2 border-secondary-200 h-[20px]"></div>
        <span onClick={() => sendChatMessage()}>
          <Icon className="hover:cursor-pointer" name="sendMessageArrow" />
        </span>
      </div>
      {openEmojiPicker && (
        <div
          className="absolute bottom-full right-0 "
          onClick={(e) => e.stopPropagation()}
        >
          <EmojiPicker
            className="z-200 "
            height={height < 730 ? 350 : 450}
            onEmojiClick={(obj, event: any) => handleEmojiClick(obj, event)}
            searchPlaceHolder={t('search.placeholder_search')}
            skinTonesDisabled={true}
            categories={[
              {
                category: Categories.SUGGESTED,
                name: t('emoji_picker.recently_used'),
              },
              {
                category: Categories.SMILEYS_PEOPLE,
                name: t('emoji_picker.people_and_faces'),
              },
              {
                category: Categories.ANIMALS_NATURE,
                name: t('emoji_picker.animals_and_nature'),
              },
              {
                category: Categories.FOOD_DRINK,
                name: t('emoji_picker.food_and_drinks'),
              },
              {
                category: Categories.TRAVEL_PLACES,
                name: t('emoji_picker.travels_and_places'),
              },
              {
                category: Categories.ACTIVITIES,
                name: t('emoji_picker.activities'),
              },
              {
                category: Categories.OBJECTS,
                name: t('emoji_picker.objects'),
              },
              {
                category: Categories.SYMBOLS,
                name: t('emoji_picker.symbols'),
              },
              {
                category: Categories.FLAGS,
                name: t('emoji_picker.flags'),
              },
            ]}
          />
        </div>
      )}
    </div>
  );
};

export default ChatMessageInput;
