import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '../../../components/shared/Icon';
import TransitionLoader from '../../../components/shared/TransitionLoader/TransitionLoader';

import { showToast } from '../../../utils';

import FraudCaseStore from '../services/fraudCases.store';
import { FraudCaseReportEnum } from '../services/fraudCases.types';

const fraudCaseStore: FraudCaseStore = new FraudCaseStore();

const FraudCaseSingleReports = ({
  id,
  title,
}: {
  id: string;
  title: string;
}) => {
  const { t } = useTranslation();
  const [isDownloading, setIsDownloading] = useState(false);

  const handleClick = async (type: FraudCaseReportEnum) => {
    setIsDownloading(true);
    const response = await fraudCaseStore.getFraudCaseReport({
      id,
      type,
    });

    const { success, message } = response;

    showToast(message, success);

    if (success && response.data) {
      const fileUrl = window.URL.createObjectURL(response.data);

      const link = document.createElement('a');
      link.href = fileUrl;

      // Set the desired filename for the download
      link.download = title; // You can change the file name if needed

      // Trigger the click event to start the download automatically
      link.click();

      // Revoke the Blob URL after download to free up memory
      URL.revokeObjectURL(fileUrl);
    }

    setIsDownloading(false);
  };

  return (
    <>
      {isDownloading && (
        <TransitionLoader message={t('fraud_cases.downloading_report')} />
      )}
      <div className="flex gap-4">
        <button
          onClick={() => {
            handleClick(FraudCaseReportEnum.Excel);
          }}
        >
          <Icon
            name="doc"
            aria-hidden="true"
            className="hover:text-primary-300 group-focus:text-primary-300"
            tabIndex={-1}
          />
          <span className="sr-only">{t('documents.review_document')}</span>
        </button>

        <button
          onClick={() => {
            handleClick(FraudCaseReportEnum.PDF);
          }}
        >
          <Icon
            name="pdf"
            aria-hidden="true"
            className="hover:text-primary-300 group-focus:text-primary-300"
            tabIndex={-1}
          />
          <span className="sr-only">{t('documents.review_document')}</span>
        </button>
      </div>
    </>
  );
};

export default FraudCaseSingleReports;
