import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Meta from '../../components/layout/Meta';
import Button from '../../components/shared/Button';
import PageLayout from '../../layouts/PageLayout';
import UserResetPasswordForm from '../../modules/forumMembers/UI/ResetPasswordForm';

import { ADMINISTRATION_PATH } from '../../constants';
import { useAuth } from '../../context/auth-context';

function UserResetPasswordPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { userId } = useAuth();

  const pageTitle = t('auth.change_password_title');

  return (
    <>
      <Meta title={pageTitle} />
      <span className="mb-8 flex gap-2 items-center w-fit justify-center b sm:flex-none focus:outline-none group">
        <Button
          onClick={() => navigate(`${ADMINISTRATION_PATH}/${userId}`)}
          type="button"
          variant="back"
          tabIndex={-1}
        >
          {t('global.back')}
        </Button>
      </span>
      <PageLayout className="max-w-[500px]" title={pageTitle}>
        <UserResetPasswordForm />
      </PageLayout>
    </>
  );
}

export default UserResetPasswordPage;
