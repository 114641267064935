import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Logo from '../../shared/Logo';
import logo from '../../../assets/images/logos/logo-white.png';
import SocialMedia from '../../shared/SocialMedia';
import Icon from '../../shared/Icon';

import {
  ContactIdEnum,
  ContactItemInterface,
  SocialMediaEnum,
  SocialMediaItemInterface,
} from '../../../types';

const Footer = () => {
  const { t } = useTranslation();

  const socialMediaLinks: SocialMediaItemInterface[] = [
    {
      id: SocialMediaEnum.Facebook,
      label: t('social_media.facebook'),
      url: t('social_media.facebook_link'),
    },
    {
      id: SocialMediaEnum.Twitter,
      label: t('social_media.twitter'),
      url: t('social_media.twitter_link'),
    },
    {
      id: SocialMediaEnum.LinkedIn,
      label: t('social_media.linkedin'),
      url: t('social_media.linkedin_link'),
    },
    {
      id: SocialMediaEnum.Instagram,
      label: `${t('social_media.instagram')} (${t('website.pks')})`,
      url: t('social_media.instagram_link'),
    },
    {
      id: SocialMediaEnum.Instagram,
      label: t('social_media.instagram_member'),
      url: t('social_media.instagram_member_link'),
    },
    {
      id: SocialMediaEnum.Youtube,
      label: t('social_media.youtube'),
      url: t('social_media.youtube_link'),
    },
  ];

  const contactInfoLinks: ContactItemInterface[] = [
    {
      id: ContactIdEnum.Address,
      label: t('contact.address'),
      url: 'https://maps.app.goo.gl/waoxQbgB1xmq3nkE6',
      ariaLabel: t('contact.view_on_google_maps'),
      external: true,
    },
    // {
    //   id: ContactIdEnum.Phone,
    //   label: '+1 (234) 567-890',
    //   url: 'tel:+1234567890',
    //   ariaLabel: 'Call +1 (234) 567-890',
    // },
    {
      id: ContactIdEnum.Email,
      label: t('contact.email'),
      url: `mailto:${t('contact.email')}`,
      ariaLabel: `${t('contact.send_an_email_to')} ${t('contact.email')}`,
    },
  ];

  const quickLinks = [
    {
      label: t('website.pks'),
      url: t('website.pks_email'),
    },
    {
      label: t('quick_links.national_bank_of_serbia'),
      url: t('quick_links.national_bank_of_serbia_email'),
    },
    {
      label: t('quick_links.ratel'),
      url: t('quick_links.ratel_email'),
    },
    {
      label: t('quick_links.ministry_of_internal_affairs'),
      url: t('quick_links.ministry_of_internal_affairs_email'),
    },
    {
      label: t('quick_links.european_association_for_secure_transactions'),
      url: t('quick_links.european_association_for_secure_transactions_email'),
    },
  ];

  return (
    <footer className="bg-secondary pt-16 pb-4 text-white">
      <div className="pks-container">
        <div className="pks-layout-col-xl">
          <div className="flex flex-col gap-16 md:flex-row md:justify-between">
            {/* Company Logo and Social Media */}
            <div className="pks-layout-col-xl">
              <Logo src={logo} />
              <SocialMedia data={socialMediaLinks} />
            </div>
            {/* Contact Info */}
            <div className="pks-layout-col-xl">
              <div className="h3">{t('contact.contact_info')}</div>
              <ul className="pks-layout-col-md">
                {contactInfoLinks.map((link) => {
                  const { id, label, url, external, ariaLabel } = link;
                  return (
                    <li key={url}>
                      {url && (
                        <a
                          className="inline-flex gap-2 hover:text-primary-300"
                          href={url}
                          aria-label={ariaLabel}
                          target={external ? '_blank' : undefined}
                          rel={external ? 'noopener noreferrer' : undefined}
                        >
                          <Icon name={id} />
                          <span
                            className="block"
                            dangerouslySetInnerHTML={{
                              __html: label,
                            }}
                          ></span>
                        </a>
                      )}
                      {!url && (
                        <span className="inline-flex gap-2">
                          <Icon name={id} />
                          <span
                            className="block"
                            dangerouslySetInnerHTML={{
                              __html: label,
                            }}
                          ></span>
                        </span>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
            {/* Quick Links */}
            <div className="pks-layout-col-xl min-w-44">
              <div className="h3">{t('quick_links.quick_links')}</div>
              <nav>
                <ul className="pks-layout-col-md">
                  {quickLinks.map((link, index) => (
                    <li key={index} className="w-fit">
                      <Link
                        target="_blank"
                        className="block hover:text-primary-300"
                        to={link.url}
                      >
                        {link.label}
                      </Link>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>
          <div className="py-4 border-t border-t-white">
            <p>
              {t('global.all_rights_reserved')}, {new Date().getFullYear()}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
