import { useTranslation } from 'react-i18next';
import DropDown from '.';
import { useLanguage } from '../../../context/language-context';
import { LanguageTypes } from '../../../types';

const LanguageDropdown = () => {
  const { t } = useTranslation();
  const { currentLang, changeLanguage, langOptions } = useLanguage();

  const selectedOptionIndex = langOptions.findIndex(
    (lang) => lang.id === currentLang
  );

  return (
    <DropDown
      label={t('lang.choose_lang')}
      onChange={(selectedLang) => {
        changeLanguage(selectedLang as LanguageTypes);
      }}
      options={langOptions}
      selectedOptionIndex={selectedOptionIndex}
    ></DropDown>
  );
};

export default LanguageDropdown;
