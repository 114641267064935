import { useTranslation } from 'react-i18next';

import Meta from '../../components/layout/Meta';
import PageLayout from '../../layouts/PageLayout';
import Roles from '../../modules/roles/UI';

import { usePermission } from '../../hooks/usePermission';
import { useRedirect } from '../../hooks/useRedirect';
import { PermissionsEnum } from '../../types';

const RolesPage = () => {
  const { t } = useTranslation();
  const pageTitle = t('roles.roles_and_permissions_title');
  /* Permissions */
  const canListRoles = usePermission([PermissionsEnum.RolesList]);
  useRedirect({ redirect: canListRoles === false });

  return (
    <>
      <Meta title={pageTitle} />
      {canListRoles && (
        <PageLayout>
          <Roles />
        </PageLayout>
      )}
    </>
  );
};

export default RolesPage;
