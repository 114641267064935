import { useTranslation } from 'react-i18next';

import FraudCaseSingle from '../../modules/fraudCases/UI/FraudCaseSingle';
import Meta from '../../components/layout/Meta';
import PageLayout from '../../layouts/PageLayout';

import { FRAUD_CASES_LINK } from '../../constants';
import { usePermission } from '../../hooks/usePermission';
import { useRedirect } from '../../hooks/useRedirect';
import { PermissionsEnum } from '../../types';

function FraudCaseSinglePage() {
  const { t } = useTranslation();
  const canListFraudCases = usePermission([PermissionsEnum.FraudcasesList]);
  const canViewFraudCaseData = usePermission([PermissionsEnum.FraudcaseData]);
  const shouldRedirectToFraudcases =
    canListFraudCases === true && canViewFraudCaseData === false;
  const shouldRedirectToHome =
    canListFraudCases === false && canViewFraudCaseData === false;

  useRedirect({
    redirect: shouldRedirectToFraudcases || shouldRedirectToHome,
    path: shouldRedirectToFraudcases ? FRAUD_CASES_LINK : undefined,
  });

  return (
    <>
      <Meta title={t('fraud_cases.fraud_case')} />
      <PageLayout>{canViewFraudCaseData && <FraudCaseSingle />}</PageLayout>
    </>
  );
}

export default FraudCaseSinglePage;
