import { useTranslation } from 'react-i18next';
import {
  useForm,
  FormProvider,
  SubmitHandler,
  FieldValues,
} from 'react-hook-form';

import Input from '../../../components/shared/Input';
import ModalButton from '../../../components/shared/Modal/ModalButton';

import { hasPermission, showToast } from '../../../utils';

import RegionsStore from '../services/regions.store';
import { RegionInterface } from '../services/regions.types';
import { PermissionsEnum } from '../../../types';
import { useValidationRules } from '../../../hooks/useValidation';

interface FormValuesInterface {
  region: string;
  // status: boolean;
}

interface RegionFormProps {
  region?: RegionInterface;
  onClose: () => void;
}

const regionsStore = new RegionsStore();

/**
 * Form for creating or editing a region.
 *
 * @component
 * @param {RegionFormProps} props - The props object for this component.
 * @param {RegionInterface} [props.region] - The region to edit, if any. If not provided, a new region will be created.
 * @param {() => void} props.onClose - A function to call when the form modal is closed.
 *
 * @returns {React.JSX.Element} The rendered RegionForm component.
 */
const RegionForm = ({
  region,
  onClose,
}: RegionFormProps): React.JSX.Element => {
  const { t } = useTranslation();
  const { REQUIRED_VALIDATION_RULE } = useValidationRules();
  const canStoreRegion =
    hasPermission([PermissionsEnum.RegionCreate]) ||
    hasPermission([PermissionsEnum.RegionEdit]);

  // const navigate = useNavigate();
  const isEditing = Boolean(region);

  // React Hook Form methods and state initialization
  const methods = useForm<FormValuesInterface>({
    defaultValues: {
      region: region?.name || '',
      // status: region?.status === RegionStatusEnum.Active || false,
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  /**
   * Handles form submission.
   */
  const onSubmit: SubmitHandler<FieldValues> = async (data): Promise<void> => {
    if (!canStoreRegion) return;

    const { region: name } = data as FormValuesInterface;

    const { success, message } = region?.id
      ? await regionsStore.updateRegion({
          id: region.id,
          name,
          status: 1,
        })
      : await regionsStore.addRegion({
          name,
          status: 1,
        });

    showToast(message, success);
    onClose();
  };

  return (
    <>
      {canStoreRegion && (
        <FormProvider {...methods}>
          <div className="pks-layout-col-md w-full">
            <div className="pks-layout-col">
              {/* Email Input */}
              <Input
                type="text"
                id="region"
                name="region"
                label={`${t('inputs.add_region.label')}*`}
                placeholder={t('inputs.add_region.placeholder')}
                autoFocus
                autoComplete="off"
                validationRules={REQUIRED_VALIDATION_RULE}
              />
            </div>
            {isEditing && (
              <ModalButton
                onClick={handleSubmit(onSubmit)}
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? t('state.submitting') : t('global.edit')}
              </ModalButton>
            )}
            {!isEditing && (
              <ModalButton
                onClick={handleSubmit(onSubmit)}
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? t('state.submitting') : t('global.add')}
              </ModalButton>
            )}
          </div>
        </FormProvider>
      )}
    </>
  );
};

export default RegionForm;
