import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useRegions } from '../context/regions-context';

const useRedirect = ({
  redirect,
  path = '/',
  message,
}: {
  redirect: boolean;
  path?: string;
  message?: string;
}) => {
  const navigate = useNavigate();
  const { isRefetching } = useRegions();
  const [hasRedirected, setHasRedirected] = useState(false);

  const finalMessage =
    message ||
    `Nemate odgovarajuća ovlašćenja za pristup sadržaju. Uskoro ćete biti preusmereni na početnu stranicu.`;

  useEffect(() => {
    if (isRefetching || hasRedirected) return;

    if (redirect === true) {
      toast.error(finalMessage, {
        position: 'top-center',
        autoClose: 4000,
      });

      const timeoutId = setTimeout(() => {
        navigate(path, { replace: true });
        setHasRedirected(true);
      }, 4000);

      return () => clearTimeout(timeoutId); // Cleanup on unmount
    }
  }, [
    finalMessage,
    hasRedirected,
    isRefetching,
    message,
    navigate,
    path,
    redirect,
  ]);
};

export { useRedirect };
