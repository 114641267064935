import { FieldValues } from 'react-hook-form';
import { AxiosError } from 'axios';

import { translate as t } from '../../../config/i18n.config';
import { FAQResponse, InfoResponse } from './info.types';
import { ActionResponse, ErrorResponse } from '../../../types';
import { formatDateWithPoints, handleErrors } from '../../../utils';

import InfoApi from './info.api';
import { InfoPageTypeEnum } from '../../../pages/info';
import {
  keepPreviousData,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import queryClient from '../../../config/tanstackQueryConfig';
const api: InfoApi = new InfoApi();

const QUERY_KEY = 'info';
const QUERY_KEY_FAQ = 'quanda';
const QUERY_KEY_FC = 'forum-council';

class InfoStore {
  useGetInfo = (type: InfoPageTypeEnum) => {
    return useQuery(infoQueryOptions(type));
  };

  useGetFAQ = () => {
    return useQuery(faqQueryOptions());
  };

  useGetForumCouncil = () => {
    return useQuery(forumCouncilQueryOptions());
  };

  addInfo = async ({
    data,
    type,
  }: {
    data: FieldValues;
    type: InfoPageTypeEnum;
  }): Promise<ActionResponse<InfoResponse>> => {
    try {
      const res = await api.addInfo({ data, type });
      const resData = res.data;

      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY, { type }],
      });

      return {
        success: true,
        message: t('global.entry_successfully_saved'),
        data: resData,
      };
    } catch (error) {
      const resError = error as AxiosError;
      const data = resError?.response?.data;
      const code = resError?.response?.request?.status || null;

      if (data) {
        const { message, success } = data as ErrorResponse;
        const newMessage = message || data;
        return {
          message: newMessage || t('error'),
          success,
          code,
        };
      } else {
        return {
          message: t('error'),
          success: false,
        };
      }
    }
  };

  addFAQ = async ({
    data,
  }: {
    data: FieldValues;
  }): Promise<ActionResponse<FAQResponse>> => {
    try {
      const res = await api.addFAQ({
        contentJson: data.questions,
        regionId: data.regionId,
      });
      const resData = res?.data;

      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_FAQ],
      });

      return {
        success: true,
        message: t('global.entry_successfully_saved'),
        data: resData,
      };
    } catch (error) {
      const resError = error as AxiosError;
      const data = resError?.response?.data;
      const code = resError?.response?.request?.status || null;

      if (data) {
        const { message, success } = data as ErrorResponse;
        const newMessage = message || data;
        return {
          message: newMessage || t('error'),
          success,
          code,
        };
      } else {
        return {
          message: t('error'),
          success: false,
        };
      }
    }
  };

  addForumCouncil = async ({
    data,
  }: {
    data: any;
  }): Promise<ActionResponse<FAQResponse>> => {
    try {
      const res = await api.addForumCouncil({
        contentJson: data.contentJson,
        regionId: data.regionId,
      });

      const resData = res?.data;

      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_FC],
      });

      return {
        success: true,
        message: t('global.entry_successfully_saved'),
        data: resData,
      };
    } catch (error) {
      const resError = error as AxiosError;
      const data = resError?.response?.data;
      const code = resError?.response?.request?.status || null;

      if (data) {
        const { message, success } = data as ErrorResponse;
        const newMessage = message || data;
        return {
          message: newMessage || t('error'),
          success,
          code,
        };
      } else {
        return {
          message: t('error'),
          success: false,
        };
      }
    }
  };
}

export default InfoStore;

export const infoQueryOptions = (type: InfoPageTypeEnum): UseQueryOptions => ({
  queryKey: [QUERY_KEY, { type }],
  queryFn: async () => {
    try {
      const res = await api.getInfo(type);
      const resData = res.data;
      const date = formatDateWithPoints(
        resData.date_edited.date || resData.date_created.date
      );

      const transformedData: InfoResponse = resData && {
        content: resData.content,
        regionId: resData.region_id,
        date: date,
      };

      return {
        success: true,
        message: resData?.message || t('succes'),
        data: transformedData,
      };
    } catch (error) {
      const { success, message } = handleErrors(error);

      return {
        success,
        message,
      };
    }
  },
  placeholderData: keepPreviousData,
});

export const faqQueryOptions = (): UseQueryOptions => ({
  queryKey: [QUERY_KEY_FAQ],
  queryFn: async () => {
    try {
      const res = await api.getInfo('qanda');
      const resData = res.data;
      const date = formatDateWithPoints(
        resData.date_edited.date || resData.date_created.date
      );

      const transformedData: FAQResponse = resData && {
        content: resData.content_json,
        regionId: resData.region_id,
        date: date,
      };

      return {
        success: true,
        message: resData?.message || t('succes'),
        data: transformedData,
      };
    } catch (error) {
      const { success, message } = handleErrors(error);

      return {
        success,
        message,
      };
    }
  },
  placeholderData: keepPreviousData,
});

export const forumCouncilQueryOptions = (): UseQueryOptions => ({
  queryKey: [QUERY_KEY_FC],
  queryFn: async () => {
    try {
      const res = await api.getInfo('adviceforum');
      const resData = res.data;
      const date = formatDateWithPoints(
        resData.date_edited.date || resData.date_created.date
      );

      const transformedData: any = resData && {
        content: resData.content_json,
        regionId: resData.region_id,
        date: date,
      };

      return {
        success: true,
        message: resData?.message || t('succes'),
        data: transformedData,
      };
    } catch (error) {
      const { success, message } = handleErrors(error);

      return {
        success,
        message,
      };
    }
  },
  placeholderData: keepPreviousData,
});
