import { useTranslation } from 'react-i18next';

import Icon from '../Icon';
import InfoMessage from '../InfoMessage';
import ConfirmDenyDialog from '../ModalNew/ConfirmDenyDialog';

import { useAuth } from '../../../context/auth-context';
import { useModal } from '../../../context/modal-context';
import { usePermission } from '../../../hooks/usePermission';
import { PermissionsEnum } from '../../../types';

const Comments = ({
  title,
  comments,
  isLoading = false,
  onDelete,
}: {
  title?: string;
  comments: any[];
  isLoading?: boolean;
  onDelete: (...args: any[]) => void;
}) => {
  const { t } = useTranslation();
  const { userId } = useAuth();
  const { openModal, closeModal } = useModal();
  function decodeHtml(html: string) {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = html;
    return textArea.value;
  }

  const canDeleteComment = usePermission([
    PermissionsEnum.FraudcaseCommentDelete,
  ]);

  const handleDeleteComment = (id: string) => {
    onDelete(id);
  };

  const handleOpenModal = (id: string) => {
    openModal(
      <>
        <ConfirmDenyDialog
          infoMessage={`${t('comments.delete_comment_question')} ${t(
            'global.irreversible_action'
          )}'`}
          onConfirm={handleDeleteComment.bind(null, id)}
          onDeny={closeModal}
          closeModal={closeModal}
        />
      </>,
      t('comments.delete_comment')
    );
  };

  return (
    <div className="pks-layout-col-md">
      <h3 className="flex justify-between items-center">
        {title || t('comments.comments_questions')}
        {isLoading && (
          <Icon className="w-5 block animate-spin" name="searchSpinner" />
        )}
      </h3>
      <div className="relative">
        <ul className="pks-layout-col-xl">
          {comments && comments?.length === 0 && !isLoading ? (
            <li className="pb-4">
              <InfoMessage icon="info" message={t('comments.no_comments')} />
            </li>
          ) : (
            comments?.map((comment) => (
              <li
                id={comment.id}
                key={comment.id}
                className="pb-4 border-b border-secondary-400"
              >
                {/* Comment Text && Delete */}
                <div>
                  {/* Delete */}
                  {(canDeleteComment || comment.createdById === userId) &&
                    !isLoading && (
                      <button
                        className="float-right p-2 -mt-2 ml-1 rounded-md bg-red-50 hover:bg-red-100"
                        onClick={handleOpenModal.bind(null, comment.id)}
                      >
                        <Icon name="trash" className="w-4" />
                      </button>
                    )}
                  {/* Text */}
                  <div className="mb-1 break-words">
                    {decodeHtml(comment?.description)}
                  </div>
                </div>

                {/* Comment Details */}
                <div className="flex gap-2 justify-between text-secondary-400">
                  <div className="flex-1">
                    {/* User */}
                    {comment?.createdBy && (
                      <span className="block font-bold">
                        {comment.createdBy}
                      </span>
                    )}
                    {/* User Organization */}
                    {comment?.organization && (
                      <span className="block">{comment.organization}</span>
                    )}
                  </div>
                  {/* Date */}
                  <span className="flex items-end">{comment?.date}</span>
                </div>
              </li>
            ))
          )}
        </ul>
      </div>
    </div>
  );
};

export default Comments;
