import { useTranslation } from 'react-i18next';

import LazyImage from '../../../components/shared/LazyImage';
import placeholder from '../../../assets/images/placeholders/news.png';
import placeholderCompact from '../../../assets/images/placeholders/news-square.png';
import placeholderLarge from '../../../assets/images/placeholders/news-large.png';

import { ViewEnum } from '../../../types';
import NewsStore from '../services/news.store';
const newsStore: NewsStore = new NewsStore();

const NewsImage = ({
  id,
  title,
  view,
  eagerNumber,
  index,
}: {
  id: number;
  title?: string;
  view: ViewEnum;
  eagerNumber?: number;
  index: number;
}) => {
  const { t } = useTranslation();
  const { data: imageQueryData } = newsStore.useGetNewsPhoto({
    id,
    enabled: true,
  });

  const image =
    (imageQueryData?.success && imageQueryData?.data?.fileUrl) || undefined;

  const placeholderImage =
    view === ViewEnum.Compact
      ? placeholderCompact
      : view === 'grid' && index === 0
      ? placeholderLarge
      : placeholder;

  const newsImgClassName = `object-cover ${
    image === undefined ? 'animate-pulse' : 'animate-fade-in'
  } ${
    view === ViewEnum.Compact
      ? 'w-[60px] h-[60px]'
      : 'w-full h-full transition-transform transform group-hover:scale-105'
  }`;

  return (
    <LazyImage
      alt={title || t('image.image_unavailable')}
      src={image || placeholderImage}
      loading={
        eagerNumber !== undefined && index < eagerNumber ? 'lazy' : 'lazy'
      }
      className={newsImgClassName}
    />
  );
};

export default NewsImage;
