import {
  keepPreviousData,
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { translate as t } from '../../../config/i18n.config';
import {
  ActionResponse,
  ErrorResponse,
  SuccessResponse,
  UploadFileInterface,
} from '../../../types';

import EducationApi from './education.api';
import { DocumentProps } from './education.types';
import queryClient from '../../../config/tanstackQueryConfig';
import { handleErrors } from '../../../utils';
import { NewsPaginationInterface } from '../../news/services/index.types';

import { QUERY_KEY as searchQueryKey } from '../../search/services/search.store';

export const QUERY_KEYS = {
  DOCUMENTS: 'documents',
};

const api: EducationApi = new EducationApi();

class EducationStore {
  useGetDocuments = ({
    page,
    type,
    searchTerm,
    region,
    organization,
    rowsPerPage,
    enabled = true,
  }: {
    page?: number;
    type?: string;
    searchTerm?: string;
    region?: string;
    organization?: string;
    rowsPerPage?: any;
    enabled?: boolean | null;
  }) => {
    return useQuery(
      getDocumentsQueryOptions({
        page,
        type,
        searchTerm,
        region,
        organization,
        rowsPerPage,
        enabled,
      })
    );
  };

  useGetEducationDocument = ({
    id,
    type,
  }: DocumentProps): UseQueryResult<any, Error> => {
    return useQuery(educationDocumentOptions({ id, type }));
  };

  getDocument = async (fileId: number, type: string) => {
    try {
      const res: any = await api.getDocument(fileId, type);

      const imageBlob = new Blob([res.data], {
        type: res.headers['content-type'],
      });

      const imageFile = new File([imageBlob], `document_${fileId}`, {
        type: res.headers['content-type'],
      });

      const fileUrl = window.URL.createObjectURL(imageFile);

      return {
        success: true,
        data: {
          imageFile,
          fileUrl: fileUrl,
        },
      };
    } catch (error) {
      const { success, message } = handleErrors(error);
      return {
        success,
        message,
      };
    }
  };

  storeDocument = async ({
    id,
    data,
    type,
    onUpload,
  }: {
    id?: number;
    data: FormData;
    type: string;
    onUpload?: (
      percentage: number | null,
      fileNames?: UploadFileInterface[]
    ) => void;
  }) => {
    try {
      const res = id
        ? await api.storeDocument({ id, data, type, onUpload })
        : await api.storeDocument({ data, type, onUpload });
      const resData = res.data;
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.DOCUMENTS, { type }],
      });
      queryClient.invalidateQueries({ queryKey: [searchQueryKey] });
      return {
        success: true,
        message:
          resData?.message || id
            ? t('documents.document_successfully_updated')
            : t('documents.document_successfully_added'),
        data: resData,
      };
    } catch (error) {
      const resError = error as AxiosError;
      const data = resError?.response?.data;
      const code = resError?.response?.request?.status || null;

      if (data) {
        const { message, success } = data as ErrorResponse;
        const newMessage = message || data;
        return {
          message: newMessage || t('error'),
          success,
          code,
        };
      } else {
        return {
          message: t('error'),
          success: false,
        };
      }
    }
  };

  deleteDocument = async ({
    id,
    type,
  }: DocumentProps): Promise<
    ActionResponse<SuccessResponse<undefined> | ErrorResponse>
  > => {
    try {
      const res = await api.deleteDocument({ id, type });
      const resData = res.data;
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.DOCUMENTS, { type }],
      });
      queryClient.invalidateQueries({ queryKey: [searchQueryKey] });
      return {
        success: true,
        message:
          resData?.message || t('documents.document_successfully_deleted'),
      };
    } catch (error) {
      const { message, success } = handleErrors(error);
      return {
        message,
        success,
      };
    }
  };
}

export default EducationStore;

export const getDocumentsQueryOptions = ({
  page,
  type,
  searchTerm,
  region,
  organization,
  rowsPerPage,
  enabled = true,
}: {
  page?: number;
  type?: string;
  searchTerm?: string;
  region?: string;
  organization?: string;
  rowsPerPage?: any;
  enabled?: boolean | null;
}): UseQueryOptions<NewsPaginationInterface, Error> => ({
  queryKey: [
    QUERY_KEYS.DOCUMENTS,
    { page, type, searchTerm, region, organization, rowsPerPage },
  ],
  queryFn: async (): Promise<any> => {
    const response: AxiosResponse<any> = await api.getDocuments(
      page,
      type,
      searchTerm,
      region,
      rowsPerPage
    );
    return response.data;
  },
  placeholderData: keepPreviousData,
  refetchOnWindowFocus: true,
  enabled: enabled ? true : false,
});

export const educationDocumentOptions = ({
  id,
  type,
}: DocumentProps): UseQueryOptions<any, Error> => ({
  queryKey: [QUERY_KEYS.DOCUMENTS, { id, type }] as QueryKey,
  queryFn: async (): Promise<any> => {
    try {
      const res: AxiosResponse<any | ErrorResponse> =
        await api.getEducationDocument({
          id,
          type,
        });
      const resData = res.data;

      return {
        success: true,
        message: resData?.message,
        data: resData,
      };
    } catch (error) {
      const resError = error as AxiosError;
      const data = resError?.response?.data;
      const code = resError?.response?.request?.status || null;

      if (data) {
        const { message, success } = data as ErrorResponse;
        const newMessage = message || data;
        return {
          message: newMessage || t('error'),
          success,
          code,
        };
      } else {
        return {
          message: t('error'),
          success: false,
        };
      }
    }
  },
  placeholderData: keepPreviousData,
});
