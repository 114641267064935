import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import PageLayout from '../../layouts/PageLayout';
import PageTitleWithActions from '../../layouts/PageLayout/TitleWithActions';
import Meta from '../../components/layout/Meta';
import Button from '../../components/shared/Button';
import FraudCases from '../../modules/fraudCases/UI';
import FraudCasesGenerationButton from '../../modules/fraudCases/UI/FraudCasesGenerationButton';

import { FRAUD_CASE_ADD_PATH } from '../../constants';
import { usePermission } from '../../hooks/usePermission';
import { useRedirect } from '../../hooks/useRedirect';
import { PermissionsEnum } from '../../types';

function FraudCasesPage() {
  const { t } = useTranslation();
  /* Permissions */
  const canListFraudCases = usePermission([PermissionsEnum.FraudcasesList]);
  const canCreateFraudCase = usePermission([PermissionsEnum.FraudcaseCreate]);
  const canDownloadReport = usePermission([
    PermissionsEnum.FraudCaseReportPerRegion,
  ]);
  const shouldRedirect =
    canListFraudCases === false && canCreateFraudCase === false;

  const pageTitle = t('fraud_cases.title');
  useRedirect({
    redirect: shouldRedirect,
  });

  /* Page Title */
  const [title, setTitle] = useState(pageTitle);

  /* Handle Title Change */
  const handleTitleChange = useCallback(
    (newTitle: string) => {
      setTitle(newTitle || pageTitle);
    },
    [pageTitle]
  );

  return (
    <>
      <Meta title={pageTitle} />
      <PageLayout>
        <PageTitleWithActions title={title} isCol>
          {canCreateFraudCase && (
            <div className="inline-flex flex-wrap gap-4">
              <Link
                to={FRAUD_CASE_ADD_PATH}
                className="flex-auto focus:outline-none group"
              >
                <Button wide tabIndex={-1}>
                  {t('fraud_cases.new_fraud_case_link')}
                </Button>
              </Link>
              {canDownloadReport && (
                <div className="flex-auto">
                  <FraudCasesGenerationButton />
                </div>
              )}
            </div>
          )}
        </PageTitleWithActions>
        {canListFraudCases && <FraudCases onTitleChange={handleTitleChange} />}
      </PageLayout>
    </>
  );
}

export default FraudCasesPage;
