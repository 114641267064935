import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  AvailableRegionInterface,
  AvailableRegionsInterface,
  DropDownItemInterface,
} from '../types';
import {
  getAvailableRegionsStateFromLocalStorage,
  showSessionExpiredToast,
} from '../utils';
import {
  ACTIVE_REGION_KEY,
  REFRESHING_TOKEN_KEY,
  REGIONS_KEY,
} from '../constants';
import { useAuth } from './auth-context';

import AuthStore from '../modules/auth/services/auth.store';
import queryClient from '../config/tanstackQueryConfig';
const authStore = new AuthStore();

/**
 * Defines the context interface for managing available regions and dropdown behavior.
 */
interface RegionsContextType {
  availableRegionsData: AvailableRegionsInterface | undefined;
  setAvailableRegionsData: React.Dispatch<
    React.SetStateAction<AvailableRegionsInterface | undefined>
  >;
  availableRegionsDropdownItems: DropDownItemInterface[];
  activeRegion: AvailableRegionInterface | undefined;
  setActiveRegion: (region: AvailableRegionInterface) => void;
  isDropdownActive: boolean;
  onCloseDropdown: () => void;
  toggleDropdown: () => void;
  isRefetching: boolean;
  setIsRefetching: React.Dispatch<React.SetStateAction<boolean>>;
}

const RegionsContext = createContext<RegionsContextType | undefined>(undefined);

/**
 * Props for the RegionsProvider component.
 */
interface RegionsProviderProps {
  children: ReactNode;
}

/**
 * Provides the regions context to its children components.
 * @param {RegionsProviderProps} props - Props for the provider, including children components.
 */
const RegionsProvider = ({ children }: RegionsProviderProps) => {
  const { t } = useTranslation();
  const { isAuth, logoutAuthUser, updateAuthState } = useAuth();
  const [availableRegionsData, setAvailableRegionsData] = useState<
    AvailableRegionsInterface | undefined
  >(undefined);
  const [activeRegion, setActiveRegion] = useState<
    AvailableRegionInterface | undefined
  >(undefined);
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);

  /** Converts available regions into dropdown-compatible items */
  const availableRegionsDropdownItems: DropDownItemInterface[] =
    availableRegionsData?.regions.map((region) => ({
      id: region.id,
      label: region.name,
    })) || [];

  /**
   * Loads regions data from localStorage when authenticated.
   */
  useEffect(() => {
    if (!isAuth) {
      setAvailableRegionsData(undefined);
      setActiveRegion(undefined);
      return;
    }

    const storedRegions = getAvailableRegionsStateFromLocalStorage();

    if (storedRegions) {
      const availableRegionsList = storedRegions.regions;
      const activeRegionData =
        availableRegionsList.find(
          (region) => region.id === storedRegions.activeRegion
        ) || availableRegionsList[0];
      setActiveRegion({ ...activeRegionData, shouldRefetch: false });
      setAvailableRegionsData(storedRegions);
    } /* else {
      console.log('No regions found in localStorage.');
      //  Handled at src/components/layout/MainNavigationNew/AvailableRegions.tsx (allRegions is set to '1')
    } */
  }, [isAuth]);

  /**
   * Toggles the dropdown's open/close state.
   */
  const toggleDropdown = () => {
    setIsDropdownActive((prevState) => !prevState);
  };

  /**
   * Handles region selection, updating the active region, localStorage, and triggering navigation.
   * @param {AvailableRegionInterface} region - Selected region object.
   */
  const handleRegionSelect = async ({
    id,
    name,
    shouldRefetch = true,
  }: AvailableRegionInterface) => {
    setIsRefetching(true);

    // Update the active region
    setActiveRegion({ id, name });
    setIsDropdownActive(false);

    if (shouldRefetch) {
      localStorage.setItem(ACTIVE_REGION_KEY, id.toString());
      localStorage.setItem(REFRESHING_TOKEN_KEY, JSON.stringify(true));

      const response = await authStore.refreshToken({});
      const { success } = response;

      if (success) {
        updateAuthState();
        // Update the regions in localStorage
        const storedRegions = getAvailableRegionsStateFromLocalStorage();
        if (storedRegions) {
          const updatedRegions = {
            ...storedRegions,
            activeRegion: +id,
          };

          // Save the updated regions back to localStorage
          localStorage.setItem(REGIONS_KEY, JSON.stringify(updatedRegions));
          localStorage.setItem(ACTIVE_REGION_KEY, JSON.stringify(+id));
        } else {
          logoutAuthUser(true);
          showSessionExpiredToast(t('regions.no_permissions_for_region'));
        }
        localStorage.removeItem(REFRESHING_TOKEN_KEY);
        setIsRefetching(false);
      }
    } else {
      const monitorRefreshingToken = () => {
        const interval = setInterval(() => {
          const refreshingToken = localStorage.getItem('refreshing_token');
          if (!refreshingToken) {
            setIsRefetching(false);
            queryClient.invalidateQueries();

            // Stop the interval
            clearInterval(interval);
          } else {
            setIsRefetching(true);
          }
        }, 10);
      };
      monitorRefreshingToken();
    }

    //setIsRefetching(false);
  };

  /**
   * Closes the dropdown.
   */
  const handleClose = () => setIsDropdownActive(false);

  return (
    <RegionsContext.Provider
      value={{
        availableRegionsData,
        setAvailableRegionsData,
        availableRegionsDropdownItems,
        activeRegion,
        setActiveRegion: handleRegionSelect,
        isDropdownActive,
        onCloseDropdown: handleClose,
        toggleDropdown,
        isRefetching,
        setIsRefetching,
      }}
    >
      {children}
    </RegionsContext.Provider>
  );
};

// Create a custom hook to use the context
const useRegions = (): RegionsContextType => {
  const context = useContext(RegionsContext);
  if (!context) {
    throw new Error('useRegions must be used within a RegionsProvider');
  }
  return context;
};

export { RegionsProvider, useRegions };
