import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CheckboxAccordion from '../../../components/shared/Accordion/CheckboxAccordion';
import NoResultsInfoMessage from '../../../components/shared/InfoMessage/NoSearchResults';

import { PermissionsEnum, RegionCheckboxEnum } from '../../../types';
import { usePermission } from '../../../hooks/usePermission';
import { PermissionInterface } from '../services/role.types';

interface PermissionsListProps {
  hasAllPermissions?: RegionCheckboxEnum;
  permissions: PermissionInterface[];
  permissionsPerRole: number[];
  onCheckedItemsChange?: (value: number[]) => void;
}

const PermissionsList: React.FC<PermissionsListProps> = ({
  hasAllPermissions = 0,
  permissions,
  permissionsPerRole,
  onCheckedItemsChange,
}) => {
  const { t } = useTranslation();
  const [openAccordion, setOpenAccordion] = useState<string | null>(null); // State to track open accordion

  // Group permissions by their group and check if all items in the group are checked
  const canSetPermissions = usePermission([PermissionsEnum.RolePermissionsSet]);
  const canGetPermissions = usePermission([PermissionsEnum.RolePermissionsGet]);

  const groupedPermissions = useMemo(() => {
    return permissions.reduce<
      Record<string, { items: any[]; allChecked: boolean }>
    >((acc, permission) => {
      if (!acc[permission.group]) {
        acc[permission.group] = { items: [], allChecked: false };
      }

      const isChecked = permissionsPerRole.includes(permission.id);

      acc[permission.group].items.push({
        ...permission,
        checked: isChecked,
      });

      // Check if all items in the group are checked
      acc[permission.group].allChecked = acc[permission.group].items.every(
        (item) => item.checked
      );

      return acc;
    }, {});
  }, [permissions, permissionsPerRole]);

  // Handle the change of a single checkbox within a group
  const handleCheckboxChange = useCallback(
    (_groupId: string, itemId: number, checked: boolean) => {
      const updatedCheckedItems = checked
        ? [...permissionsPerRole, itemId]
        : permissionsPerRole.filter((id) => id !== itemId);

      // Pass the updated checked items directly to the parent
      onCheckedItemsChange?.(updatedCheckedItems);
    },
    [permissionsPerRole, onCheckedItemsChange]
  );

  // Handle the change of a group label checkbox
  const handleLabelCheckboxChange = useCallback(
    (groupId: string, checked: boolean) => {
      const groupItems = groupedPermissions[groupId].items.map(
        (item) => item.id
      );
      const updatedCheckedItems = checked
        ? Array.from(new Set([...permissionsPerRole, ...groupItems]))
        : permissionsPerRole.filter((id) => !groupItems.includes(id));

      // Pass the updated checked items directly to the parent
      onCheckedItemsChange?.(updatedCheckedItems);
    },
    [groupedPermissions, permissionsPerRole, onCheckedItemsChange]
  );

  // Handle accordion toggle
  const handleToggleAccordion = (groupId: string) => {
    setOpenAccordion((prev) => (prev === groupId ? null : groupId)); // Close if the same accordion is clicked, else open the new one
  };

  return (
    <ul className="flex flex-col gap-3">
      {permissions.length === 0 ? (
        <li>
          <NoResultsInfoMessage />
        </li>
      ) : (
        <div className="flex flex-col gap-3">
          {Object.entries(groupedPermissions).map(
            ([group, { items: groupPermissions, allChecked }]) => (
              <CheckboxAccordion
                key={group}
                groupId={group}
                label={group}
                showToggle={canGetPermissions}
                showItemsCount={canGetPermissions ? true : false}
                labelChecked={!hasAllPermissions ? allChecked : true}
                labelDisabled={
                  !hasAllPermissions && canSetPermissions && canGetPermissions
                    ? false
                    : true
                }
                disabledText={`${
                  hasAllPermissions && canSetPermissions
                    ? t(
                        'permissions.cannot_change_permissions_due_to_allow_all'
                      )
                    : !hasAllPermissions && canGetPermissions
                    ? t('permissions.not_authorized_to_change_permissions')
                    : ''
                }`}
                items={groupPermissions.map((perm) => ({
                  id: perm.id,
                  name: perm.description,
                  checked: !hasAllPermissions ? perm.checked : true,
                  showToggle: canGetPermissions && canSetPermissions,
                  disabled:
                    !hasAllPermissions && canSetPermissions ? false : true,
                  disabledText: `${
                    !canSetPermissions
                      ? 'Niste ovlašćeni da menjate dozvole za datu rolu.'
                      : 'Nije moguće onemogućiti permisije za zadatu rolu jer je izabrana opcija "Dozvoli sve permisije".'
                  }`,
                }))}
                onCheckboxChange={
                  !hasAllPermissions ? handleCheckboxChange : undefined
                }
                onLabelCheckboxChange={
                  !hasAllPermissions ? handleLabelCheckboxChange : undefined
                }
                isOpen={openAccordion === group} // Pass the state to indicate whether this accordion is open
                onToggleAccordion={() => handleToggleAccordion(group)} // Trigger the toggle function when clicked
              />
            )
          )}
        </div>
      )}
    </ul>
  );
};

export default PermissionsList;
