import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../../components/shared/Icon';
import { ChatTabsEnum } from '../../../types/Chat';
import ChatDirectConversationsList from './ChatDirectConversationsList';
import { Chat } from '../../../types/Chat';
import { globalWS } from '../../../init';
import useUnseenMessages from '../../../hooks/Chat/useUnseenMessagesCount';
import ChatRegionConversationsList from './ChatRegionConversationsList';

interface ChatSidebarProps {
  conversations: any;
  regionChatConversations: any;
  onSelectCurrentChat: any;
  // avatarsList: any;
  allRegions: any;
  allUsers: any;
  currentOpenChat: Chat | null;
}
interface UnseenChat {
  _id: number;
  count: number;
}

interface UnseenChatsResponse {
  data: any[];
}

const ChatSidebar = ({
  conversations,
  onSelectCurrentChat,
  currentOpenChat,
  allRegions,
  allUsers,
  regionChatConversations,
}: // avatarsList,
ChatSidebarProps) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<ChatTabsEnum>(ChatTabsEnum.Direct);
  const handleChangeActiveTab = (activeTab: ChatTabsEnum) => {
    setActiveTab(activeTab);
    if (activeTab === 'group' && regionChatConversations.length === 1) {
      onSelectCurrentChat(regionChatConversations[0]);
    }
  };
  const tabBasicStyling =
    'uppercase inline-block   border-b border-b-2 hover:cursor-pointer ';
  const tabIsActive = `${tabBasicStyling} border-b-primary-300  text-primary-300 font-bold text-center  `;
  const tabIsInactive = `${tabBasicStyling} border-b-transparent text-center`;

  const {
    countUnseenMessagesBySender,
    countUnseenMessagesForRegion,
    setCountUnseenMessagesBySender,
    setCountUnseenMessagesForRegion,
  } = useUnseenMessages();

  const fetchUnseenChats = useCallback(async () => {
    try {
      const chats: UnseenChatsResponse =
        await globalWS.getUnseenCountBySender();
      setCountUnseenMessagesBySender(chats?.data);
    } catch (error) {
      console.error('Error:', error);
    }

    try {
      const chats: UnseenChatsResponse =
        await globalWS.getUnseenCountForRegion();
      setCountUnseenMessagesForRegion(chats?.data);
    } catch (error) {
      console.error('Error:', error);
    }
  }, [setCountUnseenMessagesBySender, setCountUnseenMessagesForRegion]);

  useEffect(() => {
    fetchUnseenChats();
  }, [fetchUnseenChats]);

  return (
    <>
      <div className="pr-4 pl-4 lg:bg-secondary-600 bg-secondary-50 h-full lg:border-r-2 w-full lg:w-1/3 lg:p-4 flex flex-col pb-4">
        {/* HEADING CONTAINER */}
        <div className="flex justify-between items-center h-fit">
          <h1>{t('chat.chat')}</h1>
          <span
            onClick={() => onSelectCurrentChat(null)}
            className={`w-fit min-h-12 px-3 py-2.5 text-white rounded-3xl shadow-floatingButton bg-primary-300 hover:bg-primary flex align-center items-center gap-3 hover:cursor-pointer`}
          >
            <Icon name="plusRounded" />
          </span>
        </div>
        {/* TAB CONTAINER */}
        <div className="mt-6 pb-3  h-fit">
          <ul className="flex items-center  gap-5">
            <li
              onClick={() => handleChangeActiveTab(ChatTabsEnum.Direct)}
              className={`${
                activeTab === ChatTabsEnum.Direct ? tabIsActive : tabIsInactive
              } relative`}
            >
              {t('chat.direct_chat')}
              {countUnseenMessagesBySender &&
                countUnseenMessagesBySender?.[0]?.count > 0 && (
                  <div className="size-1 bg-secondary-500 absolute top-0 -right-2  rounded-full translate-y-1.5"></div>
                )}
            </li>
            <li
              onClick={() => handleChangeActiveTab(ChatTabsEnum.Group)}
              className={`${
                activeTab === ChatTabsEnum.Group ? tabIsActive : tabIsInactive
              } relative`}
            >
              {t('chat.group_chat')}
              {typeof countUnseenMessagesForRegion === 'number' &&
                countUnseenMessagesForRegion >= 1 && (
                  <div className="size-1 bg-secondary-500 absolute top-0 -right-2 rounded-full translate-y-1.5"></div>
                )}
            </li>
          </ul>
        </div>

        {/* DYNAMISCHER INHALT */}
        <div className="flex-grow overflow-y-auto scrollbar ">
          {activeTab === ChatTabsEnum.Direct && (
            <ChatDirectConversationsList
              conversations={conversations}
              // avatarsList={avatarsList}
              currentOpenChat={currentOpenChat}
              activeTab={activeTab}
              onSelectCurrentChat={onSelectCurrentChat}
              unseenChats={countUnseenMessagesBySender}
            />
          )}
          {activeTab === ChatTabsEnum.Group && (
            <ChatRegionConversationsList
              allRegions={allRegions}
              allUsers={allUsers}
              activeTab={activeTab}
              countUnseenForRegion={countUnseenMessagesForRegion}
              regionChatConversations={regionChatConversations}
              onSelectCurrentChat={onSelectCurrentChat}
              currentOpenChat={currentOpenChat}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ChatSidebar;
