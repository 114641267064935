import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import Button from '../../components/shared/Button';
import DocumentEntryForm from '../../modules/documents/UI/DocumentsEntryForm';
import Meta from '../../components/layout/Meta';
import PageLayout from '../../layouts/PageLayout';

import { ORGANIZATION_SECTION_PATH } from '../../constants';
import { usePermission } from '../../hooks/usePermission';
import { PermissionsEnum } from '../../types';

function DocumentEntryPage() {
  const navigate = useNavigate();
  const { slug, id } = useParams();
  const { t } = useTranslation();

  const pageTitle = id
    ? slug === 'decisions'
      ? t('documents.edit_decision')
      : t('documents.edit_workreport')
    : slug === 'decisions'
    ? t('documents.add_decision')
    : t('documents.add_workreport');

  const currentType =
    slug === 'decisions' || slug === 'workreports' ? slug : 'decisions';

  /* Permissions */
  const canViewDocuments = usePermission([PermissionsEnum.DocumentData]);

  return (
    <>
      <p
        onClick={() => navigate(`${ORGANIZATION_SECTION_PATH}/${slug}`)}
        className="mb-8 flex gap-2 items-center w-fit justify-center b sm:flex-none focus:outline-none group"
      >
        <Button type="button" variant="back" tabIndex={-1}>
          {t('global.back_to_list')}
        </Button>
      </p>
      <Meta title={pageTitle} />
      <PageLayout title={pageTitle}>
        {canViewDocuments && <DocumentEntryForm currentType={currentType} />}
      </PageLayout>
    </>
  );
}

export default DocumentEntryPage;
