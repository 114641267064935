import { RefObject, useEffect } from 'react';

type UseInfiniteScrollParams = {
  sentinelRef: RefObject<HTMLElement>;
  hasNextPage: boolean;
  isLoading: boolean;
  loadMore: () => void;
};

const useInfiniteScrollSentinel = ({
  sentinelRef, // Reference to the sentinel element
  loadMore, // Function to load more items
  hasNextPage, // Whether there are more items to load
  isLoading, // Whether data is currently being loaded
}: UseInfiniteScrollParams) => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const firstEntry = entries[0];
        if (firstEntry.isIntersecting && hasNextPage && !isLoading) {
          loadMore(); // Load more items when the sentinel element is in view
        }
      },
      { rootMargin: '100px 0px', threshold: 1.0 } // Trigger when 100% of the sentinel is visible
    );

    const sentinelElement = sentinelRef.current;
    if (sentinelElement) {
      observer.observe(sentinelElement); // Start observing the sentinel element
    }

    return () => {
      if (sentinelElement) {
        observer.unobserve(sentinelElement); // Cleanup observer on unmount
      }
    };
  }, [sentinelRef, loadMore, hasNextPage, isLoading]);
};

export { useInfiniteScrollSentinel };

/*
type UseInfiniteScrollParams = {
	ref: RefObject<HTMLElement>;
	hasNextPage: boolean;
	isLoading: boolean;
	fetchNextPage: () => void;
};

const useInfiniteScroll = ({
	ref,
	hasNextPage,
	isLoading,
	fetchNextPage,
}: UseInfiniteScrollParams) => {
	useEffect(() => {
		const handleScroll = () => {
			const element = ref.current;
			if (element) {
				if (
					element.scrollTop + element.clientHeight >=
					element.scrollHeight
				) {
					if (hasNextPage && !isLoading) {
						fetchNextPage();
					}
				}
			}
		};

		const element = ref.current;
		if (element) {
			element.addEventListener('scroll', handleScroll);
		}

		return () => {
			if (element) {
				element.removeEventListener('scroll', handleScroll);
			}
		};
	}, [ref, hasNextPage, isLoading, fetchNextPage]);
};

export default useInfiniteScroll;
 */
