import * as ICONS from './Icons';

interface IconProps {
  name: keyof typeof ICONS;
  className?: string;
  tabIndex?: number;
  ariaLabel?: string;
}

const Icon: React.FC<IconProps> = ({
  name,
  className,
  tabIndex,
  ariaLabel,
}) => {
  const Icon = ICONS[name];

  if (!Icon) {
    return null;
  }

  return (
    <span tabIndex={tabIndex} className={className} aria-label={ariaLabel}>
      {Icon}
    </span>
  );
};

export default Icon;
