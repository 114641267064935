import {
  useForm,
  FormProvider,
  SubmitHandler,
  FieldValues,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, Form } from 'react-router-dom';

import Button from '../../../components/shared/Button';
import Input from '../../../components/shared/Input';

import { LOGIN_PAGE_LINK } from '../../../constants';
import {
  extractMultipleErrors,
  hasNestedObjects,
  showToast,
} from '../../../utils';

import AuthStore from '../services/auth.store';
import { useValidationRules } from '../../../hooks/useValidation';
const authStore: AuthStore = new AuthStore();

/**
 * LoginForm component for handling user authentication.
 *
 * @component
 * @returns {JSX.Element} The rendered Login form.
 */
const ForgotPassword = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { EMAIL_VALIDATION_RULES } = useValidationRules();

  // Initialize React Hook Form methods with default values
  const methods = useForm({
    defaultValues: {
      email: '',
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    setError,
  } = methods;

  /**
   * Handles form submission.
   *
   * @param {FieldValues} data - The form data including username and password.
   * @returns {Promise<void>} A promise that resolves when the submission is complete.
   */
  const onSubmit: SubmitHandler<FieldValues> = async (
    data: FieldValues
  ): Promise<void> => {
    const email = data.email;

    if (!email) return;

    const { success, message } = await authStore.forgetPassword(email);

    if (success) {
      showToast(message, success);
      navigate(LOGIN_PAGE_LINK, { replace: true });
    } else {
      if (hasNestedObjects(message)) {
        // Set input fields errors
        const errorMessages = extractMultipleErrors(message);

        for (const key in errorMessages) {
          setError(key as 'email', {
            type: 'backend',
            message: errorMessages[key],
          });
        }
      } else {
        // Show toast error
        showToast(message, success, true);
      }
    }
  };

  return (
    <>
      {/* Form wrapped in FormProvider to use React Hook Form context */}
      <FormProvider {...methods}>
        <Form
          method="post"
          className="pks-layout-col-xl w-full"
          onSubmit={handleSubmit(onSubmit)}
        >
          {/* Form inputs and forgot password link */}
          <div className="pks-layout-col">
            {/* Email Input Field */}
            <Input
              type="text"
              id="email"
              name="email"
              label={`${t('inputs.email.label')} *`}
              placeholder={t('inputs.email.placeholder')}
              autoFocus
              autoComplete="off"
              validationRules={EMAIL_VALIDATION_RULES}
            />
          </div>
          {/* Submit Button */}
          <Button type="submit" wide disabled={isSubmitting}>
            {isSubmitting ? t('state.submitting') : t('state.submit')}
          </Button>
        </Form>
      </FormProvider>
    </>
  );
};

export default ForgotPassword;
