import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const FilePreview = ({ file }: { file: any }) => {
  const { t } = useTranslation();
  const linkProps = file.url
    ? { target: '_blank', rel: 'noopener noreferrer' }
    : {};
  return (
    <div>
      <Link
        to={`${file.url ? file.url : '#'}`}
        {...linkProps}
        className="underline"
      >
        {t('global.uploaded')} {file?.filename}
      </Link>
    </div>
  );
};

export default FilePreview;
