import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ChatMessage from './ChatMessage';
import ChatMessageInput from './ChatMessageInput';
import ChatTimeBorder from './ChatTimeBorder';
import ChatUserIcon from './ChatUserIcon';
import Icon from '../../../components/shared/Icon';
import ChatInfo from './ChatInfo';
import ChatSearchBar from './ChatSearchBar';
import { globalWS } from '../../../init';
import { normalizeTimestamp } from '../../../components/shared/DropdownSelect/utils';
import { useAuth } from '../../../context/auth-context';
import { useRegions } from '../../../context/regions-context';
import { useTranslation } from 'react-i18next';

interface ChatConversationPanelProps {
  currentOpenChat: any | null;
  allUsers: any;
  allRegions: any;
  onSelectCurrentChat: any;
  // avatarsList: any;
  conversations: any;
  onTogglePanelVisibility?: () => void;
  handleMoveChatToTop: any;
}

const ChatConversationPanel: React.FC<ChatConversationPanelProps> = ({
  currentOpenChat,
  onSelectCurrentChat,
  onTogglePanelVisibility,
  // avatarsList,
  allUsers,
  allRegions,
  conversations,
  handleMoveChatToTop,
}) => {
  const { t } = useTranslation();
  const { userId, token } = useAuth();

  const [currentChatMessages, setCurrentChatMessages] = useState<any>([]);
  const [currentChatPage, setCurrentChatPage] = useState(1);

  // INFINITE SCROLL
  const scrollRef = useRef<HTMLDivElement>(null);
  const loadChatsRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isBottom, setIsBottom] = useState(false);
  const estimatedMessageHeight = 64;
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [previousChatMessages, setPreviousChatMessages] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  // INFINITE SCROLL

  // const fetchChatHistory = useCallback(async () => {
  //   if (!currentOpenChat?.id) return;
  //   try {
  //     await globalWS.getCurrentChat(token, userId, currentOpenChat?.id, 1);
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // }, [currentOpenChat, token, userId]);

  // useEffect(() => {
  //   fetchChatHistory();
  // }, [fetchChatHistory]);

  const updateCurrentChatMessages = (messages: any[]) => {
    if (!messages) return;
    if (messages.length === 0) {
      setHasMore(false);
    } else {
      setCurrentChatMessages((prev: any[]) => {
        const messageMap = new Map();

        prev.forEach((msg) => messageMap.set(msg.id, msg));

        messages.forEach((msg) => messageMap.set(msg.id, msg));

        return Array.from(messageMap.values()).sort(
          (a, b) =>
            normalizeTimestamp(a.timestamp) - normalizeTimestamp(b.timestamp)
        );
      });
    }
  };

  useEffect(() => {
    const fetchChatHistoryAndUpdateMessages = async (message: any) => {
      if (!currentOpenChat?.id) return;
      handleMoveChatToTop(message.chatId);
      await globalWS.getCurrentChat(token, userId, currentOpenChat?.id, 1);
      scrollRef?.current?.scrollIntoView({ behavior: 'auto' });

      // const messages = (globalWS.currentChat as any)?.data?.messages || [];
      // if (messages && messages.length > 0) {
      //   setCurrentChatMessages(() => {
      //     const updatedMessages = [...messages];
      //     return updatedMessages.sort(
      //       (a, b) =>
      //         normalizeTimestamp(a.timestamp) - normalizeTimestamp(b.timestamp)
      //     );
      //   });
      // } else return;
    };

    globalWS.on('chatHistoryReceived', updateCurrentChatMessages);
    globalWS.on('textMessageReceived', fetchChatHistoryAndUpdateMessages);

    return () => {
      globalWS.off('chatHistoryReceived', updateCurrentChatMessages);
      globalWS.off('textMessageReceived', fetchChatHistoryAndUpdateMessages);
    };
  }, [
    currentOpenChat,
    currentChatPage,
    setCurrentChatMessages,
    token,
    userId,
    hasMore,
    handleMoveChatToTop,
  ]);

  // Messages where fromId === currentOpenChat.chatPartnerId are beeing filtered and seet as seen
  useEffect(() => {
    const getUnseenMessages = async (messages: any) => {
      let unseenMessages = [];

      if (currentOpenChat?.chatPartnerId) {
        unseenMessages = messages.filter(
          (message: any) =>
            message.seen === 'false' &&
            String(message.fromId) === String(currentOpenChat?.chatPartnerId)
        );
      }

      if (currentOpenChat?.chatType === 'region') {
        unseenMessages = messages.filter(
          (message: any) =>
            message.seen === 'false' &&
            String(message.fromId) !== String(userId)
        );
      }

      if (unseenMessages.length === 0) return;
      for (const message of unseenMessages) {
        await globalWS.setMessageAsSeen(message.id);
      }
      globalWS.getUnseenCountForRegion();
    };

    if (currentChatMessages) {
      getUnseenMessages(currentChatMessages);
    }
  }, [
    currentChatMessages,
    currentOpenChat,
    currentOpenChat?.chatPartnerId,
    userId,
  ]);

  const groupMessagesByDate = (messages: any[]) => {
    const groupedMessages: Record<string, any[]> = {};

    messages.forEach((message) => {
      const normalizedTimestamp = normalizeTimestamp(message.timestamp);
      const date = new Date(normalizedTimestamp).toLocaleDateString('de-DE');
      if (!groupedMessages[date]) {
        groupedMessages[date] = [];
      }
      groupedMessages[date].push(message);
    });

    return groupedMessages;
  };

  const groupedMessages = useMemo(() => {
    return groupMessagesByDate(
      currentChatMessages.sort(
        (a: any, b: any) =>
          normalizeTimestamp(a.timestamp) - normalizeTimestamp(b.timestamp)
      )
    );
  }, [currentChatMessages]);

  const [regionName, setRegionName] = useState<any>('');
  const { activeRegion } = useRegions();

  useEffect(() => {
    if (!activeRegion) return;
    const region = allRegions.find(
      (region: any) => +region?.id === +activeRegion?.id
    );
    setRegionName(region?.label);
  }, [activeRegion, activeRegion?.id, allRegions]);

  let currentChatPartnerFullName;
  switch (true) {
    case !!currentOpenChat?.name:
      currentChatPartnerFullName = currentOpenChat.name;
      break;
    case currentOpenChat?.chatType === 'region':
      currentChatPartnerFullName = `${t('regions.region')} ${regionName}`;
      break;
    case !!currentOpenChat?.firstName:
      currentChatPartnerFullName =
        currentOpenChat.firstName + ' ' + currentOpenChat.lastName;
      break;
    case !!currentOpenChat?.chatPartnerName:
      currentChatPartnerFullName = `${currentOpenChat.chatPartnerName.firstName} ${currentOpenChat.chatPartnerName.lastName}`;
      break;
    default:
      currentChatPartnerFullName = `${t('chat.group_chat')} ${t('chat.chat')}`;
      break;
  }

  useEffect(() => {
    setPreviousChatMessages(currentChatMessages);
  }, [currentChatMessages]);

  useEffect(() => {
    if (
      scrollRef.current &&
      !loadingMessages &&
      previousChatMessages.length !== currentChatMessages.length
    ) {
      scrollRef.current.scrollIntoView({ behavior: 'auto' });
      setIsBottom(true);
    }
  }, [
    currentChatMessages,
    loadingMessages,
    hasMore,
    previousChatMessages.length,
  ]);

  useEffect(() => {
    if (loadingMessages && containerRef.current) {
      const newMessagesCount =
        currentChatMessages.length - previousChatMessages.length;
      const offset = newMessagesCount * estimatedMessageHeight;

      containerRef.current.scrollTop += offset;
    }
  }, [currentChatMessages, loadingMessages, previousChatMessages.length]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setLoadingMessages(true);
            setCurrentChatPage((prev: number) => prev + 1);
          }
        });
      },
      {
        root: null,
        threshold: 0.1,
      }
    );

    const sentinelElement = loadChatsRef.current;
    if (sentinelElement) {
      observer.observe(sentinelElement);
    }

    return () => {
      if (sentinelElement) {
        observer.unobserve(sentinelElement);
      }
    };
  }, [currentOpenChat, isBottom]);

  useEffect(() => {
    const fetchChatData = async () => {
      if (!currentOpenChat?.id) return;
      if (!hasMore) {
        setLoadingMessages(false);
        return;
      }
      try {
        await globalWS.getCurrentChat(
          token,
          userId,
          currentOpenChat?.id,
          currentChatPage
        );
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchChatData();
  }, [
    currentChatPage,
    currentOpenChat?.id,
    token,
    userId,
    hasMore,
    currentOpenChat,
  ]);

  // Reset on chat change
  useEffect(() => {
    setCurrentChatPage(1);
    setHasMore(true);
    setLoadingMessages(false);
    setCurrentChatMessages([]);
  }, [currentOpenChat]);

  // const userAvatarUrl = avatarsList[currentOpenChat?.chatPartnerId];

  return (
    <div className="lg:w-2/3  h-[calc(100vh-50px)] md:h-full mt-[45px] lg:mt-0  bg-white flex flex-col    w-full overflow-hidden ">
      {/* HEADER OF CHAT */}
      <div className="flex items-center bg-white  lg:justify-start gap-2 p-4 border-b border-secondary-200 lg:relative h-[76px] w-full  z-20">
        <div className="lg:hidden" onClick={onTogglePanelVisibility}>
          <Icon name="chevronBack" />
        </div>

        {currentOpenChat && (
          <div className="flex items-center gap-2 ">
            {currentOpenChat.chatType !== 'region' && (
              <ChatUserIcon
                id={currentOpenChat.id}
                userName={currentChatPartnerFullName}
                // userAvatar={userAvatarUrl}
              />
            )}
            <p className="font-bold">{currentChatPartnerFullName}</p>
          </div>
        )}
        {!currentOpenChat && (
          <ChatSearchBar
            onSelectCurrentChat={onSelectCurrentChat}
            conversations={conversations}
          />
        )}
      </div>
      {/* CONVERSATION LIST / CHAT MESSAGE LIST / INPUT FIELD */}
      {currentOpenChat && (
        <div
          className={`flex-grow       lg:relative lg:top-0 p-4 space-y-4 overflow-y-auto scrollbar  h-[calc(100vh-278px)]  w-full   `}
          ref={containerRef}
        >
          {isBottom && (
            <div className=" h-[1px] bg-transparent" ref={loadChatsRef}></div>
          )}
          {Object.keys(groupedMessages).length > 0 ? (
            Object.keys(groupedMessages).map((dateKey) => (
              <div key={dateKey}>
                <ChatTimeBorder date={dateKey} />
                {groupedMessages[dateKey].map(
                  (messageObj: any, index: number) => {
                    return (
                      <div key={index}>
                        <ChatMessage
                          // userAvatar={userAvatarUrl}
                          allUsers={allUsers}
                          userId={userId}
                          senderId={
                            messageObj?.fromId || messageObj?.from[0]?.id
                          }
                          text={messageObj.message}
                          timestamp={messageObj.timestamp}
                        />
                      </div>
                    );
                  }
                )}
              </div>
            ))
          ) : (
            <ChatInfo />
          )}

          {/* Scroll-Anchor */}
          <div ref={scrollRef} className=" bg-transparent h-1"></div>
        </div>
      )}
      <div className=" lg:relative bottom-0 bg-white w-full">
        {currentOpenChat && (
          <ChatMessageInput
            currentOpenChat={currentOpenChat}
            handleMoveChatToTop={handleMoveChatToTop}
          />
        )}
      </div>
      {/* START NEW CHAT */}
      {!currentOpenChat && <ChatInfo />}
    </div>
  );
};

export default ChatConversationPanel;
