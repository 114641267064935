import { useTranslation } from 'react-i18next';

import Meta from '../../components/layout/Meta';
import PageLayout from '../../layouts/PageLayout';
import SearchResults from '../../modules/search/UI/index';

import { usePermission } from '../../hooks/usePermission';
import { useRedirect } from '../../hooks/useRedirect';
import { PermissionsEnum } from '../../types';

function SearchResultsPage() {
  const { t } = useTranslation();
  const canSearch = usePermission([PermissionsEnum.Search]);
  useRedirect({ redirect: canSearch === false });

  return (
    <>
      <Meta title={t('search.title_search')} />
      <PageLayout>{canSearch && <SearchResults />}</PageLayout>
    </>
  );
}

export default SearchResultsPage;
