import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import ConfirmDenyDialog from '../../../components/shared/ModalNew/ConfirmDenyDialog';
import DocumentModalContent from './EducationDocumentModalContent';
import NoResultsInfoMessage from '../../../components/shared/InfoMessage/NoResults';

import Pagination from '../../../components/shared/Pagination';
import Table from '../../../components/shared/Table';
import TransitionLoader from '../../../components/shared/TransitionLoader/TransitionLoader';

import { EDUCATION_PATH } from '../../../constants';
import { useModal } from '../../../context/modal-context';
import { PermissionsEnum, TableCellWithIdInterface } from '../../../types';
import { formatDateWithPoints, showToast } from '../../../utils';

import { DocumentProps } from '../services/education.types';
import EducationStore from '../services/education.store';
import { usePermission } from '../../../hooks/usePermission';

const educationStore: EducationStore = new EducationStore();

const getTableHeadings = ({
  t,
  canDeleteDocument,
  canEditDocument,
  canViewFile,
  canViewDocument,
}: {
  t: any;
  canDeleteDocument: boolean | null;
  canEditDocument: boolean | null;
  canViewFile: boolean | null;
  canViewDocument: boolean | null;
}) => {
  const headings = [
    { content: t('documents.document') },
    { content: t('documents.document_description') },
    { content: t('fraud_cases.fraud_entry_date'), className: 'w-[150px]' },
  ];

  if (canViewFile) {
    headings.unshift({
      content: '',
      className: 'w-[50px] min-w-[50px]',
    }); // Add "view file" column first
  }

  if (canEditDocument && canViewDocument) {
    headings.push({ content: '', className: 'w-[50px]' }); // delete
  }

  if (canDeleteDocument) {
    headings.push({ content: '', className: 'w-[50px]' }); // delete
  }

  return headings;
};

type LoadingDocumentState =
  | false
  | {
      id: number | null;
      type: 'delete' | 'open' | null;
    };

const EducationReports = ({
  slug,
}: {
  slug?: string;
}): React.JSX.Element | null => {
  const { t } = useTranslation();
  /* const { slug } = useParams(); */
  const [searchParams, setSearchParams] = useSearchParams();
  const page = Number(searchParams.get('page')) || 1;
  const region = searchParams.get('regionIds[]') || '';
  const organization = searchParams.get('organizationIds[]') || '';

  /* Permissions */
  const canListDocuments = usePermission([PermissionsEnum.DocumentsList]);
  const canDeleteDocument = usePermission([PermissionsEnum.DocumentDelete]);
  const canEditDocument = usePermission([PermissionsEnum.DocumentUpdate]);
  const canViewDocument = usePermission([PermissionsEnum.DocumentData]);
  const canViewFile = usePermission([PermissionsEnum.DocumentFile]);

  const { openModal, closeModal, isOpen } = useModal();

  const [currentType, setCurrentType] = useState<string>(
    slug || 'regular_sessions'
  );
  const [searchValue] = useState(searchParams.get('name') || '');

  const [isLoadingFile, setIsLoadingFile] =
    useState<LoadingDocumentState>(false);

  const { data: documentsQueryData, isFetching } =
    educationStore.useGetDocuments({
      page,
      type: currentType,
      searchTerm: searchValue,
      region,
      organization,
      enabled: canListDocuments,
    });

  const educationReports = documentsQueryData?._embedded?.documents || [];
  const transformedEducationReports = educationReports.map(
    (report: any) =>
      ({
        id: report.id,
        data: [
          ...(canViewFile
            ? [
                {
                  content: '',
                  isLoadingFile: report.id === isLoadingFile,
                  type: 'doc',
                  className: 'cursor-pointer',
                  onClick: (e: React.MouseEvent<HTMLButtonElement>) =>
                    handleOpenFile(e, report.id, report.type),
                },
              ]
            : []),
          {
            content: report.title || '',
            type: 'heading',
          },
          {
            content: report.details || '',
            type: 'truncate',
          },
          {
            content: formatDateWithPoints(report.date_created.date) || '',
          },
          ...(canEditDocument && canViewDocument
            ? [
                {
                  type: 'edit',
                  link: `${EDUCATION_PATH}/${currentType}/${report.id}`,
                },
              ]
            : []),
          ...(canDeleteDocument
            ? [
                {
                  type: 'delete',
                  onClick: (e: React.MouseEvent<HTMLButtonElement>) =>
                    handleDeleteFileModal(e, {
                      id: report.id,
                      type: report.type,
                      title: report.title,
                    }),
                },
              ]
            : []),
        ],
      } as TableCellWithIdInterface)
  );

  const totalPages = documentsQueryData?._page_count || 1;

  useEffect(() => {
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    if (slug) {
      setCurrentType(slug);
    }
  }, [slug]);

  const handleOpenFile = async (
    e: React.MouseEvent<HTMLButtonElement>,
    id: number,
    type: string
  ) => {
    e.stopPropagation();
    if (!canViewDocument) return;

    setIsLoadingFile({ id, type: 'open' });

    try {
      const { success, data } = await educationStore.getDocument(id, type);
      if (!success) {
        throw new Error('No URL found');
      }
      window.open(data.fileUrl, '_blank');
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoadingFile(false);
    }
  };

  const handleDeleteFileModal = async (
    e: React.MouseEvent<HTMLButtonElement>,
    { id, type, title }: DocumentProps
  ) => {
    e.stopPropagation();
    if (!canDeleteDocument) return;
    openModal(
      <ConfirmDenyDialog
        infoMessage={`${t('documents.confirm_delete_document')} ${
          title || ''
        }? ${t('global.irreversible_action')}`}
        onConfirm={async () => {
          setIsLoadingFile({ id, type: 'delete' });
          const { success, message } = await educationStore.deleteDocument({
            id,
            type,
          });

          showToast(message, success);
          setIsLoadingFile(false);
        }}
        onDeny={closeModal}
        closeModal={closeModal}
      />,
      t('documents.delete_document')
    );
  };

  const handlePageChange = (newPage: number) => {
    if (newPage !== page) {
      searchParams.set('page', newPage.toString());
      setSearchParams(searchParams);
    }
  };

  // const handleFilterChange = (selectedFilter: string) => {
  // 	setCurrentType(selectedFilter);
  // 	searchParams.set('page', '1');
  // 	setSearchParams(searchParams);
  // };

  // const selectedOptionIndex = DOCUMENTATION_TYPE_OPTIONS.findIndex(
  // 	(option) => option.id === currentType
  // );

  const handleRowClick = (
    e: React.MouseEvent<HTMLTableRowElement>,
    id: number | string
  ) => {
    e.stopPropagation();
    if (!canViewDocument) return;

    const rowItem = educationReports.find((report: any) => report.id === id);
    openModal(
      <DocumentModalContent document={rowItem} onOpenFile={handleOpenFile} />,
      t('documents.document')
    );
  };

  const loadingMessage = isLoadingFile
    ? isLoadingFile.type === 'open'
      ? t('global.opening')
      : isLoadingFile.type === 'delete'
      ? t('state.deleting')
      : t('state.loading')
    : t('state.loading');

  return (
    <div className="pks-layout-col-md">
      <div className="max-w-xs">
        {/* <DropDownSelect
					label='Izaberi tip dokumentacije'
					options={DOCUMENTATION_TYPE_OPTIONS}
					onChange={handleFilterChange}
					selectedOptionIndex={selectedOptionIndex > -1 ? selectedOptionIndex : undefined}
				/> */}
      </div>
      {(isLoadingFile || (isFetching && !isOpen)) &&
        createPortal(
          isOpen ? (
            <div className="relative z-50">
              <TransitionLoader message={loadingMessage} isModalOpen={isOpen} />
            </div>
          ) : (
            <TransitionLoader message={loadingMessage} />
          ),
          document.getElementById('loader')!
        )}

      {!isFetching && transformedEducationReports.length > 0 && (
        <>
          <Table
            headings={getTableHeadings({
              t,
              canDeleteDocument,
              canEditDocument,
              canViewFile,
              canViewDocument,
            })}
            rows={transformedEducationReports}
            onRowClick={handleRowClick}
            clickEnabled={canViewDocument}
          />
          <Pagination
            currentPage={page}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </>
      )}
      {!isFetching && educationReports.length === 0 && <NoResultsInfoMessage />}
    </div>
  );
};

export default EducationReports;
