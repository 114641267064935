import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Icon from '../../../components/shared/Icon';
import ConfirmDenyDialog from '../../../components/shared/ModalNew/ConfirmDenyDialog';
import ForumMemberPreview from './ForumMemberPreview';

import { useModal } from '../../../context/modal-context';
import { usePermission } from '../../../hooks/usePermission';
import { PermissionsEnum } from '../../../types';
import { showToast } from '../../../utils';

import ForumMembersStore from '../services/forumMembers.store';
import UserStatusToggleSwitch from './UserStatusToggleSwitch';
const forumMembersStore = new ForumMembersStore();

const ForumMembersList = ({
  forumMembers,
  showToggle,
}: {
  forumMembers: any;
  showToggle?: boolean;
}) => {
  const { t } = useTranslation();

  // Permissions
  const canDeleteUser = usePermission([PermissionsEnum.UserDelete]);
  const canEditUser = usePermission([PermissionsEnum.UserEdit]);
  const canViewUserData = usePermission([PermissionsEnum.UserData]);

  const { openModal, closeModal } = useModal();

  const handleOpenModal = (e: any, forumMember: any) => {
    if (e.target.closest('.toggle-switch')) {
      e.stopPropagation();
      return;
    }
    e.stopPropagation();
    if (!canViewUserData) return;
    openModal(
      <ForumMemberPreview forumMember={forumMember} />,
      t('forum_members.title_forum_member_preview')
    );
  };

  const deleteForumMember = async (id: number) => {
    if (!canDeleteUser) return;
    const { success, message } = await forumMembersStore.deleteForumMember(id);
    showToast(message, success);
  };

  const handleOpenDeleteForumMemberModal = (e: any, id: number) => {
    if (!canDeleteUser) return;
    e.stopPropagation();
    openModal(
      <ConfirmDenyDialog
        infoMessage={t('forum_members.prompt_forum_member_delete')}
        onConfirm={() => deleteForumMember(id)}
        onDeny={closeModal}
        closeModal={closeModal}
      />,
      t('forum_members.title_forum_member_delete_preview')
    );
  };

  return (
    <>
      <ul className="flex flex-col gap-3">
        {forumMembers?.map((member: any) => {
          return (
            <li
              onClick={(e: any) => handleOpenModal(e, member)}
              id={member.id}
              key={member.id}
              className={`pks-card-bg  overflow-hidden rounded-lg ${
                canViewUserData
                  ? 'group pks-card-bg-hover hover:cursor-pointer'
                  : ''
              }`}
            >
              <div className="flex gap-4 items-center justify-between ml-[6px] pl-[22px] pr-4 py-3 bg-white">
                <div className="pks-layout-col group">
                  {showToggle && (
                    <div className="-mb-2">
                      <UserStatusToggleSwitch id={Number(member.id)} />
                    </div>
                  )}
                  <p className="text-lg font-bold">{member?.name}</p>
                  {member?.organization?.name && (
                    <p className="text-secondary-400">
                      {member?.organization?.name}
                    </p>
                  )}
                  <p className="text-secondary-400">{member?.email}</p>
                </div>
                <div className="inline-flex items-center flex-col lg:flex-row  gap-4">
                  {canEditUser && canViewUserData && (
                    <Link
                      to={`${member.id}`}
                      className="transform scale-125 p-2"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Icon
                        name="pencil"
                        aria-hidden="true"
                        className="hover:text-primary-300"
                        tabIndex={-1}
                      />
                    </Link>
                  )}
                  {canDeleteUser && (
                    <button
                      aria-label={t('forum_members.delete_member')}
                      onClick={(e) =>
                        handleOpenDeleteForumMemberModal(e, member.id)
                      }
                    >
                      <Icon
                        name="trash"
                        aria-hidden="true"
                        className="text-white"
                        tabIndex={-1}
                      />
                    </button>
                  )}
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default ForumMembersList;
