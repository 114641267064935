import { useEffect } from 'react';

import DropDownSelect from '../../../components/shared/DropdownSelect';
import Icon from '../../../components/shared/Icon';
import NoResultsInfoMessage from '../../../components/shared/InfoMessage/NoSearchResults';

import { DropDownItemInterface, PermissionsEnum } from '../../../types';
import { usePermission } from '../../../hooks/usePermission';
// import StatusIndicator from '../../../components/shared/StatusIndicator';

interface RolesListProps {
  roles: any[] | undefined;
  selected?: number;
  onClick: (id: number) => void;
  onUpdate: (role?: any) => void;
}

const RolesList: React.FC<RolesListProps> = ({
  roles,
  selected,
  onClick,
  onUpdate,
}) => {
  const canEditRole = usePermission([PermissionsEnum.RoleEdit]);

  useEffect(() => {
    window.scrollTo({
      top: 0, // Scroll to the top of the page
      behavior: 'smooth', // Smooth scrolling effect
    });
  }, [selected]);

  const dropDownRoles: DropDownItemInterface[] =
    roles?.map((role) => {
      return {
        id: role.id.toString(),
        label: role.name,
      };
    }) || [];

  const handleSelectedRole = (selectedRoleIndex: number) => {
    onClick(selectedRoleIndex);
  };

  const handleUpdate = () => {
    const selectedRole = roles?.find((role) => role.id === selected);
    onUpdate(selectedRole);
  };

  const selectedDropdownItemIndex = dropDownRoles?.findIndex(
    (role) => role.id === selected?.toString()
  );

  return (
    <>
      {dropDownRoles?.length === 0 ? (
        <div>
          <NoResultsInfoMessage />
        </div>
      ) : (
        dropDownRoles?.length && (
          <div className="flex gap-2 items-end">
            <DropDownSelect
              label="Rola"
              options={dropDownRoles}
              selectedOptionIndex={
                selectedDropdownItemIndex !== -1
                  ? selectedDropdownItemIndex
                  : undefined
              }
              onChange={(selectedRole) =>
                handleSelectedRole(Number(selectedRole))
              }
            />
            {canEditRole && (
              <div
                onClick={handleUpdate}
                className="h-[50px] flex items-center justify-center px-4 bg-white rounded-lg border border-secondary-300 hover:border-primary-300 hover:text-primary-300 hover:cursor-pointer"
              >
                <Icon name="pencil" />
              </div>
            )}
          </div>
        )
      )}
    </>
  );
};

export default RolesList;
