import { useState } from 'react';
import { createPortal } from 'react-dom';
import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FileInput, {
  ALLOWED_DOC_TYPES,
} from '../../../components/shared/FileInput';
import TransitionLoader from '../../../components/shared/TransitionLoader/TransitionLoader';
import UploadProgressModal, {
  useUploadProgress,
} from '../../../components/shared/UploadProgressModal';

import {
  extractMultipleErrors,
  formatDateWithPoints,
  hasNestedObjects,
  showToast,
} from '../../../utils';

import { PermissionsEnum } from '../../../types';
import { usePermission } from '../../../hooks/usePermission';

import FraudCaseStore from '../services/fraudCases.store';
import FraudCaseSingleFilesList from './FraudCaseSingleFilesList';
const fraudCaseStore: FraudCaseStore = new FraudCaseStore();

const FraudCaseSingleDetails = ({
  id,
  data: fraudCaseDetailsData,
}: {
  id?: string;
  data: any;
}) => {
  const { t } = useTranslation();
  /* Permissions */
  const canViewFraudCaseTypes = usePermission([
    PermissionsEnum.FraudcaseTypesList,
  ]);
  const canViewFraudCaseCardType = usePermission([
    PermissionsEnum.FraudcaseCardtypesList,
  ]);
  const canViewFraudCaseTerminalType = usePermission([
    PermissionsEnum.FraudcaseTerminalList,
  ]);
  const canListFraudCaseFiles = usePermission([
    PermissionsEnum.FraudcaseFilesList,
  ]);
  const canAddFraudCaseFiles = usePermission([
    PermissionsEnum.FraudcaseFilesAdd,
  ]);

  const shouldShowFilesSection = canListFraudCaseFiles;

  const {
    uploadDetails,
    handleUploadProgress,
    handleUploadResponse,
    resetUploadProgress,
  } = useUploadProgress();

  //const fraudCaseDetailsData: any = useLoaderData();
  const [isLoadingFile, setIsLoadingFile] = useState(false);

  const inputDateStart = formatDateWithPoints(
    fraudCaseDetailsData?.date_created?.date
  );

  const fraudDateStart = formatDateWithPoints(
    fraudCaseDetailsData?.fraud_date_start?.date
  );

  // Use the `useGetFraudCase` hook from the fraud cases store to fetch fraud case type data
  const { data: fraudCaseTypesData } = fraudCaseStore.useGetFraudCasesTypes(
    canViewFraudCaseTypes
  );
  const sourceType = fraudCaseDetailsData?.source_type;
  const type = fraudCaseTypesData?.[fraudCaseDetailsData?.type_id];

  // Use the `useGetFraudCasesTerminalTypes` hook from the fraud cases fetch fraud case terminal type data
  const { data: fraudCaseTerminalTypesData } =
    fraudCaseStore.useGetFraudCasesTerminalTypes(canViewFraudCaseTerminalType);

  const terminalType =
    fraudCaseTerminalTypesData?.[fraudCaseDetailsData?.terminal_type_id];

  const handleOpenFile = async (fileId: number) => {
    try {
      setIsLoadingFile(true);
      const url = await fraudCaseStore.getFraudCaseFile(fileId);
      if (!url) {
        throw new Error('No URL found');
      }
      window.open(url, '_blank');
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoadingFile(false);
    }
  };

  const methods = useForm<FieldValues>({
    defaultValues: {
      documents: [],
    },
  });

  const {
    handleSubmit,
    reset,
    setError,
    formState: { isSubmitting },
  } = methods;

  const onSubmit: SubmitHandler<FieldValues> = async (data, e) => {
    e?.preventDefault();

    const payload = {
      documents: [...data.documents],
    };

    const handleError = (message: any) => {
      if (hasNestedObjects(message)) {
        const errorMessages = extractMultipleErrors(message);

        for (const key in errorMessages) {
          setError(key as any, {
            type: 'backend',
            message: errorMessages[key],
          });
        }
      } else {
        showToast(message, false, true);
      }
    };

    const response = await fraudCaseStore.addFraudCaseFiles({
      id: Number(id),
      data: payload,
      onUpload: handleUploadProgress,
    });

    const { success, message } = response;

    if (success) {
      handleUploadResponse({ success: true });
      reset({}, { keepValues: false });
    } else {
      handleUploadResponse({ success: false, code: response.code });

      if (message === null) return;
      handleError(message);

      if (response.code === 400) {
        reset({}, { keepValues: false, keepErrors: true });
      }
    }
  };

  return (
    <>
      {isLoadingFile &&
        !isSubmitting &&
        createPortal(
          <TransitionLoader message={'Otvaranje...'} />,
          document.getElementById('loader')!
        )}
      {uploadDetails.percentage && (
        <UploadProgressModal
          percentage={uploadDetails.percentage}
          error={uploadDetails.error}
          files={uploadDetails.files}
          onReset={resetUploadProgress}
          onSubmit={handleSubmit(onSubmit)}
        />
      )}
      <div>
        <ul className="flex flex-col gap-1">
          <li>
            <span>
              {sourceType === 1
                ? t('fraud_cases.issuer')
                : sourceType === 2
                ? t('fraud_cases.acquirer')
                : ''}
            </span>
          </li>
          {canViewFraudCaseTypes && (
            <li>
              <span className="font-bold">
                {t('fraud_cases.fraud_case_type')}:
              </span>{' '}
              {type}
            </li>
          )}
          {canViewFraudCaseCardType &&
            fraudCaseDetailsData?.card_type_names &&
            Object.keys(fraudCaseDetailsData.card_type_names).length > 0 && (
              <li>
                <span className="font-bold">{t('fraud_cases.card_type')}:</span>{' '}
                {Object.values(fraudCaseDetailsData.card_type_names).join(', ')}
              </li>
            )}
          {canViewFraudCaseTerminalType && (
            <li>
              <span className="font-bold">
                {t('fraud_cases.terminal_type')}:
              </span>{' '}
              {terminalType}
            </li>
          )}
        </ul>

        <div className="pks-divider-dashed"></div>

        <ul className="flex flex-col gap-1">
          <li>
            <span className="font-bold">BIN:</span>{' '}
            {fraudCaseDetailsData?.card_number
              ? '*'.repeat(fraudCaseDetailsData.card_number.length)
              : ''}
          </li>
          <li>
            <span className="font-bold">
              {`${t('inputs.acquirer.label')} ${t('global.id')}`}:
            </span>{' '}
            {fraudCaseDetailsData?.acquirer_id}
          </li>
          <li>
            <span className="font-bold">{t('inputs.mid.label')}:</span>{' '}
            {fraudCaseDetailsData?.mid}
          </li>
          <li>
            <span className="font-bold">{t('inputs.tid.label')}:</span>
            {fraudCaseDetailsData?.tid}
          </li>
        </ul>

        <div className="pks-divider-dashed"></div>

        <ul className="flex flex-col gap-1">
          <li>
            <span className="font-bold">
              {t('fraud_cases.fraud_entry_date')}:
            </span>{' '}
            {inputDateStart}
          </li>
          <li>
            <span className="font-bold">{t('fraud_cases.fraud_date')}:</span>{' '}
            {fraudDateStart}
          </li>
        </ul>

        <div className="pks-divider-dashed"></div>

        <ul className="flex flex-col gap-1">
          <li>
            <span className="font-bold">{t('regions.region')}:</span>{' '}
            {fraudCaseDetailsData?.regions[0]?.name}
          </li>
        </ul>

        <div className="pks-divider-dashed"></div>

        {shouldShowFilesSection && (
          <>
            <div className="flex flex-col gap-1">
              <p className="font-bold">{t('files.uploaded_files')}:</p>

              <div className="pks-layout-col">
                {canAddFraudCaseFiles && (
                  <>
                    <FormProvider {...methods}>
                      <form onChange={handleSubmit(onSubmit)}>
                        <FileInput
                          showFiles={false}
                          id="documents"
                          name="documents"
                          label={t('documents.add_documents')}
                          maxSizeMB={100}
                          multiple
                          allowedTypes={ALLOWED_DOC_TYPES}
                        />
                      </form>
                    </FormProvider>
                    <p className="text-sm">
                      {t('documents.document_size_limit')}
                    </p>
                  </>
                )}
                {canListFraudCaseFiles && (
                  <FraudCaseSingleFilesList
                    id={id}
                    onOpenFile={handleOpenFile}
                  />
                )}
              </div>
            </div>

            <div className="pks-divider-dashed"></div>
          </>
        )}

        <div>
          <p className="font-bold">{t('inputs.note_description.label')}:</p>
          <div>{fraudCaseDetailsData?.description}</div>
        </div>
      </div>
    </>
  );
};

export default FraudCaseSingleDetails;
