import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../config/i18n.config';
import { LANG_KEY } from '../constants';
import { DropDownItemInterface, LanguageTypes } from '../types';
import serbianFlag from '../assets/images/flags/serbian-flag.svg';
import englandFlag from '../assets/images/flags/england-flag.svg';

/**
 * Interface for the Language Context's shape.
 * @property {string} currentLang - The currently selected language.
 * @property {(lang: LanguageTypes) => void} changeLanguage - Function to change the current language.
 */
interface LanguageContextProps {
  currentLang: LanguageTypes;
  changeLanguage: (lang: LanguageTypes) => void;
  langOptions: DropDownItemInterface[];
}

interface LanguageProviderProps {
  children: ReactNode;
}

/**
 * Context for managing the application's language settings.
 * Provides access to the current language and a method to change it.
 */
const LanguageContext = createContext<LanguageContextProps | undefined>(
  undefined
);

/**
 * LanguageProvider component.
 * Wraps children components and provides language state and methods via context.
 * This component manages the current language, syncs it with `i18next`,
 * and persists it in `localStorage`.
 *
 * @param {LanguageProviderProps} props - The props for the provider, including children.
 * @returns {JSX.Element} The provider component.
 */
export const LanguageProvider = ({
  children,
}: LanguageProviderProps): JSX.Element => {
  const { t } = useTranslation();

  const [langOptions, setLangOptions] = useState([
    { id: LanguageTypes.EN, label: t('lang.english'), image: englandFlag },
    { id: LanguageTypes.SR, label: t('lang.serbian'), image: serbianFlag },
    {
      id: LanguageTypes.SR_CYR,
      label: t('lang.serbian_cyr'),
      image: serbianFlag,
    },
  ]);
  /**
   * State to store the current language.
   * Initialized from localStorage if available, defaults to 'sr' otherwise.
   */
  const [currentLang, setCurrentLang] = useState<LanguageTypes>(() => {
    const storedLang = localStorage.getItem(LANG_KEY);

    // Check if storedLang exists and is a valid value in LanguageTypes
    if (
      !storedLang ||
      !Object.values(LanguageTypes).includes(storedLang as LanguageTypes)
    ) {
      localStorage.setItem(LANG_KEY, LanguageTypes.SR_CYR); // Set default language to 'sr_cyr' if no valid language is stored
      return LanguageTypes.SR_CYR; // Return enum value, not a string
    }

    return storedLang as LanguageTypes; // Cast storedLang to LanguageTypes enum
  });

  /**
   * Updates the current language state and persists it to localStorage.
   * @param {string} lang - The language to switch to.
   */
  const changeLanguage = (lang: LanguageTypes) => {
    setCurrentLang(lang);
    localStorage.setItem(LANG_KEY, lang); // Persist to localStorage
  };

  /**
   * Effect to sync the selected language with i18next.
   * This ensures the i18n library uses the same language as the context.
   */
  useEffect(() => {
    i18n.changeLanguage(currentLang); // Sync with i18next

    setLangOptions([
      { id: LanguageTypes.EN, label: t('lang.english'), image: englandFlag },
      { id: LanguageTypes.SR, label: t('lang.serbian'), image: serbianFlag },
      {
        id: LanguageTypes.SR_CYR,
        label: t('lang.serbian_cyr'),
        image: serbianFlag,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLang]);

  return (
    <LanguageContext.Provider
      value={{ currentLang, langOptions, changeLanguage }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

/**
 * Custom hook to use the Language Context.
 * Provides access to the current language and a method to change it.
 * Must be used within a LanguageProvider.
 *
 * @returns {LanguageContextProps} The context value containing current language and changeLanguage function.
 * @throws Will throw an error if used outside of a LanguageProvider.
 */
export const useLanguage = (): LanguageContextProps => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};
