import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useForm,
  FormProvider,
  SubmitHandler,
  FieldValues,
} from 'react-hook-form';

import Input from '../../../components/shared/Input';
import Button from '../../../components/shared/Button';
import Pagination from '../../../components/shared/Pagination';
import Comments from '../../../components/shared/Comments';

import { formatDateWithPoints, showToast } from '../../../utils';

import FraudCaseStore from '../services/fraudCases.store';
import useScrollToTop from '../../../hooks/useScrollTop';
import { PermissionsEnum } from '../../../types';
import { usePermission } from '../../../hooks/usePermission';
import { useValidationRules } from '../../../hooks/useValidation';

const fraudCaseStore: FraudCaseStore = new FraudCaseStore();

const FraudCaseComments = ({ id }: { id?: string }) => {
  const { t } = useTranslation();
  const { REQUIRED_VALIDATION_RULE } = useValidationRules();
  const [page, setPage] = useState(1);
  const [comments, setComments] = useState<any[]>([]);

  /* Permissions */
  const canViewCommentsData = usePermission([
    PermissionsEnum.FraudcaseCommentsList,
  ]);
  const canAddComment = usePermission([PermissionsEnum.FraudcaseCommentAdd]);

  const {
    data: fraudCaseCommentsQuery,
    error,
    isLoading,
    isRefetching,
  } = fraudCaseStore.useGetFraudCaseComments({
    id,
    page,
    enabled: canViewCommentsData,
  });

  const currentPage = fraudCaseCommentsQuery?._page || 1;
  const totalPages = fraudCaseCommentsQuery?._page_count || 1;

  useEffect(() => {
    if (!canViewCommentsData) return;

    if (fraudCaseCommentsQuery) {
      const transformedComments = fraudCaseCommentsQuery?._embedded?.comments
        .map((comment: any) => {
          return {
            id: comment.id,
            description: comment.description,
            date: formatDateWithPoints(
              comment.date_edited?.date || comment.date_created?.date
            ),
            createdBy: comment.created_by
              ? `${comment.created_by.firstName} ${comment.created_by.lastName} `
              : t('auth.unknown_user'),
            createdById: comment.created_by?.id,
            organization: comment.created_by?.organization?.name,
          };
        })
        .sort((a: any, b: any) => {
          const dateA = new Date(a.date).getTime();
          const dateB = new Date(b.date).getTime();
          return dateB - dateA; // Sort in descending order
        });

      setComments(transformedComments);
    }
  }, [canViewCommentsData, fraudCaseCommentsQuery, t]);

  const methods = useForm({
    defaultValues: {
      description: '',
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = methods;

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    if (!canAddComment) return;
    const { success, message } = await fraudCaseStore.addComment(
      Number(id),
      data
    );
    if (success) {
      reset();
      setPage(1); // Optionally, reset the page to 1 after adding a new comment
    }

    showToast(message, success);
  };

  const handlePageChange = (newPage: number) => {
    if (!canViewCommentsData) return;
    setPage(newPage);
  };

  const handleCommentDelete = async (id: string) => {
    const { success, message } = await fraudCaseStore.deleteComment(id);
    showToast(message, success);
  };

  useScrollToTop([page]);

  return (
    <div className="pks-layout-col-xl">
      {canAddComment && (
        <FormProvider {...methods}>
          <form className="pks-layout-col-md" onSubmit={handleSubmit(onSubmit)}>
            <Input
              id="description"
              name="description"
              label={t('comments.post_comment_question')}
              placeholder={t('comments.enter_comment_question')}
              autoComplete="off"
              asTextarea
              validationRules={REQUIRED_VALIDATION_RULE}
            />
            <div className="text-right">
              <Button
                type="submit"
                disabled={isSubmitting}
                className="min-w-[200px]"
              >
                {isSubmitting
                  ? t('state.submitting')
                  : t('comments.post_comment')}
              </Button>
            </div>
          </form>
        </FormProvider>
      )}
      {canViewCommentsData && (
        <>
          {!error && !isLoading && (
            <Comments
              comments={comments}
              isLoading={isRefetching}
              onDelete={handleCommentDelete}
            />
          )}
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </>
      )}
    </div>
  );
};

export default FraudCaseComments;
