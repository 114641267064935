import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Meta from '../../components/layout/Meta';
import PageLayout from '../../layouts/PageLayout';
import PageTitleWithActions from '../../layouts/PageLayout/TitleWithActions';
import Button from '../../components/shared/Button';
import ForumMembers from '../../modules/forumMembers/UI';

import { FORUM_MEMBER_ADD_PATH } from '../../constants';
import { usePermission } from '../../hooks/usePermission';
import { useRedirect } from '../../hooks/useRedirect';
import { PermissionsEnum } from '../../types';

function ForumMembersPage() {
  const { t } = useTranslation();

  const pageTitle = t('forum_members.title_forum_members');
  const canListUsers = usePermission([PermissionsEnum.UsersList]);
  const canAddMember = usePermission([PermissionsEnum.UserCreate]);
  const shouldRedirect = canListUsers === false && canAddMember === false;

  useRedirect({ redirect: shouldRedirect });

  return (
    <>
      <Meta title={pageTitle} />
      <PageLayout>
        <PageTitleWithActions title={pageTitle}>
          {canAddMember ? (
            <Link
              to={FORUM_MEMBER_ADD_PATH}
              className="focus:outline-none group"
            >
              <Button tabIndex={-1}>
                {t('forum_members.link_forum_member_add')}
              </Button>
            </Link>
          ) : null}
        </PageTitleWithActions>

        {canListUsers && <ForumMembers />}
      </PageLayout>
    </>
  );
}

export default ForumMembersPage;
