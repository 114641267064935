import { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import SidebarLayoutWrapper from './Wrapper';
import NewsSidebar from './NewsSidebar';
import PreviewSidebar from './PreviewSidebar';
import PreviewWithStatisticsSidebar from './PreviewWithStatisticsSidebar';

import {
  NEWS_SIDEBAR_PATHS,
  PREVIEW_NEWS_STATISTICS_SIDEBAR_PATHS,
  PREVIEW_SIDEBAR_PATHS,
  PREVIEW_WITH_STATISTICS_SIDEBAR_PATHS,
} from '../../constants';
import { isPathMatching, getNormalizedPathname } from '../../utils';
import { SidebarEnum, SidebarType } from '../../types';
import PreviewNewsStatisticsSidebar from './PreviewNewsStatisticsSidebar';

const SidebarLayout = () => {
  const { pathname } = useLocation();
  const [sidebarType, setSidebarType] = useState<SidebarType>(undefined);

  useEffect(() => {
    const normalizedPathname = getNormalizedPathname(pathname);
    let newSidebarType: SidebarType = undefined;

    if (isPathMatching(normalizedPathname, NEWS_SIDEBAR_PATHS)) {
      newSidebarType = SidebarEnum.News;
    } else if (
      isPathMatching(normalizedPathname, PREVIEW_WITH_STATISTICS_SIDEBAR_PATHS)
    ) {
      newSidebarType = SidebarEnum.PreviewWithStatistics;
    } else if (isPathMatching(normalizedPathname, PREVIEW_SIDEBAR_PATHS)) {
      newSidebarType = SidebarEnum.Preview;
    } else if (
      isPathMatching(normalizedPathname, PREVIEW_NEWS_STATISTICS_SIDEBAR_PATHS)
    ) {
      // Neu
      newSidebarType = SidebarEnum.PreviewNewsStatistics; // Neu
    }

    // Only update state if the sidebarType changes
    if (newSidebarType !== sidebarType) {
      setSidebarType(newSidebarType);
    }
  }, [pathname, sidebarType]);

  // Memoize the sidebar content rendering
  const renderSidebarContent = useMemo(() => {
    switch (sidebarType) {
      case SidebarEnum.News:
        return <NewsSidebar />;
      case SidebarEnum.Preview:
        return <PreviewSidebar />;
      case SidebarEnum.PreviewWithStatistics:
        return <PreviewWithStatisticsSidebar />;
      case SidebarEnum.PreviewNewsStatistics:
        return <PreviewNewsStatisticsSidebar />;
      default:
        return null;
    }
  }, [sidebarType]);

  if (!sidebarType) return null;

  return <SidebarLayoutWrapper>{renderSidebarContent}</SidebarLayoutWrapper>;
};

export default SidebarLayout;
