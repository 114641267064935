import { usePermission } from '../../hooks/usePermission';
import FreudCasesPreview from '../../modules/fraudCases/UI/FraudCasesPreview';
import NewsPreview from '../../modules/news/UI/Preview';
import { PermissionsEnum } from '../../types';

const PreviewSidebar = () => {
  const canListNews = usePermission([PermissionsEnum.NewsList]);
  const canListFraudcases = usePermission([PermissionsEnum.FraudcasesList]);

  return (
    <div className="pks-layout-col-xl">
      {canListNews && <NewsPreview count={5} />}
      {canListFraudcases && <FreudCasesPreview count={4} />}
    </div>
  );
};

export default PreviewSidebar;
