import { Fragment, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useNavigate,
  Form,
  useLoaderData,
  LoaderFunctionArgs,
} from 'react-router-dom';
import {
  useForm,
  FormProvider,
  SubmitHandler,
  FieldValues,
  useFieldArray,
  useWatch,
} from 'react-hook-form';

import AvatarInput from '../../../components/shared/AvatarInput';
import Button from '../../../components/shared/Button';
import CheckboxInput from '../../../components/shared/CheckboxInput';
import DropDownSelect from '../../../components/shared/DropdownSelect/DropDownSelectWithIndicators';
import FakeInput from '../../../components/shared/Input/FakeInput';
import LanguageDropdown from '../../../components/shared/DropdownSelect/LanguageDropdown';
import Icon from '../../../components/shared/Icon';
import Input from '../../../components/shared/Input';

import { FORUM_MEMBERS_PATH, RESET_PASSWORD_PATH } from '../../../constants';
import { useAuth } from '../../../context/auth-context';
import { usePaginatedDropdown } from '../../../hooks/usePaginatedDropdown';
import { ActionResponse, PermissionsEnum } from '../../../types';
import {
  extractMultipleErrors,
  hasNestedObjects,
  hasPermission,
  showToast,
} from '../../../utils';

import ForumMembersStore from '../services/forumMembers.store';
import OrganizationsStore from '../../organizations/services/organizations.store';
import RolesStore from '../../roles/services/roles.store';
import { useRegions } from '../../../context/regions-context';
import { NavLink } from 'react-router-dom';
import { usePermission } from '../../../hooks/usePermission';
import { useValidationRules } from '../../../hooks/useValidation';

const forumMembersStore: ForumMembersStore = new ForumMembersStore();
const organizationsStore: OrganizationsStore = new OrganizationsStore();
const rolesStore: RolesStore = new RolesStore();

const ForumMemberForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userId, setAvatarUrl } = useAuth();
  const { availableRegionsDropdownItems, availableRegionsData } = useRegions();
  const {
    EMAIL_VALIDATION_RULES,
    regionRoleValidationRules,
    REQUIRED_VALIDATION_RULE,
  } = useValidationRules();
  /* Permissions */
  const canCreateUser = usePermission([PermissionsEnum.UserCreate]);
  const canEditUser = usePermission([PermissionsEnum.UserEdit]);
  const canDeleteUser = usePermission([PermissionsEnum.UserDelete]);
  const canListOrganizations = usePermission([
    PermissionsEnum.OrganizationsList,
  ]);
  const canListRoles = usePermission([PermissionsEnum.RolesList]);
  const canGetUserRoles = hasPermission([PermissionsEnum.UserRolesList]);
  const canSetRoles = usePermission([PermissionsEnum.UserRoles]);
  const canSetRegions = usePermission([PermissionsEnum.UserRegions]);
  const canSetAvatar = usePermission([PermissionsEnum.UserAvatarSet]);

  const allRegions = availableRegionsData?.allRegions;

  const loaderData = useLoaderData() as ActionResponse<any> | null;

  const canEditFields =
    (loaderData && canEditUser) || (!loaderData && canCreateUser);

  const editFormData = loaderData?.success ? loaderData.data : undefined;

  const isEditForm = editFormData?.id ? true : false;

  const canEditAvatar = isEditForm && !canEditFields && canSetAvatar;

  const methods = useForm<any>({
    defaultValues: {
      email: editFormData?.email || '',
      organizationId: editFormData?.organization.id || '',
      firstName: editFormData?.first_name || '',
      lastName: editFormData?.last_name || '',
      phone: editFormData?.phone || '',
      mobilePhone: editFormData?.mobile_phone || '',
      organizationFunction: editFormData?.organization_function || '',
      avatar: '',
      allRegions: editFormData?.regions_list?.allRegions || 0,
      regionRoles:
        allRegions && canEditFields && editFormData?.regions_list?.allRegions
          ? availableRegionsDropdownItems.map((region) => {
              const matchedRegion = editFormData?.region_roles_list?.find(
                (roleRegion: { regionId: string | number }) =>
                  roleRegion.regionId === region.id
              );

              return {
                regionId: region.id,
                roleIds: matchedRegion
                  ? matchedRegion.roles?.map((role: { id: any }) => role.id) ||
                    []
                  : [],
              };
            })
          : (editFormData?.region_roles_list?.length > 0 &&
              editFormData?.region_roles_list?.map(
                (region: { regionId: any; roles: any[] }) => ({
                  regionId: region.regionId || '',
                  roleIds: region.roles?.map((role) => role.id) || [],
                })
              )) || [{ regionId: '', roleIds: [] }],
    },
  });

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    watch,
    getValues,
    formState: { isSubmitting, errors },
  } = methods;

  const areAllRegionsSelected = watch('allRegions');
  const previousRegionRolesRef = useRef(getValues('regionRoles')); // Store initial state

  useEffect(() => {
    if (!canEditFields) {
      // Restore the previous state
      setValue('regionRoles', previousRegionRolesRef.current);
      return;
    }

    // Generate default region roles based on the selected state
    const defaultRegionRoles = availableRegionsDropdownItems
      .filter((region) => {
        // Filter regions based on `areAllRegionsSelected`
        if (!areAllRegionsSelected) {
          // Only include regions with IDs that are present in the existing selection
          const selectedRegionIds = previousRegionRolesRef.current?.map(
            (role: { regionId: any }) => role.regionId
          );
          return selectedRegionIds?.includes(region.id);
        }
        return true; // Include all regions if `areAllRegionsSelected` is true
      })
      .map((region) => {
        // Check if the region exists in the `editFormData` with matched roles
        const matchedRegion = editFormData?.region_roles_list?.find(
          (roleRegion: { regionId: string | number }) =>
            roleRegion.regionId === region.id
        );

        return {
          regionId: region.id,
          roleIds: matchedRegion
            ? matchedRegion.roles?.map((role: { id: any }) => role.id) || []
            : [], // Use matched roles or an empty array if no match
        };
      });

    // Determine if an empty region-role pair should be added
    const shouldAddEmptyPair = defaultRegionRoles.length === 0;

    // Finalize the region roles list
    const finalRegionRoles = shouldAddEmptyPair
      ? [{ regionId: '', roleIds: [] }]
      : defaultRegionRoles;

    // Update the form values for `regionRoles`
    setValue('regionRoles', finalRegionRoles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableRegionsDropdownItems, areAllRegionsSelected]);

  // Use Field Arrays for regions and roles
  const {
    fields: regionRolesFields, // Array of region-role fields
    append: appendRegion, // Function to append a new region-role pair
    remove: removeRegion, // Function to remove a region-role pair
  } = useFieldArray({
    control, // Control from useForm
    name: 'regionRoles', // The name of the array in the form data
  });

  const hasMoreAvailableRegion = availableRegionsData?.regions?.length
    ? regionRolesFields?.length < availableRegionsData?.regions?.length
    : false;

  /* Roles */
  const useRolesDropdown = (editFormData: any) => {
    return usePaginatedDropdown({
      fetchData: (page) =>
        rolesStore.useGetRoles({
          page,
          enabled: canListRoles && canGetUserRoles ? true : false,
        }),
      extractItems: (data) => {
        return (data?.roles || []).map((role: any) => ({
          id: role.id,
          label: role.name,
          selected: editFormData?.roles.some(
            (selectedRole: any) => selectedRole.id === role.id
          ),
        }));
      },
      dependencies: [editFormData?.roles],
    });
  };

  const { finalItems: finalRoles, isLoadingRef: isLoadingRolesRef } =
    useRolesDropdown(editFormData);

  /* Organizations */
  const useOrganizationsDropdown = () => {
    return usePaginatedDropdown({
      fetchData: (page) =>
        organizationsStore.useGetOrganizations({
          page,
          searchTerm: '',
          enabled: canListOrganizations,
        }),
      extractItems: (data) => {
        return (data?.organizations || []).map((org: any) => ({
          id: org.id,
          label: org.name,
        }));
      },
    });
  };

  const {
    finalItems: finalOrganizations,
    isLoadingRef: isLoadingOrganizationsRef,
  } = useOrganizationsDropdown();

  /* Handle Submit */
  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    if (!canEditFields && !canEditAvatar) return;

    const handleError = (message: any) => {
      if (hasNestedObjects(message)) {
        const errorMessages = extractMultipleErrors(message);
        for (const key in errorMessages) {
          setError(key as any, {
            type: 'backend',
            message: errorMessages[key],
          });
        }
      } else {
        showToast(message, false, true);
      }
    };

    let formData = new FormData();

    if (canEditFields) {
      Object.keys(data).forEach((key) => {
        if (!['regionRoles', 'allRegions'].includes(key)) {
          formData.append(key, data[key]);
        }
      });

      // Append the allRegions field as 1 or 0
      formData.append('allRegions', areAllRegionsSelected ? '1' : '0');

      // Handle regions that are specifically selected by the user
      data.regionRoles.forEach((regionRole: any) => {
        // Check that the regionId is valid and has roleIds
        if (regionRole.regionId && regionRole.roleIds.length > 0) {
          formData.append(
            'regionRoles[]',
            JSON.stringify({
              regionId: regionRole.regionId,
              roleIds: regionRole.roleIds.map((roleId: any) =>
                typeof roleId === 'number' ? roleId : roleId.id
              ),
            })
          );
        }
      });

      const response = editFormData?.id
        ? await forumMembersStore.updateForumMember(editFormData.id, formData)
        : await forumMembersStore.addForumMember(formData);

      const { success, message } = response;

      if (success) {
        showToast(message, success);
        if (userId === editFormData?.id && data.avatar) {
          setAvatarUrl(URL.createObjectURL(data.avatar));
        }
        navigate(FORUM_MEMBERS_PATH);
      } else {
        // TODO check again because its hardcoded...
        if (message === 'Account with email already exists.') {
          setError('email', {
            type: 'manual',
            message: t('forum_members.member_with_email_exists'),
          });
        }
        handleError(message);
      }
    } else {
      formData.append('avatar', data['avatar']);
      const response =
        editFormData?.id &&
        (await forumMembersStore.setForumMemberAvatar(
          editFormData.id,
          formData
        ));
      const { success, message } = response;
      if (success) {
        window.scrollTo(0, 0);
        showToast(message, success);
        setAvatarUrl(URL.createObjectURL(data.avatar));
      } else {
        handleError(message);
      }
    }
  };

  const regionRoles = useWatch({ control, name: 'regionRoles' });
  // Check if regionId is selected for the specific index
  const isRegionSelected = (index: number) => {
    return regionRoles?.[index]?.regionId !== '';
  };

  const shouldShowApplySameRolesButton =
    areAllRegionsSelected &&
    canSetRoles &&
    regionRoles?.[0]?.roleIds?.length > 0
      ? regionRoles
          .slice(1)
          .every(({ roleIds }: { roleIds: number[] }) => roleIds.length === 0) // Check all regions except the first one
      : false;

  const applySameRolesToAllRegions = () => {
    // Get the roles from the first region
    const rolesToApply = regionRoles?.[0]?.roleIds;

    // Apply the roles to the other regions
    regionRoles.forEach((region: { roleIds: any[] }, index: number) => {
      if (index !== 0 && rolesToApply.length > 0) {
        region.roleIds = [...rolesToApply]; // Apply the same roles
      }
    });

    // You can now perform other actions after updating regionRoles, such as re-rendering UI
    setValue('regionRoles', regionRoles);
  };

  /* Handle Delete */
  const handleDelete = async () => {
    if (!canDeleteUser) return;
    const { success, message } = await forumMembersStore.deleteForumMember(
      Number(editFormData.id)
    );

    if (success) {
      showToast(message, success);
      navigate(FORUM_MEMBERS_PATH);
    } else {
      showToast(message, success);
    }
  };

  /* Member Form */
  return (
    <FormProvider {...methods}>
      <Form
        method="post"
        className="pks-layout-col-xl"
        onSubmit={handleSubmit(onSubmit)}
      >
        {/* Avatar Upload */}
        <AvatarInput
          userId={editFormData?.id}
          userName={`${editFormData?.first_name || ''} ${
            editFormData?.last_name || ''
          }`}
          userFunction={editFormData?.organization_function}
          hasAvatar={editFormData?.avatar ? true : false}
          disabled={!canEditFields && !canEditAvatar ? true : false}
        />

        {/* Profile Page - Reset Password Link */}
        {userId === editFormData?.id && (
          <NavLink to={RESET_PASSWORD_PATH} className="underline">
            {t('auth.change_password_link')}
          </NavLink>
        )}

        <div className="flex flex-col gap-8 md:flex-row">
          {/* User Details */}
          <div className="pks-layout-col flex-1">
            {/* First and Last Name Name Input Fields */}
            <div className="flex flex-col gap-2 sm:flex-row">
              <div className="flex-1">
                <Input
                  type="text"
                  id="firstName"
                  name="firstName"
                  label={`${t('inputs.name.label')}*`}
                  placeholder={t('inputs.name.placeholder')}
                  autoComplete="off"
                  validationRules={REQUIRED_VALIDATION_RULE}
                  disabled={!canEditFields}
                  hasLock={!canEditFields}
                />
              </div>

              {/* Last Name Input Field */}
              <div className="flex-1">
                <Input
                  type="text"
                  id="lastName"
                  name="lastName"
                  label={`${t('inputs.surname.label')}*`}
                  placeholder={t('inputs.surname.placeholder')}
                  autoComplete="off"
                  validationRules={REQUIRED_VALIDATION_RULE}
                  disabled={!canEditFields}
                  hasLock={!canEditFields}
                />
              </div>
            </div>

            {/* Email Input Field */}
            <Input
              type="text"
              id="email"
              name="email"
              label={`${t('inputs.email.label')}*`}
              placeholder={t('inputs.email.placeholder')}
              autoComplete="off"
              validationRules={EMAIL_VALIDATION_RULES}
              disabled={!canEditFields}
              hasLock={!canEditFields}
            />

            {/* Phone Input Field */}
            <Input
              type="text"
              id="phone"
              name="phone"
              label={t('inputs.phone.label')}
              placeholder={t('inputs.phone.placeholder')}
              autoComplete="off"
              numeric
              disabled={!canEditFields}
              hasLock={!canEditFields}
            />

            {/* Mobile Phone Input Field */}
            <Input
              type="text"
              id="mobilePhone"
              name="mobilePhone"
              label={`${t('inputs.mobile_phone.label')}*`}
              placeholder={t('inputs.mobile_phone.placeholder')}
              autoComplete="off"
              validationRules={REQUIRED_VALIDATION_RULE}
              numeric
              disabled={!canEditFields}
              hasLock={!canEditFields}
            />

            {/* Organizations Dropdown Field */}
            {canListOrganizations && canEditFields ? (
              <DropDownSelect
                id="organizationId"
                name="organizationId"
                label={`${t('institutions.institution')}*`}
                placeholder={t('institutions.choose_institution')}
                options={finalOrganizations}
                isLoading={isLoadingOrganizationsRef.current}
              />
            ) : (
              <FakeInput
                id="organizationName"
                label={`${t('institutions.institution')}*`}
                children={editFormData?.organization.name || ''}
                title={t('institutions.no_permission_institutions')}
              />
            )}

            {/* Organization Function Input Field */}
            <Input
              type="text"
              id="function"
              name="organizationFunction"
              label={`${t('inputs.function.label')}*`}
              placeholder={t('inputs.function.placeholder')}
              autoComplete="off"
              validationRules={REQUIRED_VALIDATION_RULE}
              disabled={!canEditFields}
              hasLock={!canEditFields}
            />

            {/* Lang */}
            {userId === editFormData?.id && <LanguageDropdown />}
          </div>

          {/* Regions and Roles */}

          <div className="relative pks-layout-col flex-1 border-t border-t-secondary-300 pt-4 md:pt-0 md:border-none">
            {canEditFields &&
            (shouldShowApplySameRolesButton ||
              editFormData?.regions_list?.allRegions) ? (
              <div
                className={`w-full flex gap-1 flex-wrap items-center justify-between md:absolute lg:flex-nowrap lg:-mt-10 ${
                  shouldShowApplySameRolesButton ? 'md:-mt-20' : 'md:-mt-10'
                }`}
              >
                {/* All Regions Checkbox Field */}
                {editFormData?.regions_list?.allRegions ? (
                  <CheckboxInput
                    id="allRegions"
                    label={t('regions.all_regions')}
                    name="allRegions"
                    disabled={allRegions === 1 ? false : true}
                    defaultChecked={
                      !editFormData?.regions_list?.allRegions
                        ? false
                        : editFormData?.regions_list?.allRegions
                    }
                  />
                ) : null}

                {shouldShowApplySameRolesButton && (
                  <button
                    type="button"
                    onClick={applySameRolesToAllRegions}
                    className="flex gap-1 items-center"
                  >
                    <Icon name="plusInCircle" />
                    {t('regions.apply_same_roles_to_all_regions')}
                  </button>
                )}
              </div>
            ) : null}

            {/* Regions and Roles Selection */}
            {regionRolesFields.map((item, index) => {
              const regionValidation = regionRoleValidationRules.regionId;
              const roleValidation = regionRoleValidationRules.roleIds;

              // Extract the already selected regions from other region entries
              const selectedRegions = regionRolesFields
                .filter((_, i) => i !== index) // Exclude the current region
                .map((field: any) => field.regionId); // Get the regionId of other regions

              // Filter the available regions to exclude the selected ones
              const filteredRegions = areAllRegionsSelected
                ? availableRegionsDropdownItems
                : availableRegionsDropdownItems.filter(
                    (region) => !selectedRegions.includes(region.id) // Exclude already selected regions
                  );

              return (
                <Fragment key={item.id}>
                  {index !== 0 && (
                    <div className="pks-divider-dashed pks-divider-dashed-narrow"></div>
                  )}
                  <div className="flex flex-col gap-2">
                    {/* Region Dropdown */}
                    <DropDownSelect
                      id={`regionRoles[${index}].regionId`}
                      name={`regionRoles[${index}].regionId`}
                      label={`${
                        index !== 0
                          ? `${t('regions.region')} ${index + 1}`
                          : t('regions.region')
                      }*`}
                      placeholder={t('regions.region')}
                      options={filteredRegions}
                      validationRules={regionValidation}
                      isDisabled={
                        areAllRegionsSelected ||
                        !canEditFields ||
                        !canSetRegions
                      }
                      hasLock={
                        areAllRegionsSelected ||
                        !canSetRegions ||
                        !canEditFields
                          ? true
                          : false
                      }
                      hasError={
                        (errors?.regionRoles as any)?.[index]?.regionId?.message
                      }
                    />
                    {(errors?.regionRoles as any)?.[index]?.regionId
                      ?.message && (
                      <div className="text-danger">
                        {(errors.regionRoles as any)[index].regionId.message}
                      </div>
                    )}

                    {canEditFields && canListRoles && canGetUserRoles ? (
                      <>
                        {/* Roles Dropdown */}
                        <DropDownSelect
                          id={`regionRoles[${index}].roleIds`}
                          name={`regionRoles[${index}].roleIds`}
                          label={`${
                            index !== 0
                              ? `${t('roles.role')} ${index + 1}`
                              : `${t('roles.role')}`
                          }*`}
                          placeholder={t('roles.choose_roles')}
                          options={finalRoles}
                          isLoading={
                            canListRoles === true &&
                            canGetUserRoles &&
                            isLoadingRolesRef.current
                          }
                          validationRules={roleValidation}
                          isDisabled={
                            !isRegionSelected(index) ||
                            !canEditFields ||
                            !canSetRoles
                          } // Disable if regionId is empty
                          hasLock={
                            !canEditFields ? true : false || !canSetRoles
                          }
                          title={
                            !isRegionSelected(index) &&
                            canEditFields &&
                            canSetRoles
                              ? t('roles.region_required_for_role_assignment')
                              : ''
                          }
                          hasError={
                            (errors?.regionRoles as any)?.[index]?.roleIds
                              ?.message
                          }
                          multiple
                        />
                        {(errors?.regionRoles as any)?.[index]?.roleIds
                          ?.message && (
                          <div className="text-danger">
                            {(errors.regionRoles as any)[index].roleIds.message}
                          </div>
                        )}
                      </>
                    ) : (
                      (() => {
                        const regionRolesList = editFormData?.region_roles_list;
                        const currIndex = regionRolesList?.[index]?.regionId;
                        const currRegion = regionRolesList?.find(
                          (region: any) => region.regionId === currIndex
                        );
                        const roles =
                          currRegion?.roles
                            ?.map((role: any) => role.name)
                            .join(', ') || '';

                        const title = canSetRoles
                          ? t('roles.no_permission_roles')
                          : '';

                        return (
                          <FakeInput
                            title={title}
                            label={`${t('roles.role')}*`}
                            id="role-readonly"
                            children={<>{roles}</>}
                          />
                        );
                      })()
                    )}

                    {/* Remove Button for Region-Role Pair */}
                    {regionRolesFields.length > 1 && !areAllRegionsSelected && (
                      <button
                        type="button"
                        className="flex gap-1 items-center justify-end text-danger pr-3"
                        onClick={() => removeRegion(index)}
                        aria-label={`${t('regions.delete_region_roles')} ${
                          index + 1
                        }`}
                      >
                        <Icon name="trash"></Icon>
                        {t('regions.delete_region_roles_pair')}
                      </button>
                    )}
                  </div>
                </Fragment>
              );
            })}

            {regionRoles?.[regionRoles.length - 1]?.roleIds?.length > 0 &&
              hasMoreAvailableRegion &&
              !areAllRegionsSelected && (
                <button
                  className="flex gap-1 items-center"
                  type="button"
                  onClick={() => appendRegion({ regionId: '', roleIds: [] })}
                >
                  <Icon name="plusInCircle" />
                  {t('regions.add_region')}
                </button>
              )}
          </div>
        </div>

        {/* Form Actions */}
        <div className="w-full inline-flex gap-4 flex-wrap sm:flex-nowrap">
          {/* Submit Button */}
          <Button
            type="submit"
            className="flex-grow"
            wide
            isDisabled={isSubmitting || (!canEditFields && !canSetAvatar)}
            disabled={isSubmitting || (!canEditFields && !canSetAvatar)}
          >
            {isSubmitting
              ? t('state.submitting')
              : editFormData
              ? t('forum_members.edit_member')
              : t('forum_members.add_member')}
          </Button>

          {/* Delete Button */}
          {editFormData?.id && canDeleteUser && (
            <Button
              type="button"
              variant="secondary"
              wide
              onClick={handleDelete}
            >
              {t('forum_members.delete_member')}
            </Button>
          )}

          {/* Back Button */}
          <span className="flex-auto sm:flex-none focus:outline-none group">
            <Button
              onClick={() => navigate(FORUM_MEMBERS_PATH)}
              type="button"
              variant="secondary"
              wide
              tabIndex={-1}
            >
              {t('global.cancel')}
            </Button>
          </span>
        </div>
      </Form>
    </FormProvider>
  );
};

export default ForumMemberForm;

export async function loader({
  params,
}: LoaderFunctionArgs): Promise<ActionResponse<any> | null> {
  const slug = Number(params.id);

  if (!hasPermission([PermissionsEnum.UserData])) return null;

  const canGetUserRoles = hasPermission([PermissionsEnum.UserRolesList]);

  if (slug) {
    const data = await forumMembersStore.preloadForumMember(slug);

    const roles = await forumMembersStore.getForumMemberRole({
      id: slug,
      enabled: canGetUserRoles,
    });

    if (data && data.success && roles) {
      data.data.roles = [...roles.data.roles];
    }

    return data;
  }

  return null;
}
