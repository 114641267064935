import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Button from '../../components/shared/Button';
import Meta from '../../components/layout/Meta';
import News from '../../modules/news/UI/index';
import PageLayout from '../../layouts/PageLayout';
import PageTitleWithActions from '../../layouts/PageLayout/TitleWithActions';
import ToggleSwitch from '../../components/shared/ToggleSwitch';

import { PermissionsEnum, ViewEnum } from '../../types';
import { usePermission } from '../../hooks/usePermission';
import { useRedirect } from '../../hooks/useRedirect';

const EducationNewsPage = () => {
  const { t } = useTranslation();
  /* Title */
  const pageTitle = t('news.news_and_useful_information');
  const [showActive, setShowActive] = useState(true);

  /* Permissions */
  const canCreateNews = usePermission([PermissionsEnum.NewsCreate]);
  const canListNews = usePermission([PermissionsEnum.NewsList]);
  const canToggleNewsStatus = usePermission([PermissionsEnum.NewsStatus]);
  const shouldRedirect = canCreateNews === false && canListNews === false;

  useRedirect({
    redirect: shouldRedirect,
  });

  const handleToggleChange = (checked: boolean) => {
    if (!canToggleNewsStatus) return;

    setShowActive(checked);
  };

  if (shouldRedirect) return null;

  return (
    <>
      <Meta title={pageTitle} />
      <PageLayout>
        <PageTitleWithActions title={pageTitle}>
          {canCreateNews && (
            <Link
              to="/education/add-news"
              replace
              className="focus:outline-none group"
            >
              <Button tabIndex={-1}>{t('news.enter_new_news')}</Button>
            </Link>
          )}
        </PageTitleWithActions>

        {canListNews && (
          <>
            {canToggleNewsStatus && (
              <div className="w-fit">
                <ToggleSwitch
                  checked={showActive}
                  id="news-status"
                  label={
                    showActive
                      ? t('news.active_news')
                      : t('news.deactivated_news')
                  }
                  onChange={handleToggleChange}
                />
              </div>
            )}
            <News view={ViewEnum.Grid} showInactive={!showActive} />
          </>
        )}
      </PageLayout>
    </>
  );
};

export default EducationNewsPage;
