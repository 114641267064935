import { useEffect, useState } from 'react';
import useInputContext from '../../../hooks/useInputContext';

interface CheckboxInputProps {
  id: string | number;
  name: string;
  label: string;
  defaultChecked?: boolean;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void; // Added onChange prop
}

/**
 * CheckboxInput component for rendering a styled checkbox input with a label.
 *
 * This component integrates with the `useInputContext` hook, allowing it to
 * register the checkbox input with form handling logic. It supports an optional
 * `defaultChecked` state, enabling it to start as checked or unchecked based
 * on the provided props.
 *
 * @component
 * @param {CheckboxInputProps} props - The props object for this component.
 * @param {string} props.id - The unique identifier for the checkbox input.
 * @param {string} props.name - The name attribute for the checkbox input, used for form submission.
 * @param {string} props.label - The label text displayed alongside the checkbox.
 * @param {boolean} [props.defaultChecked=false] - Determines whether the checkbox is checked by default.
 *
 * @returns {JSX.Element} The rendered checkbox input component.
 */
const CheckboxInput = ({
  id,
  label,
  name,
  disabled = false,
  defaultChecked = false,
  onChange,
}: CheckboxInputProps): JSX.Element => {
  const { register, setValue } = useInputContext(name);
  const stringId = typeof id === 'number' ? id.toString() : id;

  // Local state for immediate UI response
  const [isChecked, setIsChecked] = useState(defaultChecked);

  // Sync initial state from context or props
  useEffect(() => {
    setIsChecked(defaultChecked);
  }, [defaultChecked]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.checked;

    // Immediately toggle local state for instant feedback
    setIsChecked(newValue);

    // Update form context immediately
    setValue(name, newValue);

    // Trigger onChange callback after delay (e.g., after request logic)
    if (onChange) {
      setTimeout(() => {
        onChange(e);
      }, 0);
    }
  };

  return (
    <div className="pks-cbx">
      <input
        type="checkbox"
        id={stringId}
        {...register(name)}
        checked={isChecked}
        className="inp-cbx"
        onChange={handleChange}
        disabled={disabled}
      />
      <label className="cbx" htmlFor={stringId} tabIndex={0}>
        <span>
          <svg width="12px" height="10px">
            <use xlinkHref="#check"></use>
          </svg>
        </span>
        <span>{label}</span>
      </label>
      <svg className="inline-svg">
        <symbol id="check" viewBox="0 0 12 10">
          <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
        </symbol>
      </svg>
    </div>
  );
};

interface FakeCheckboxInputProps {
  label: string;
  checked?: boolean;
}

const FakeCheckbox = ({
  label,
  checked = false,
}: FakeCheckboxInputProps): JSX.Element => {
  return (
    <div className={`pks-cbx pks-cbx-fake ${checked ? 'checked' : ''}`}>
      <div className="cbx">
        <span>
          <svg width="12px" height="10px">
            <use xlinkHref="#check"></use>
          </svg>
        </span>
        <span>{label}</span>
      </div>
      <svg className="inline-svg">
        <symbol id="check" viewBox="0 0 12 10">
          <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
        </symbol>
      </svg>
    </div>
  );
};

export { FakeCheckbox };
export default CheckboxInput;
