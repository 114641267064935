import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useForm,
  useFieldArray,
  FormProvider,
  FieldValues,
} from 'react-hook-form';

import Button from '../../../components/shared/Button';
import DropDownSelectWithSearch from '../../../components/shared/DropdownSelect/DropDownSelectWithSearch';
import Icon from '../../../components/shared/Icon';
import Input from '../../../components/shared/Input';

import { useRegions } from '../../../context/regions-context';
import { DropDownItemInterface } from '../../../types';
import { useValidationRules } from '../../../hooks/useValidation';

export interface UserField {
  id?: number;
  position: string;
  label?: string;
}

export interface ForumCouncilFormProps {
  onSubmit: (data: FieldValues) => void;
  defaultValues: {
    users: UserField[] | null;
    regionId: number;
  } | null;
  users: DropDownItemInterface[]; // Assumes users have `id` and `label` properties
  isLoading: boolean;
}

const ForumCouncilForm: React.FC<ForumCouncilFormProps> = ({
  defaultValues,
  users,
  isLoading = false,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const { activeRegion } = useRegions();
  const methods = useForm({
    defaultValues: {
      users: defaultValues?.users || [{ id: undefined, position: '' }],
      regionId: activeRegion?.id || defaultValues?.regionId,
    },
  });

  const { REQUIRED_VALIDATION_RULE } = useValidationRules();

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    watch,
  } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'users',
  });

  const fieldRefs = useRef<(HTMLDivElement | null)[]>([]);
  const watchedFields = watch('users') as UserField[];

  const shouldAddEntry = () =>
    watchedFields.every(
      (field) => field.id !== null && field.position.trim() !== ''
    );

  const handleAddEntry = () => {
    append({ id: undefined, position: '' }); // Append a new field with empty values
    setTimeout(() => {
      const lastFieldRef = fieldRefs.current[fields.length];
      lastFieldRef?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 0);
  };

  // Merge `users` with the missing users from `defaultValues.users`
  const updatedUsers: DropDownItemInterface[] = [
    ...users,
    ...(defaultValues?.users || [])
      .filter(
        (defaultUser) =>
          !users.some((existingUser) => existingUser.id === defaultUser.id)
      )
      .map((user) => ({
        id: user.id!, // Use non-null assertion operator if you're sure id is not undefined
        label: user.label!, // Same for label
        disabled: true, // Optional field, can be undefined
      })),
  ];

  return (
    <FormProvider {...methods}>
      <form
        method="post"
        className="pks-layout-col-md w-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        {/* Council Members Fields */}
        {fields.map((field, index) => (
          <div
            key={field.id || index}
            ref={(el) => (fieldRefs.current[index] = el)}
            className="pks-layout-col"
          >
            {/* Users Dropdown Select */}
            <DropDownSelectWithSearch
              id={`users.${index}.id`}
              name={`users.${index}.id`}
              label={`${t('forum_members.member')} ${index + 1}`}
              placeholder={t('forum_members.select_member')}
              options={updatedUsers}
              validationRules={REQUIRED_VALIDATION_RULE}
              isLoading={isLoading}
            />

            {/* Position Input Field */}
            <Input
              id={`users.${index}.position`}
              name={`users.${index}.position`}
              label={`${t('forum_members.member_position')} ${index + 1}`}
              placeholder={t('forum_members.enter_position')}
              validationRules={REQUIRED_VALIDATION_RULE}
            />

            {fields.length > 1 && (
              <div>
                <Button
                  variant="danger"
                  type="button"
                  className="w-full sm:w-auto sm:min-w-48"
                  onClick={() => remove(index)}
                >
                  <div className="flex gap-2 items-center">
                    <Icon name="trashCurrentColor" />
                    <span>
                      {t('global.delete')} {index + 1}
                    </span>
                  </div>
                </Button>
              </div>
            )}
          </div>
        ))}

        {/* Add Button */}
        <div>
          <Button
            type="button"
            onClick={handleAddEntry}
            className="w-full sm:w-auto sm:min-w-48 focus:outline-none group text-left"
            disabled={!shouldAddEntry()}
            isDisabled={!shouldAddEntry()}
          >
            <div className="flex gap-2 items-center">
              <Icon name="plus" />
              {t('forum_members.add_member')}
            </div>
          </Button>
        </div>

        <Button type="submit" disabled={isSubmitting}>
          {isSubmitting ? t('state.submitting') : t('global.confirm')}
        </Button>
      </form>
    </FormProvider>
  );
};

export default ForumCouncilForm;
