import { Link, useRouteError } from 'react-router-dom';

import Meta from '../components/layout/Meta';
import MainNavigation from '../components/layout/MainNavigationNew';
import Button from '../components/shared/Button';

import error_401 from '../assets/images/error/error-401.png';
import error_403 from '../assets/images/error/error-403.png';
import error_404 from '../assets/images/error/error-404.png';
import error_500 from '../assets/images/error/error-500.png';
import error_503 from '../assets/images/error/error-503.png';

import cableLeft from '../assets/images/error/cable-left.png';
import cableLeftMobile from '../assets/images/error/cable-left-mobile.png';
import cableRight from '../assets/images/error/cable-right.png';
import cableRightMobile from '../assets/images/error/cable-right-mobile.png';

import { HOME_PAGE_LINK } from '../constants';
import { useLogout } from '../hooks/useLogout';
import { useTranslation } from 'react-i18next';

interface ErrorMessageInterface {
  title: string;
  message: string;
  img?: string;
}

const getErrorMessages = (
  t: (key: string) => string
): Record<number | string, ErrorMessageInterface> => ({
  401: {
    title: t('errors.unauthorized.title'),
    message: t('errors.unauthorized.message'),
    img: error_401,
  },
  403: {
    title: t('errors.forbidden.title'),
    message: t('errors.forbidden.message'),
    img: error_403,
  },
  404: {
    title: t('errors.not_found.title'),
    message: t('errors.not_found.message'),
    img: error_404,
  },
  500: {
    title: t('errors.server_error.title'),
    message: t('errors.server_error.message'),
    img: error_500,
  },
  503: {
    title: t('errors.service_unavailable.title'),
    message: t('errors.service_unavailable.message'),
    img: error_503,
  },
  default: {
    title: t('error'),
    message: t('global.try_again'),
  },
});

const getErrorMessage = (
  status: number | undefined,
  errorMessages: Record<number | string, ErrorMessageInterface>
): ErrorMessageInterface => {
  return status !== undefined && status in errorMessages
    ? errorMessages[status]
    : errorMessages.default;
};

const ErrorMessage = ({ message }: { message: string }) => {
  const lines = message.split('<br />');

  return (
    <div>
      {lines.map((line, index) => (
        <p key={index}>
          {line}
          {index < lines.length - 1 && <br />}
        </p>
      ))}
    </div>
  );
};

interface RouteError {
  response: any;
  status?: number;
  message?: string;
}

function ErrorPage() {
  const { t } = useTranslation();
  const error = useRouteError() as RouteError;
  const { handleLogout } = useLogout();

  const errorMessages = getErrorMessages(t);
  const status: number | undefined = error?.response?.status || error.status;
  const { title, message, img } = getErrorMessage(status, errorMessages);

  return (
    <>
      <Meta title={t('errors.title_error')} />
      <div className="bg-secondary-50 overflow-x-hidden">
        {status !== 401 && <MainNavigation />}
        <main className="pks-container flex lg:items-center pt-28 pb-8 md:pt-32 md:pb-12 min-h-screen lg:pt-40 lg:pb-40">
          <div className="relative w-full flex flex-col items-center lg:items-start gap-36 lg:gap-0 lg:justify-between lg:flex-row lg:-mt-40">
            <div className="relative lg:w-1/2">
              <div className="pks-layout-col-md flex flex-col gap-8 lg:pr-20">
                <h1>{title}</h1>
                <ErrorMessage message={message} />
                {status !== 401 && (
                  <Link to={HOME_PAGE_LINK} className="z-10">
                    <Button>{t('global.home_page')}</Button>
                  </Link>
                )}

                {status === 401 && (
                  <div>
                    <Button onClick={handleLogout}>{t('auth.logout')}</Button>
                  </div>
                )}

                {img && (
                  <img
                    className="absolute -bottom-24 left-0 min-w-[105%] hidden lg:block"
                    src={cableLeft}
                    alt=""
                  />
                )}
              </div>
            </div>
            <>
              {img && (
                <div className="relative lg:w-1/2">
                  <img
                    src={img}
                    alt=""
                    className="w-10/12 m-auto lg:m-0 lg:ml-auto"
                  />
                  <img
                    className="absolute -top-24 left-[-14%] min-w-[114%] hidden lg:block"
                    src={cableRight}
                    alt=""
                  />
                  <img
                    className="absolute -top-[100px] -left-16 xxs:-left-4 lg:hidden"
                    src={cableLeftMobile}
                    alt=""
                  />
                  <img
                    className="absolute -top-[125px] -right-16 xxs:-right-4 lg:hidden"
                    src={cableRightMobile}
                    alt=""
                  />
                </div>
              )}
            </>
          </div>
        </main>
      </div>
    </>
  );
}

export default ErrorPage;
