import React, { useState, useEffect } from 'react';
import {
  Controller,
  useFormContext,
  FieldError,
  RegisterOptions,
} from 'react-hook-form';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import '../../../assets/styles/react-multi-date-picker/colors/primary.css';

import { DATE_FORMAT } from '../../../constants';

interface CustomDateRangePickerProps {
  idFrom: string;
  nameFrom: string;
  labelFrom: string;
  validationRulesFrom?: RegisterOptions;
  idTo: string;
  nameTo: string;
  labelTo: string;
  validationRulesTo?: RegisterOptions;
  flexParent?: boolean;
  [key: string]: any;
}

const CustomDateRangePicker: React.FC<CustomDateRangePickerProps> = ({
  idFrom,
  nameFrom,
  labelFrom,
  validationRulesFrom,
  idTo,
  nameTo,
  labelTo,
  validationRulesTo,
  flexParent = true,
}) => {
  const {
    control,
    formState: { errors, isSubmitted },
    setValue,
    getValues,
  } = useFormContext();

  const currentValues = getValues();

  const errorFrom = errors[nameFrom] as FieldError | undefined;
  const errorTo = errors[nameTo] as FieldError | undefined;

  const [fromDate, setFromDate] = useState<DateObject | null>(
    currentValues[nameFrom]
      ? new DateObject(currentValues[nameFrom])
      : new DateObject() // Initialize to current date if no value
  );
  const [toDate, setToDate] = useState<DateObject | null>(
    currentValues[nameTo]
      ? new DateObject(currentValues[nameTo])
      : new DateObject() // Initialize to current date if no value
  );

  useEffect(() => {
    if (!currentValues[nameFrom]) {
      setValue(nameFrom, fromDate?.format(DATE_FORMAT) || '');
    }
    if (!currentValues[nameTo]) {
      setValue(nameTo, toDate?.format(DATE_FORMAT) || '');
    }
  }, [fromDate, toDate, setValue, currentValues, nameFrom, nameTo]);

  const handleFromDateChange = (date: DateObject | null) => {
    setFromDate(date);
    const formattedDate = date?.format(DATE_FORMAT) || '';
    setValue(nameFrom, formattedDate, {
      shouldDirty: true,
      shouldValidate: true,
    });

    if (toDate && date && toDate.toDate() < date.toDate()) {
      setToDate(date);
      setValue(nameTo, formattedDate, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  };

  const handleToDateChange = (date: DateObject | null) => {
    setToDate(date);
    const formattedDate = date?.format(DATE_FORMAT) || '';
    setValue(nameTo, formattedDate, {
      shouldDirty: true,
      shouldValidate: true,
    });

    if (fromDate && date && fromDate.toDate() > date.toDate()) {
      setFromDate(date);
      setValue(nameFrom, formattedDate, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  };

  return (
    <>
      {/* Date Input From */}
      <div className={flexParent ? 'flex-1' : ''}>
        <label htmlFor={idFrom} className="block mb-1 hover:cursor-pointer">
          {labelFrom}
        </label>
        <Controller
          control={control}
          name={nameFrom}
          rules={validationRulesFrom}
          render={({ field: { value, onChange } }) => (
            <DatePicker
              id={idFrom}
              value={value || fromDate}
              onChange={(date) => {
                handleFromDateChange(date);
                onChange(date?.format(DATE_FORMAT));
              }}
              format={DATE_FORMAT}
              placeholder={DATE_FORMAT}
              containerStyle={{ width: '100%' }}
              className="primary"
              maxDate={toDate || new Date()} // Set the max date to the selected "To" date or today's date
              inputClass={`pks-input ${
                errorFrom
                  ? 'pks-input-error'
                  : isSubmitted
                  ? 'pks-input-success'
                  : 'pks-input-initial'
              }`}
            />
          )}
        />
        {errorFrom && <div className="text-danger">{errorFrom.message}</div>}
      </div>

      {/* Date Input To */}
      <div className={flexParent ? 'flex-1' : ''}>
        <label htmlFor={idTo} className="block mb-1 hover:cursor-pointer">
          {labelTo}
        </label>
        <Controller
          control={control}
          name={nameTo}
          rules={validationRulesTo}
          render={({ field: { value, onChange } }) => (
            <DatePicker
              id={idTo}
              value={value || toDate}
              onChange={(date) => {
                handleToDateChange(date);
                onChange(date?.format(DATE_FORMAT));
              }}
              format={DATE_FORMAT}
              placeholder={DATE_FORMAT}
              containerStyle={{ width: '100%' }}
              className="primary"
              minDate={fromDate || undefined} // Set the min date to the selected "From" date
              maxDate={new Date()} // Set the max date to today's date
              inputClass={`pks-input ${
                errorTo
                  ? 'pks-input-error'
                  : isSubmitted
                  ? 'pks-input-success'
                  : 'pks-input-initial'
              }`}
            />
          )}
        />
        {errorTo && <div className="text-danger">{errorTo.message}</div>}
      </div>
    </>
  );
};

export default CustomDateRangePicker;
