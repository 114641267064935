import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoaderFunctionArgs, useSearchParams } from 'react-router-dom';

import DropDownSelect from '../../../components/shared/DropdownSelect';
import Pagination from '../../../components/shared/Pagination';
import SearchInput from '../../../components/shared/SearchInput';
import ForumMembersList from './ForumMembersList';

import { usePaginatedDropdown } from '../../../hooks/usePaginatedDropdown';
import { DropDownItemInterface, PermissionsEnum } from '../../../types';

import ForumMembersStore from '../services/forumMembers.store';
import RegionsStore from '../../regions/services/regions.store';
import OrganizationsStore from '../../organizations/services/organizations.store';
import NoSearchResultsInfoMessage from '../../../components/shared/InfoMessage/NoSearchResults';
import NoResultsInfoMessage from '../../../components/shared/InfoMessage/NoResults';
import TransitionLoader from '../../../components/shared/TransitionLoader/TransitionLoader';
import ToggleSwitch from '../../../components/shared/ToggleSwitch';

import { hasPermission } from '../../../utils';
import { usePermission } from '../../../hooks/usePermission';
import { useRegions } from '../../../context/regions-context';

const forumMembersStore = new ForumMembersStore();
const regionsStore = new RegionsStore();
const organizationsStore = new OrganizationsStore();

const ForumMembers = () => {
  const { t } = useTranslation();
  const { activeRegion } = useRegions();

  const [searchParams, setSearchParams] = useSearchParams();
  const [showActive, setShowActive] = useState(true);

  // Permissions
  const canListOrganizations = usePermission([
    PermissionsEnum.OrganizationsList,
  ]);
  const canListRegions = usePermission([PermissionsEnum.RegionsList]);
  const canToggleUserStatus = usePermission([PermissionsEnum.UserActivate]);

  //Parse URL search parameters
  const page = Number(searchParams.get('page')) || 1;
  const region = searchParams.get('regionIds[]') || '';
  const organization = searchParams.get('organizationIds[]') || '';

  //Search field search param
  const [searchValue, setSearchValue] = useState(
    searchParams.get('name') || ''
  );

  // Parse URL search parameters forum members
  const { data: forumMembersQueryData, isFetching } =
    forumMembersStore.useGetForumMembers({
      page,
      searchTerm: searchValue,
      region,
      organization,
      invalid: !showActive,
    });
  const forumMembers = forumMembersQueryData?._embedded?.users || [];
  const currentPage = forumMembersQueryData?._embedded?._page || 1;
  const totalPages = forumMembersQueryData?._page_count || 1;

  useEffect(() => {
    setShowActive(true);
  }, [activeRegion?.id]);

  const handleToggleChange = (checked: boolean) => {
    if (!canToggleUserStatus) return;
    searchParams.set('page', '1');
    setSearchParams(searchParams);
    setShowActive(checked);
  };

  // ORGANIZATIONS
  const useOrganizationsDropdown = () => {
    return usePaginatedDropdown({
      fetchData: (page) =>
        organizationsStore.useGetOrganizations({
          page,
          searchTerm: '',
          enabled: canListOrganizations,
        }),
      extractItems: (data) => {
        return (data?.organizations || []).map((org: any) => ({
          id: org.id.toString(),
          label: org.name,
        }));
      },
    });
  };
  const {
    finalItems: finalOrganizations,
    isLoadingRef: isLoadingOrganizations,
  } = useOrganizationsDropdown();

  const organizationsOptions: DropDownItemInterface[] = [
    {
      id: 'all',
      label: t('institutions.all_institutions'),
    },
    ...finalOrganizations,
  ];

  let selectedOrganizationIndex = organizationsOptions.findIndex(
    (option) => option.id === organization
  );

  // REGIONS
  const useRegionsDropdown = () => {
    return usePaginatedDropdown({
      fetchData: (page) =>
        regionsStore.useGetRegions({
          page,
          searchTerm: '',
          enabled: canListRegions ? true : false,
        }),
      extractItems: (data) => {
        return (data?.regions || []).map((region: any) => ({
          id: region.id.toString(),
          label: region.name,
        }));
      },
    });
  };
  const { finalItems: finalRegions, isLoadingRef: isLoadingRegions } =
    useRegionsDropdown();
  const regionOptions: DropDownItemInterface[] = [
    {
      id: 'all',
      label: t('regions.all_regions'),
    },
    ...finalRegions,
  ];
  const selectedRegionIndex = regionOptions.findIndex(
    (option) => option.id === region
  );

  /**
   * Handles page changes in pagination.
   * Updates the URL with the new page number.
   *
   * @param {number} newPage - The new page number to navigate to.
   */
  const handlePageChange = (newPage: number) => {
    if (newPage.toString() !== currentPage) {
      searchParams.set('page', newPage.toString());

      if (region) {
        searchParams.set('regionIds[]', region);
      }
      if (organization) {
        searchParams.set('organizationIds[]', organization);
      }

      setSearchParams(searchParams);
    }
  };

  const handleSearch = (debouncedValue: string): void => {
    setSearchValue(debouncedValue);
    searchParams.set('name', debouncedValue);
    searchParams.set('page', '1');
    setSearchParams(searchParams);
  };

  const handleFilterChange = (selectedFilter: string, paramName: string) => {
    searchParams.set('page', '1');
    if (selectedFilter === 'all') {
      searchParams.delete(paramName);
    } else {
      searchParams.set(paramName, selectedFilter);
    }
    setSearchParams(searchParams);
  };

  const handleRegionChange = (selectedRegion: string) => {
    handleFilterChange(selectedRegion, 'regionIds[]');
  };

  const handleOrganizationChange = (selectedOrganization: string) => {
    handleFilterChange(selectedOrganization, 'organizationIds[]');
  };

  const handleRegionDelete = () => {
    searchParams.delete('regionIds[]');
    searchParams.set('page', '1');
    setSearchParams(searchParams);
  };

  const handleOrganizationDelete = () => {
    searchParams.delete('organizationIds[]');
    searchParams.set('page', '1');
    setSearchParams(searchParams);
  };

  return (
    <>
      {canToggleUserStatus && (
        <div className="w-fit">
          <ToggleSwitch
            checked={showActive}
            id="news-status"
            label={
              showActive
                ? t('forum_members.active_members')
                : t('forum_members.deactivated_members')
            }
            onChange={handleToggleChange}
          />
        </div>
      )}
      {isFetching && <TransitionLoader />}
      <div className="pks-layout-col-md ">
        <div className=" flex lg:flex-row flex-col items-center lg:gap-8 gap-1 mb-2 w-12/12">
          {canListRegions && (
            <span className="lg:w-4/12 w-full ">
              <DropDownSelect
                label={t('regions.choose_region')}
                options={regionOptions}
                isLoading={isLoadingRegions.current}
                onRemoveFilter={handleRegionDelete}
                onChange={handleRegionChange}
                selectedOptionIndex={
                  selectedRegionIndex > -1 ? selectedRegionIndex : 0
                }
              />
            </span>
          )}
          {canListOrganizations && (
            <span className="lg:w-4/12 w-full">
              <DropDownSelect
                label={t('institutions.choose_institution')}
                options={organizationsOptions}
                isLoading={isLoadingOrganizations.current}
                onRemoveFilter={handleOrganizationDelete}
                onChange={handleOrganizationChange}
                selectedOptionIndex={
                  selectedOrganizationIndex > -1 ? selectedOrganizationIndex : 0
                }
              />
            </span>
          )}
        </div>
        <SearchInput
          value={searchValue}
          onSearch={handleSearch}
          autofocus
          isFetching={isFetching}
        />
        {forumMembers.length === 0 ? (
          searchValue === '' ? (
            <NoResultsInfoMessage />
          ) : (
            <NoSearchResultsInfoMessage />
          )
        ) : (
          <>
            <ForumMembersList
              forumMembers={forumMembers}
              showToggle={canToggleUserStatus && !showActive ? true : false}
            />
            <Pagination
              currentPage={page}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </>
        )}
      </div>
    </>
  );
};

export default ForumMembers;

/**
 * Loader function to prefetch forum members.
 */
export async function loader({ request }: LoaderFunctionArgs) {
  const { searchParams } = new URL(request.url);
  const page = Number(searchParams.get('page')) || 1;
  const name = searchParams.get('name') || '';
  const region = searchParams.get('regionIds[]') || '';
  const organization = searchParams.get('organizationIds[]') || '';

  // Check if the user has the required permission
  if (!hasPermission([PermissionsEnum.UsersList])) {
    // If the user doesn't have permission, return null and don't fetch data
    return null;
  }

  // Fetch data based on the page number
  await forumMembersStore.preloadForumMembers({
    page,
    searchTerm: name,
    region,
    organization,
  });
  return null;
}
