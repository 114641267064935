import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import ConfirmDenyDialog from '../../../components/shared/ModalNew/ConfirmDenyDialog';
import SquareIcon, {
  SquareIconEnum,
} from '../../../components/shared/Icon/SquareIcon';

import { EDIT_NEWS_PATH } from '../../../constants';
import { useModal } from '../../../context/modal-context';
import { PermissionsEnum, StatusEnum } from '../../../types';
import { usePermission } from '../../../hooks/usePermission';
import { showToast } from '../../../utils';

import NewsStore from '../services/news.store';
const newsStore: NewsStore = new NewsStore();

enum ActionTypeEnum {
  Delete = 'delete',
  Deactivate = 'deactivate',
}

const NewsActionDropdown = ({
  id,
  status,
}: {
  id: number;
  status: StatusEnum;
}) => {
  const { t } = useTranslation();
  /* Permissions */
  const canViewNewsData = usePermission([PermissionsEnum.NewsData]);
  const canEditNews = usePermission([PermissionsEnum.NewsEdit]);
  const canToggleNewsStatus = usePermission([PermissionsEnum.NewsStatus]);
  const canDeleteNews = usePermission([PermissionsEnum.NewsDelete]);

  const shouldShowNewsActionDropdown =
    (canEditNews && canViewNewsData) || canToggleNewsStatus || canDeleteNews;

  const { openModal, closeModal } = useModal();

  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const dropdownClassName = `flex flex-col gap-1 w-40 pt-2 pb-2 bg-white rounded-lg border border-secondary-300`;
  const dropdownItemContentClassName =
    'block w-full px-4 py-1.5  text-left hover:bg-secondary-100 hover:cursor-pointer';

  const handleDelete = async () => {
    if (!canDeleteNews) return;

    const { success, message } = await newsStore.deleteNews(Number(id));
    showToast(message, success);
  };

  const handleStatus = async () => {
    if (!canToggleNewsStatus) return;

    const { success, message } = await newsStore.changeStatus({
      id: Number(id),
      status: status === StatusEnum.Active ? 0 : 1,
    });
    showToast(message, success);
  };

  const handleOpenActionModal = (e: React.MouseEvent, type: ActionTypeEnum) => {
    e.stopPropagation();

    if (!shouldShowNewsActionDropdown) return;

    setIsDropdownActive(false);

    const modalData =
      type === ActionTypeEnum.Delete
        ? {
            title: t('news.delete_news'),
            message: `${t('news.confirm_delete_news')} ${t(
              'global.irreversible_action'
            )}'`,
            onConfirm: handleDelete,
          }
        : {
            title: status ? t('news.deactivate_news') : t('news.activate_news'),
            message: status
              ? t('news.confirm_deactivate_news')
              : t('news.confirm_activate_news'),
            onConfirm: handleStatus,
          };

    openModal(
      <ConfirmDenyDialog
        infoMessage={modalData.message}
        onConfirm={modalData.onConfirm}
        onDeny={closeModal}
        closeModal={closeModal}
      />,
      modalData.title
    );
  };

  const handleDropdownButtonClick = () => {
    if (!shouldShowNewsActionDropdown) return;

    setIsDropdownActive((prevState) => !prevState);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (!shouldShowNewsActionDropdown) return;

    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsDropdownActive(false);
    }
  };

  useEffect(() => {
    if (!shouldShowNewsActionDropdown) return;

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShowNewsActionDropdown]);

  return (
    <>
      {shouldShowNewsActionDropdown && (
        <div className="flex flex-col items-end" ref={dropdownRef}>
          <SquareIcon
            onClick={handleDropdownButtonClick}
            type={SquareIconEnum.Dots}
            isActive={isDropdownActive}
          />
          {isDropdownActive && (
            <ul className={dropdownClassName}>
              {canEditNews && canViewNewsData && (
                <li>
                  <Link
                    to={`${EDIT_NEWS_PATH}/${id}`}
                    className={`${dropdownItemContentClassName}`}
                    onClick={handleDropdownButtonClick}
                  >
                    {t('global.edit')}
                  </Link>
                </li>
              )}

              {canToggleNewsStatus && (
                <li>
                  <button
                    className={`${dropdownItemContentClassName}`}
                    onClick={(e) =>
                      handleOpenActionModal(e, ActionTypeEnum.Deactivate)
                    }
                  >
                    {status ? t('global.deactivate') : t('global.activate')}
                  </button>
                </li>
              )}

              {canDeleteNews && (
                <li>
                  <button
                    className={`text-danger ${dropdownItemContentClassName}`}
                    onClick={(e) =>
                      handleOpenActionModal(e, ActionTypeEnum.Delete)
                    }
                  >
                    {t('global.delete')}
                  </button>
                </li>
              )}
            </ul>
          )}
        </div>
      )}
    </>
  );
};

export default NewsActionDropdown;
