import {
  useState,
  InputHTMLAttributes,
  TextareaHTMLAttributes,
  ChangeEvent,
} from 'react';
import { RegisterOptions } from 'react-hook-form';
import useInputContext from '../../../hooks/useInputContext';

import Label from '../Label';
import Icon from '../Icon';

interface BaseProps {
  id: string;
  name: string;
  label: string;
  numeric?: boolean;
  validationRules?: RegisterOptions;
  beError?: boolean;
  maskedInput?: boolean;
  hasLock?: boolean;
}

interface CustomInputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'id' | 'name'>,
    BaseProps {
  type?: string;
  asTextarea?: false;
  height?: undefined;
  value?: string;
}

interface CustomTextareaProps
  extends Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'id' | 'name'>,
    BaseProps {
  type?: undefined;
  asTextarea: true;
  height?: string;
  value?: undefined;
}

type Props = CustomInputProps | CustomTextareaProps;

const Input: React.FC<Props> = ({
  id,
  name,
  label,
  type = 'text',
  validationRules,
  asTextarea = false,
  height = '160',
  numeric = false,
  beError = false,
  maskedInput = false,
  hasLock = false,
  ...rest
}) => {
  const { register, error, isSubmitted } = useInputContext(name);
  // Manages state for controlling password visibility
  const [showPassword, setShowPassword] = useState(false);
  const [maskedValue, setMaskedValue] = useState<string>('');
  const [isHiddenValueFocused, setIsHiddenValueFocused] = useState(false);

  /**
   * Toggles the visibility of the password input's content.
   * Updates the state to show or hide the password based on the current state.
   */
  const handleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  /**
   * Restricts input to numeric characters if the 'numeric' prop is true.
   * Removes any non-numeric characters from the input value.
   *
   * @param event - The event object representing the input change.
   */
  const handleInput = (
    event: ChangeEvent<HTMLInputElement & HTMLTextAreaElement>
  ) => {
    if (numeric) {
      event.target.value = event.target.value.replace(/[^0-9]/g, '');
    }

    if (maskedInput) {
      setMaskedValue('*'.repeat(event.target.value.length));
    }
  };

  const handleFocus = () => {
    if (maskedInput) {
      setIsHiddenValueFocused(true);
    }
  };

  const handleBlur = () => {
    if (maskedInput) {
      setIsHiddenValueFocused(false);
    }
  };

  /**
   * Generates the input element's class name based on the 'asTextarea' prop and form state indicators.
   * Adds appropriate styling classes validation and submission states.
   *
   * @returns {string} The concatenated class names for the input element.
   */

  const getInputClassName = (): string => {
    let inputClassNameList = ['pks-input'];

    if (asTextarea) {
      inputClassNameList.push('py-3');
    }
    if (rest.disabled) {
      inputClassNameList.push('pks-input-disabled');
    } else if (error) {
      inputClassNameList.push('pks-input-error');
    } else if (!beError && isSubmitted && !error) {
      inputClassNameList.push('pks-input-success');
    } else {
      inputClassNameList.push('pks-input-initial');
    }

    const inputClassName = inputClassNameList.join(' ');
    return inputClassName;
  };

  return (
    <div>
      {/* Label */}
      <Label id={id} label={label} disabled={rest.disabled} hasLock={hasLock} />
      {/* Input | Textarea */}
      <div className="relative">
        {asTextarea ? (
          <textarea
            id={id}
            style={{ height: height + 'px' }}
            {...register(name, validationRules)}
            {...(rest as TextareaHTMLAttributes<HTMLTextAreaElement>)}
            className={getInputClassName()}
            spellCheck="false"
            autoCorrect="off"
            data-gramm="false"
            data-gramm_editor="false"
            data-enable-grammarly="false"
          />
        ) : (
          <>
            {maskedInput && !showPassword && maskedValue.length > 0 && (
              <div
                className="absolute top-1 bottom-1 left-4 right-10 flex items-center select-none pointer-events-none overflow-hidden bg-white"
                tabIndex={-1}
              >
                <span>{maskedValue}</span>
                {isHiddenValueFocused && (
                  <div
                    className="cursor-indicator h-full animate-blink bg-black"
                    style={{
                      width: '1px',
                      height: '1.2em',
                    }}
                  />
                )}
              </div>
            )}
            <input
              id={id}
              type={
                showPassword && type === ('password' as any) ? 'text' : type
              }
              {...register(name, validationRules)}
              {...(rest as InputHTMLAttributes<HTMLInputElement>)}
              autoComplete={
                type === 'password' && showPassword ? 'off' : rest.autoComplete
              }
              className={getInputClassName()}
              onInput={handleInput}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
          </>
        )}
        {(type === 'password' || maskedInput) && !asTextarea && (
          <div
            className="absolute right-4 top-1/2 transform -translate-y-1/2 hover:cursor-pointer"
            onClick={handleShowPassword}
          >
            {showPassword ? <Icon name="eyeHide" /> : <Icon name="eyeShow" />}
          </div>
        )}
      </div>
      {/* Error */}
      {error && <div className="text-danger">{error.message}</div>}
    </div>
  );
};

export default Input;
