import {
  keepPreviousData,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { /* AxiosError,  */ AxiosResponse } from 'axios';

import { translate as t } from '../../../config/i18n.config';
import queryClient from '../../../config/tanstackQueryConfig';
/* import { SOMETHING_WENT_WRONG_TEXT } from '../../../constants';
import {
  ActionResponse,
  ErrorResponse,
  SuccessResponse,
  UploadFileInterface,
} from '../../../types'; */
import { handleErrors } from '../../../utils';

import { QUERY_KEY as searchQueryKey } from '../../search/services/search.store';
import NewsApi from './news.api';
import { NewsPaginationInterface, NewsPhotoInterface } from './index.types';
import { ActionResponse, StatusEnum } from '../../../types';

export const QUERY_KEYS = {
  NEWS: 'news',
  DOCUMENTS: 'documents',
  NEWS_PHOTO: 'news-photo',
};

const api: NewsApi = new NewsApi();

class NewsStore {
  useGetNews = ({
    page,
    region,
    rowsPerPage,
    inactive = false,
    enabled = true,
  }: {
    page?: number;
    region?: string;
    rowsPerPage?: any;
    inactive?: boolean;
    enabled?: boolean;
  }) => {
    return useQuery(
      getNewsQueryOptions({ page, region, rowsPerPage, inactive, enabled })
    );
  };

  useGetNewsPhoto = ({
    id,
    enabled = true,
  }: {
    id: number;
    enabled?: boolean;
  }) => {
    return useQuery(getNewsPhotoQueryOptions({ id, enabled }));
  };

  storeNews = async ({ data, id }: { data: any; id?: number }) => {
    try {
      const res = await api.storeNews({ data, id });
      const resData = res.data;
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.NEWS] });
      id &&
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.NEWS_PHOTO, { id }],
        });
      queryClient.invalidateQueries({ queryKey: [searchQueryKey] });
      return {
        success: true,
        message:
          resData?.message || id
            ? t('news.news_successfully_updated')
            : t('news.news_successfully_added'),
        data: resData,
      };
    } catch (error) {
      const { message, success } = handleErrors(error);
      return { message, success };
    }
  };

  changeStatus = async ({ id, status }: { id: number; status: StatusEnum }) => {
    try {
      const res = await api.changeStatus({ id, status });
      const resData = res.data;
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.NEWS] });
      queryClient.invalidateQueries({ queryKey: [searchQueryKey] });
      const successMessage =
        status === StatusEnum.Active
          ? t('news.news_successfully_activated')
          : t('news.news_successfully_deactivated');
      return {
        success: true,
        message: successMessage,
        data: resData,
      };
    } catch (error) {
      const { message, success } = handleErrors(error);
      return { message, success };
    }
  };

  getSingleNews = async (id: number) => {
    try {
      const res = await api.getSingleNews(id);
      const resData = res.data;
      return {
        success: true,
        // message: resData?.message || 'Organization successfully added!',
        data: resData,
      };
    } catch (error) {
      const { message, success, code } = handleErrors(error);
      return { message, success, code };
    }
  };

  deleteNews = async (id: number) => {
    try {
      const res = await api.deleteNews(id);
      const resData = res.data;
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.NEWS] });
      queryClient.invalidateQueries({ queryKey: [searchQueryKey] });
      return {
        success: true,
        message: t('news.news_successfully_deleted'),
        data: resData,
      };
    } catch (error) {
      const { message, success } = handleErrors(error);
      return { message, success };
    }
  };
}

export default NewsStore;

/**
 * Function that returns query options for fetching news data.
 *
 * @returns {UseQueryOptions<NewsPaginationInterface, Error>}
 * Options for the `useQuery` hook to fetch news data.
 */
export const getNewsQueryOptions = ({
  page,
  region,
  rowsPerPage,
  inactive = false,
  enabled = true,
}: {
  page?: number;
  region?: string;
  rowsPerPage?: any;
  inactive?: boolean;
  enabled?: boolean;
}): UseQueryOptions<NewsPaginationInterface, Error> => ({
  queryKey: [QUERY_KEYS.NEWS, { page, region, rowsPerPage, inactive }],
  queryFn: async (): Promise<any> => {
    const response: AxiosResponse<any> = await api.getNews({
      page,
      region,
      rowsPerPage,
      inactive,
    });
    return response.data;
  },
  enabled: enabled,
  placeholderData: keepPreviousData,
});

type NewsPhotoResponse = ActionResponse<NewsPhotoInterface>;

/**
 * Function that returns query options for fetching news photo.
 *
 * @returns {UseQueryOptions<NewsPhotoResponse>}
 * Options for the `useQuery` hook to fetch news photo.
 */
export const getNewsPhotoQueryOptions = ({
  id,
  enabled = true,
}: {
  id: number;
  enabled?: boolean;
}): UseQueryOptions<NewsPhotoResponse> => ({
  queryKey: [QUERY_KEYS.NEWS_PHOTO, { id }],
  queryFn: async (): Promise<NewsPhotoResponse> => {
    if (!enabled) {
      return {
        success: false,
        message: t('global.query_disabled'),
      };
    }

    try {
      const res = await api.getNewsPhoto(id);

      const imageBlob = new Blob([res.data], {
        type: res.headers['content-type'],
      });

      const imageFile = new File([imageBlob], `news_${id}`, {
        type: res.headers['content-type'],
      });

      const fileUrl = URL.createObjectURL(imageFile);

      return {
        success: true,
        message: t('files.file_download_successful'),
        data: {
          imageFile,
          fileUrl,
        },
      };
    } catch (error) {
      const { message, success } = handleErrors(error);
      return { message, success };
    }
  },
  enabled: enabled,
  placeholderData: keepPreviousData,
});
