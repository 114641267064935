import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import Icon from '../../../components/shared/Icon';
import InfoMessage from '../../../components/shared/InfoMessage';

import fraudCaseThumbnail from '../../../assets/images/thumbnails/fraud-case-thumbnail.png';
import newsThumbnail from '../../../assets/images/thumbnails/news-thumbnail.png';
import documentThumbnail from '../../../assets/images/thumbnails/document-thumbnail.png';

import { FRAUD_CASE_PATH, NEWS_PATH } from '../../../constants';
import {
  DocumentResponseInterface,
  SearchResultItemType,
  SearchResultsProps,
} from '../services/search.types';

import SearchStore from '../services/search.store';
const searchStore: SearchStore = new SearchStore();

const SearchResultsList: React.FC<SearchResultsProps> = ({
  data,
  initialSearchTerm,
  searchTerm,
  error,
  minChars,
  isFetching = false,
  onClose,
}) => {
  const { t } = useTranslation();
  const { hits, total } = data;
  const [isLoading, setIsLoading] = useState<string | false>(false);
  const hasViewAllButton = !!onClose;

  if ((hasViewAllButton && searchTerm.length < minChars) || isFetching)
    return null;

  if (error) {
    return (
      <InfoMessage
        icon="danger"
        message={`${t('error')} ${t('global.try_again')}`}
      />
    );
  }

  if (total === 0) {
    const InfoChildren = (
      <p>
        {!hasViewAllButton ? (
          <>
            {t('state.no_hits_search_term')}{' '}
            <b className="break-all">'{initialSearchTerm || searchTerm}'</b>
          </>
        ) : (
          <>{t('state.no_hits_search')}</>
        )}
      </p>
    );
    return <InfoMessage icon="danger" message={InfoChildren} />;
  }

  const containerClassName = hasViewAllButton
    ? 'flex flex-col gap-1 mb-[46px]'
    : '';

  const listClassName = hasViewAllButton
    ? 'flex flex-col gap-1 max-h-[60vh] overflow-x-hidden overflow-y-auto'
    : 'pks-layout-col';

  const listItemClassName = hasViewAllButton
    ? 'flex items-center hover:bg-secondary-100'
    : 'pks-card-bg pks-card-bg-hover overflow-hidden rounded-lg';

  const listItemInnerClassName = `block w-full ${
    hasViewAllButton
      ? 'flex gap-2 px-4 py-1.5'
      : 'bg-white ml-[6px] pl-[22px] pr-4 py-2.5'
  }`;

  const getHitDetails = (
    type: SearchResultItemType,
    url: string
  ): { img: string; alt: string; transformedUrl: string } => {
    switch (type) {
      case SearchResultItemType.FRAUD_CASE:
        return {
          img: fraudCaseThumbnail,
          alt: 'Thumbnail for Fraud Case',
          transformedUrl: url.replace(
            '/api/fraudcases/',
            `${FRAUD_CASE_PATH}/`
          ),
        };
      case SearchResultItemType.NEWS:
        return {
          img: newsThumbnail,
          alt: 'Thumbnail for News Article',
          transformedUrl: url.replace('/api/news/', `${NEWS_PATH}/`),
        };
      case SearchResultItemType.ANY:
      default:
        return {
          img: documentThumbnail,
          alt: 'Thumbnail for Document',
          transformedUrl: url,
        };
    }
  };

  const renderSearchItemContent = ({
    id,
    title,
    img,
    alt,
  }: {
    id: string;
    title: string;
    img: string;
    alt: string;
  }) => {
    return (
      <div className="flex gap-2 items-center justify-between w-full">
        <div className="flex gap-2 items-center">
          <img src={img} alt={alt} width={38} height={38} loading="eager" />
          <h2 className="font-normal text-base leading-7">{title}</h2>
        </div>
        {isLoading && isLoading === id && (
          <Icon name="loadingSpinner" className="w-6 h-6" />
        )}
      </div>
    );
  };

  const handleOpenDocument = async (e: any, url: string, id: string) => {
    e.stopPropagation();
    toast.dismiss();
    setIsLoading(id);
    try {
      const response: DocumentResponseInterface = await searchStore.getDocument(
        url.replace('/api/', '')
      );

      if (!response.url) {
        throw new Error(response.error);
      }

      window.open(response.url, '_blank');
      onClose?.();
    } catch (error) {
      const message = error instanceof Error ? error.message : t('error');
      toast.error(message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={containerClassName}>
      <ul className={listClassName}>
        {hits.map((hit) => {
          const { id, title, type, url } = hit;
          const { img, alt, transformedUrl } = getHitDetails(hit.type, url);

          return (
            <li key={id} className={listItemClassName}>
              {type === SearchResultItemType.NEWS ||
              type === SearchResultItemType.FRAUD_CASE ? (
                <Link
                  to={transformedUrl}
                  className={listItemInnerClassName}
                  onClick={onClose}
                >
                  {renderSearchItemContent({
                    title,
                    img,
                    alt,
                    id,
                  })}
                </Link>
              ) : (
                <button
                  className={listItemInnerClassName}
                  onClick={(e) => handleOpenDocument(e, url, hit.id)}
                >
                  {renderSearchItemContent({
                    id,
                    title,
                    img,
                    alt,
                  })}
                </button>
              )}
            </li>
          );
        })}
      </ul>
      {/* See All Link */}
      {hasViewAllButton && (
        <button onClick={onClose}>
          <div className="absolute right-2 bottom-1 left-2 bg-white h-[50px] flex items-center justify-center px-4 py-1.5 border-t border-secondary-300 text-center text-primary-300 font-bold hover:text-primary">
            {t('global.view_all_results')}
          </div>
        </button>
      )}
    </div>
  );
};

export default SearchResultsList;
