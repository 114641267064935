import { useTranslation } from 'react-i18next';

import Meta from '../components/layout/Meta';
import PageLayout from '../layouts/PageLayout';
import Regions from '../modules/regions/UI';

import { PermissionsEnum } from '../types';
import { usePermission } from '../hooks/usePermission';
import { useRedirect } from '../hooks/useRedirect';

function RegionsPage() {
  const { t } = useTranslation();
  const canListRegions = usePermission([PermissionsEnum.RegionsList]);
  useRedirect({ redirect: canListRegions === false });

  return (
    <>
      <Meta title={t('regions.regions')} />
      {canListRegions && (
        <PageLayout>
          <Regions />
        </PageLayout>
      )}
    </>
  );
}

export default RegionsPage;
