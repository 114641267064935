import { useEffect } from 'react';
import Icon from '../Icon';
import { useTranslation } from 'react-i18next';

function TransitionLoader({ message = '', isModalOpen = false }) {
  const { t } = useTranslation();

  useEffect(() => {
    if (!isModalOpen) return;

    const modalContent = document.querySelector(
      '.js-global-modal'
    ) as HTMLElement;

    if (modalContent) {
      modalContent.style.zIndex = '0'; // Set zIndex when modal mounts
      modalContent.classList.remove('bg-modalBackdrop');
    }

    // Optional cleanup if you need to reset the zIndex when the modal unmounts
    return () => {
      if (modalContent) {
        modalContent.removeAttribute('style'); // Reset zIndex or set it to a desired value
        modalContent.classList.add('bg-modalBackdrop');
      }
    };
  }, [isModalOpen]);

  return (
    <div className="fixed z-40 h-screen top-0 left-0 w-full flex items-center justify-center bg-white opacity-75">
      <div>
        <Icon name="pageSpinner" className="flex justify-center" />
        <p className="font-bold h2">{message || t('state.loading')}</p>
      </div>
    </div>
  );
}

export default TransitionLoader;
