import { useTranslation } from 'react-i18next';
import { redirect, Link } from 'react-router-dom';

import Button from '../../components/shared/Button';
import Meta from '../../components/layout/Meta';

import { HOME_PAGE_LINK, LOGIN_PAGE_LINK } from '../../constants';
import { useAuth } from '../../context/auth-context';
import { useLogout } from '../../hooks/useLogout';

function LogoutPage() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { handleLogout } = useLogout();

  return (
    <>
      <Meta title={t('auth.logout')} />
      <div className="text-center">
        <div className="pks-card overflow-hidden inline-block rounded-lg">
          <div className="pks-layout-col-md bg-white p-4 sm:px-8">
            <p>
              {t('global.good_day')}, {user?.name}.<br />{' '}
              {t('auth.sign_out_question')}
            </p>
            <div>
              <div className="inline-flex flex-wrap gap-4">
                <Button className="flex-auto" onClick={handleLogout}>
                  {t('auth.logout')}
                </Button>
                <Link className="flex-auto" to={HOME_PAGE_LINK} replace={true}>
                  <Button className="w-full">{t('global.home_page')}</Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LogoutPage;

export function action() {
  return redirect(LOGIN_PAGE_LINK);
}
