import React from 'react';
import Label from '../Label';

interface Props {
  id: string;
  label: string;
  children: React.ReactNode;
  title?: string;
}

const FakeInput: React.FC<Props> = ({ id, label, children, title }) => {
  return (
    <div>
      {/* Label */}
      <Label
        id={id}
        label={label}
        disabled={true}
        hasLock={true}
        title={title}
      />

      {/* Value Display */}
      <div className="pks-input pks-input-fake pks-input-disabled flex items-center">
        <span className="overflow-ellipsis overflow-hidden">{children}</span>
      </div>

      {/* Error (if needed, can add content here) */}
    </div>
  );
};

export default FakeInput;
