import { useTranslation } from 'react-i18next';
import Meta from '../components/layout/Meta';
import { useAuth } from '../context/auth-context';
import { globalWS } from '../init';
import PageLayout from '../layouts/PageLayout';
import ChatLayout from '../modules/chat/UI';
import { useRegions } from '../context/regions-context';
import { usePaginatedDropdown } from '../hooks/usePaginatedDropdown';
import ForumMembersStore from '../modules/forumMembers/services/forumMembers.store';
import RegionsStore from '../modules/regions/services/regions.store';
import TransitionLoader from '../components/shared/TransitionLoader/TransitionLoader';
import { useEffect, useRef, useState } from 'react';
import ChatPageLayout from '../layouts/ChatLayout';
import Button from '../components/shared/Button';
import { hasPermission } from '../utils';
import { PermissionsEnum } from '../types';
import { useRedirect } from '../hooks/useRedirect';

const forumMembersStore: ForumMembersStore = new ForumMembersStore();
const regionsStore: RegionsStore = new RegionsStore();

const ChatPage = () => {
  const { t } = useTranslation();
  // Permissions
  const canListUsers = hasPermission([PermissionsEnum.UsersList]);
  const canListRegions = hasPermission([PermissionsEnum.RegionsList]);

  const shouldRedirect = canListUsers === false;
  useRedirect({ redirect: shouldRedirect });

  const pageTitle = t('chat.chat');
  const { activeRegion } = useRegions();

  const [initForumMembersPage, setInitForumMembersPage] = useState(1);
  const [availableForumMembers, setAvailableForumMembers] = useState<any[]>([]);
  const [finalForumMembers, setFinalForumMembers] = useState<any[]>([]);
  const [isDataResetting, setIsDataResetting] = useState(false);
  const isLoadingForumMembersRef = useRef<boolean>(true);

  const {
    data: forumMembersQueryData,
    refetch,
    isFetching: isFetchingUsers,
  } = forumMembersStore.useGetForumMembers({
    page: initForumMembersPage,
    searchTerm: '',
    enabled: canListUsers,
  });

  useEffect(() => {
    if (!canListUsers) return;
    setIsDataResetting(true);
    setInitForumMembersPage(1);
    setAvailableForumMembers([]);
    isLoadingForumMembersRef.current = true;
    refetch().then(() => {
      setIsDataResetting(false);
    });
  }, [activeRegion?.id, refetch, canListUsers]);

  useEffect(() => {
    if (!forumMembersQueryData || isDataResetting) return;

    const {
      _embedded: { users = [] } = {},
      _page = 1,
      _page_count = 1,
    } = forumMembersQueryData;

    const newUsers = users.map((user: any) => ({
      userId: user.id.toString(),
      firstName: user.first_name,
      lastName: user.last_name,
    }));

    setAvailableForumMembers((prevUsers) =>
      _page === 1 ? newUsers : [...prevUsers, ...newUsers]
    );

    if (_page < _page_count) {
      setInitForumMembersPage((prevPage) => prevPage + 1);
    } else {
      isLoadingForumMembersRef.current = false;
    }
  }, [forumMembersQueryData, isDataResetting]);

  useEffect(() => {
    if (!isLoadingForumMembersRef.current && !isDataResetting) {
      setFinalForumMembers([...availableForumMembers]);
    }
  }, [availableForumMembers, isDataResetting]);

  const useGetAllRegions = (editFromData?: any) => {
    return usePaginatedDropdown({
      fetchData: (page) =>
        regionsStore.useGetRegions({ page, enabled: canListRegions }),
      extractItems: (data) => {
        return (data?.regions || []).map((region: any) => ({
          id: region.id,
          label: region.name,
        }));
      },
      dependencies: [],
    });
  };
  const { finalItems: allRegions, isLoadingRef: isLoadingRegions } =
    useGetAllRegions();

  const sentinelRegionsRef = isLoadingRegions.current;

  const [isConnected, setIsConnected] = useState<boolean>(true);

  useEffect(() => {
    const checkConnectionStatus = () => {
      const status = globalWS.getConnectionStatus();
      setIsConnected(status);
    };

    const intervalId = setInterval(checkConnectionStatus, 5000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <Meta title={pageTitle} />

      {!canListUsers ? (
        <div className="text-center">
          <p className="">{t('permissions.no_permission_message')}</p>
        </div>
      ) : isConnected ? (
        <div className="lg:w-2/3 w-full mx-auto     ">
          {isLoadingForumMembersRef.current ? (
            <TransitionLoader message={t('chat.loading_messages')} />
          ) : (
            <ChatLayout
              allUsers={finalForumMembers}
              allRegions={allRegions}
              isLoadingUsers={isLoadingForumMembersRef.current}
              isLoadingRegions={sentinelRegionsRef}
            />
          )}
        </div>
      ) : (
        <div className="h-[calc(100vh/2)] bg-secondary-100 w-3/4 mx-auto  rounded-md flex items-center justify-center flex-col">
          <p className=" font-beld text-xl text-center ">
            {t('chat.chat_connection_lost')}
          </p>
          <Button className="mt-4" onClick={() => window.location.reload()}>
            {t('global.refresh_page')}
          </Button>
        </div>
      )}
    </>
  );
};

export default ChatPage;
