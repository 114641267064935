import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import ConfirmDenyDialog from '../../../components/shared/ModalNew/ConfirmDenyDialog';
import Icon from '../../../components/shared/Icon';
import NoResultsInfoMessage from '../../../components/shared/InfoMessage/NoResults';
import NoSearchResultsInfoMessage from '../../../components/shared/InfoMessage/NoSearchResults';
import RegionForm from './RegionForm';
import RegionPreview from './RegionPreview';

// import StatusIndicator from '../../../components/shared/StatusIndicator';
import { useModal } from '../../../context/modal-context';
import { usePermission } from '../../../hooks/usePermission';
import { PermissionsEnum } from '../../../types';
import { formatDateWithPoints, showToast } from '../../../utils';

import { RegionInterface } from '../services/regions.types';
import RegionsStore from '../services/regions.store';
import { useRegions } from '../../../context/regions-context';
const regionsStore: RegionsStore = new RegionsStore();

interface RegionListProps {
  regions: RegionInterface[] | undefined;
  onUpdate: (region?: RegionInterface | null) => void;
  searchValue?: string;
}

/**
 * Renders a list of regions with the option to edit each region.
 * Displays a message when there are no results and shows a status indicator for each region.
 *
 * @component
 * @param {RegionListProps} props - The props for this component.
 * @param {RegionInterface[] | undefined} props.regions - The list of regions to display.
 * @param {(region?: RegionInterface | null) => void} props.onUpdate - The callback to handle region updates.
 * @param {string} [props.searchValue] - The current search value used to filter the list of regions.
 *
 * @returns {React.JSX.Element} The rendered list of regions.
 */
const RegionsList = ({
  regions,
  onUpdate,
  searchValue = '',
}: RegionListProps): React.JSX.Element => {
  const { t } = useTranslation();
  const { activeRegion, setAvailableRegionsData, setActiveRegion } =
    useRegions();
  /* Permissions */
  const canViewRegionData = usePermission([PermissionsEnum.RegionData]);
  const canEditRegion = usePermission([PermissionsEnum.RegionEdit]);
  const canDeleteRegion = usePermission([PermissionsEnum.RegionDelete]);

  const { openModal, closeModal } = useModal();

  const deleteRegion = async (id: number) => {
    const { success, message } = await regionsStore.deleteRegion(id);
    showToast(message, success);

    if (success) {
      // Filter out the deleted region from the availableRegionsData
      setAvailableRegionsData((prevRegions) => {
        if (!prevRegions) return prevRegions;

        const updatedRegions = {
          ...prevRegions,
          regions: prevRegions.regions.filter((region) => region.id !== id),
        };

        // Also check if the deleted region is the active one and update active region
        if (activeRegion?.id === id) {
          setActiveRegion(prevRegions.regions[0] || undefined); // Set to the first region if available
        }

        // Check if there are no available regions left and navigate to home
        return updatedRegions;
      });
    }
  };

  const handleOpenPreview = (e: any, region: any) => {
    if (!canViewRegionData) return;
    e.stopPropagation();
    openModal(<RegionPreview region={region} />, t('regions.region'));
  };

  const handleOpenRegionForm = (e: any, region: any) => {
    e.stopPropagation();
    if (!canEditRegion) return;
    openModal(
      <RegionForm region={region} onClose={closeModal} />,
      t('regions.edit_region')
    );
  };

  const handleOpenDeleteRegionModal = (
    e: MouseEvent<HTMLButtonElement>,
    id: number
  ) => {
    e.stopPropagation();
    if (!canDeleteRegion) return;

    openModal(
      <ConfirmDenyDialog
        infoMessage={t('regions.prompt_region_delete')}
        onConfirm={() => deleteRegion(id)}
        onDeny={closeModal}
        closeModal={closeModal}
      />,
      t('regions.title_region_delete_preview')
    );
  };

  return (
    <ul className="flex flex-col gap-3">
      {regions?.length === 0 ? (
        <li>
          {searchValue === '' ? (
            <NoResultsInfoMessage />
          ) : (
            <NoSearchResultsInfoMessage />
          )}
        </li>
      ) : (
        regions?.map((region) => {
          const formatedDate = formatDateWithPoints(
            region?.dateEdited?.date || region?.dateCreated?.date
          );
          return (
            <li
              key={region.id}
              id={`region-${region.id}`}
              className={`pks-card-bg overflow-hidden rounded-lg ${
                canViewRegionData ? 'pks-card-bg-hover cursor-pointer' : ''
              } group`}
              onClick={(e) => handleOpenPreview(e, region)}
            >
              <div className="flex gap-4 items-center justify-between ml-[6px] pl-[22px] pr-4 py-3 bg-white">
                <div className="flex gap-2 items-center">
                  <div className="pks-layout-col">
                    <p className="font-bold">{region.name}</p>
                    <span className="text-secondary-400">{formatedDate}</span>
                  </div>
                </div>
                <div className="inline-flex items-center flex-col lg:flex-row  gap-4">
                  {canEditRegion && (
                    <span
                      className="transform scale-125 p-2 "
                      onClick={(e) => handleOpenRegionForm(e, region)}
                    >
                      <Icon
                        name="pencil"
                        aria-hidden="true"
                        className="hover:text-primary-300 hover:cursor-pointer"
                        tabIndex={-1}
                      />
                    </span>
                  )}
                  {canDeleteRegion && (
                    <button
                      aria-label="Delete region"
                      onClick={(e) => handleOpenDeleteRegionModal(e, region.id)}
                    >
                      <Icon name="trash" />
                    </button>
                  )}
                </div>
              </div>
            </li>
          );
        })
      )}
    </ul>
  );
};

export default RegionsList;
