import { useTranslation } from 'react-i18next';
import { useNavigation } from 'react-router-dom';

import Icon from '../Icon';

import { PaginationInterface as PaginationProps } from '../../../types';
import usePagination from '../../../hooks/usePagination';

/**
 * Pagination Component for navigating through pages.
 *
 * @param {PaginationProps} props - The properties for the pagination component.
 * @param {number} [props.currentPage=1] - The current page number. Defaults to 1.
 * @param {number} props.totalPages - The total number of pages.
 * @param {number} [props.maxButtonCount=5] - The maximum number of pagination buttons to display. Defaults to 5.
 * @param {(value: number) => void} props.onPageChange - Function to handle page changes.
 *
 * @returns {React.ReactElement | null} The pagination component or null if no pagination is needed.
 */
const Pagination: React.FC<PaginationProps> = ({
  currentPage = 1,
  totalPages,
  maxButtonCount = 5,
  onPageChange,
  isLoading,
}: PaginationProps): React.ReactElement | null => {
  const { t } = useTranslation();
  const { state: navigationState } = useNavigation();

  const { generatePageNumbers } = usePagination({
    currentPage,
    totalPages,
    maxButtonCount,
  });

  if (totalPages <= 1) {
    return null;
  }

  const pageNumbers = generatePageNumbers();

  // Helper to create a button's classes
  const getPaginationItemBtnClasses = (number?: number) =>
    `w-7 h-7 xs:w-10 xs:h-10 xs:px-1 xs:py-1 rounded-lg border active:border-primary-300 active:text-primary-300 text-sm sm:text-base ${
      number === currentPage
        ? 'border-primary-300 bg-primary-300 text-white'
        : 'border-secondary-200 hover:border-primary-300 hover:text-primary-300'
    }`;

  // Handle changing pages
  const handlePageChange = (selectedPage: number) => {
    onPageChange(selectedPage);
  };

  // Pagination buttons
  const backButton = (
    <li>
      <button
        onClick={() => handlePageChange(currentPage - 1)}
        className={`${getPaginationItemBtnClasses()} ${
          currentPage <= 1 ? 'opacity-25 pointer-events-none' : ''
        }`}
        aria-label={t('pagination.prev_page')}
        disabled={currentPage <= 1 || navigationState === 'loading'}
      >
        <Icon
          name="chevronDown"
          className="flex items-center justify-center rotate-90"
        />
      </button>
    </li>
  );

  const nextButton = (
    <li>
      <button
        onClick={() => handlePageChange(currentPage + 1)}
        className={`${getPaginationItemBtnClasses()} ${
          currentPage >= totalPages ? 'opacity-25 pointer-events-none' : ''
        }`}
        aria-label={t('pagination.next_page')}
        disabled={currentPage >= totalPages || navigationState === 'loading'}
      >
        <Icon
          name="chevronDown"
          className="flex items-center justify-center -rotate-90"
        />
      </button>
    </li>
  );

  return (
    <nav className="pagination" aria-label={t('pagination.navigate_pages')}>
      <ul className="flex gap-0.5 xs:gap-1 justify-center">
        {backButton}
        <div className="flex gap-0.5 xs:gap-1 justify-center">
          {pageNumbers.map((number: number, index: number) => (
            <li
              key={index}
              className={`group ${number === currentPage ? 'active' : ''}`}
            >
              {number === -1 ? (
                <span className="w-3 h-7 xs:h-10 md:w-6 flex items-center justify-center">
                  &hellip;
                </span>
              ) : (
                <button
                  onClick={() => {
                    handlePageChange(number);
                  }}
                  className={getPaginationItemBtnClasses(number)}
                  aria-current={currentPage === number ? 'page' : undefined}
                  aria-label={`${t('pagination.go_to_page')} ${number}`}
                  disabled={
                    currentPage === number ||
                    navigationState === 'loading' ||
                    isLoading
                  }
                >
                  {number}
                </button>
              )}
            </li>
          ))}
        </div>
        {nextButton}
      </ul>
    </nav>
  );
};

export default Pagination;
