import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { getPageTitle } from '../../../utils';

interface MetaProps {
  title?: string;
}

const Meta: React.FC<MetaProps> = ({ title }) => {
  const { t } = useTranslation();
  const pageTitle = title ? getPageTitle(title) : t('website.name');

  return (
    <Helmet>
      <title>{pageTitle}</title>
    </Helmet>
  );
};

export default Meta;
