import { useTranslation } from 'react-i18next';
import { EMAIL_REGEX, PASSWORD_REGEX } from '../constants';

/**
 * Hook to get the current validation rules based on the current language.
 * This ensures that validation rules are reactive to language changes.
 */
export const useValidationRules = () => {
  const { t } = useTranslation(); // Use useTranslation hook to get the `t` function

  const REQUIRED_VALIDATION_RULE = {
    required: t('validation.required'),
  };

  const NUMBERS_VALIDATION_RULES = {
    pattern: {
      value: /^[0-9]+$/,
      message: t('validation.numbers_only'),
    },
  };

  const LETTERS_VALIDATION_RULES = {
    pattern: {
      value: /^([A-Za-zČĆŽŠĐLjNjDžčćžšđljnjdžЉЊЏЂЖША-Яа-яёЁ0-9 ]*)$/,
      message: t('validation.letters_only'),
    },
  };

  const TITLE_VALIDATION_RULE = {
    ...REQUIRED_VALIDATION_RULE,
    pattern: {
      value: /^[A-Za-zА-Яа-яЂђЖжЉљЊњЏџЧчЋћШшĐđŽžČчĆćŠш0-9\s-]*$/,
      message: t('validation.title_pattern'),
    },
  };

  const LETTERS_AND_NUMBERS_VALIDATION_RULES = {
    pattern: {
      value: /^[A-Za-zА-Яа-яЂђЖжЉљЊњЏџЧчЋћШшĐđŽžČчĆćШш0-9]*$/,
      message: t('validation.letters_and_numbers_only'),
    },
  };

  const EMAIL_VALIDATION_RULES = {
    ...REQUIRED_VALIDATION_RULE,
    pattern: {
      value: EMAIL_REGEX,
      message: t('validation.email_invalid'),
    },
  };

  const PASSWORD_VALIDATION_RULES = {
    ...REQUIRED_VALIDATION_RULE,
    minLength: {
      value: 12,
      message: t('validation.password_min_length'),
    },
    maxLength: {
      value: 50,
      message: t('validation.password_max_length'),
    },
    pattern: {
      value: PASSWORD_REGEX,
      message: t('validation.password_pattern'),
    },
  };

  const BIN_VALIDATION_RULES = {
    ...NUMBERS_VALIDATION_RULES,
    maxLength: {
      value: 8,
      message: t('validation.bin_max_length'),
    },
  };

  const ACQUIRER_ID_VALIDATION_RULES = {
    ...REQUIRED_VALIDATION_RULE,
    ...NUMBERS_VALIDATION_RULES,
    maxLength: {
      value: 6,
      message: t('validation.acquirer_id_max_length'),
    },
  };

  const MID_VALIDATION_RULES = {
    ...LETTERS_AND_NUMBERS_VALIDATION_RULES,
    maxLength: {
      value: 20,
      message: t('validation.mid_max_length'),
    },
  };

  const TID_VALIDATION_RULES = {
    ...LETTERS_AND_NUMBERS_VALIDATION_RULES,
    maxLength: {
      value: 10,
      message: t('validation.tid_max_length'),
    },
  };

  const POSTAL_CODE_VALIDATION_RULES = {
    pattern: {
      value: /^\d{5}(-\d{4})?$/,
      message: t('validation.postal_code_invalid'),
    },
  };

  const DOMAIN_VALIDATION_RULES = {
    ...REQUIRED_VALIDATION_RULE,
    pattern: {
      value: /^(https?:\/\/)?([a-zA-Z0-9.-]{2,}\.[a-zA-Z]{2,})$/,
      message: t('validation.domain_invalid'),
    },
  };

  const PHONE_VALIDATION_RULES = {
    ...REQUIRED_VALIDATION_RULE,
    pattern: {
      value: /^(\+?\d{8,15}|(0|00)?\d{8,15})$/,
      message: t('validation.phone_invalid'),
    },
  };

  const MOBILE_PHONE_VALIDATION_RULES = {
    ...REQUIRED_VALIDATION_RULE,
    pattern: {
      value: /^\+?\d{8,15}$/,
      message: t('validation.mobile_phone_invalid'),
    },
  };

  const FAX_VALIDATION_RULES = {
    ...REQUIRED_VALIDATION_RULE,
    pattern: {
      value: /^[0-9+()-]{7,20}$/,
      message: t('validation.fax_invalid'),
    },
  };

  // Adding validation for Date (DD/MM/YYYY format)
  const DATE_FORMAT_VALIDATION_RULE = {
    required: t('validation.required'),
    pattern: {
      value: /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/, // Ensure DD/MM/YYYY with / as separator
      message: t('validation.date_format_invalid'),
    },
  };

  const regionRoleValidationRules = {
    regionId: {
      required: t('validation.required'),
    },
    roleIds: {
      validate: (value: any) => {
        if (!value || value.length === 0) {
          return t('validation.required'); // Custom error message
        }
        return true;
      },
    },
  };

  // Return all validation rules
  return {
    REQUIRED_VALIDATION_RULE,
    NUMBERS_VALIDATION_RULES,
    LETTERS_VALIDATION_RULES,
    TITLE_VALIDATION_RULE,
    LETTERS_AND_NUMBERS_VALIDATION_RULES,
    EMAIL_VALIDATION_RULES,
    PASSWORD_VALIDATION_RULES,
    BIN_VALIDATION_RULES,
    ACQUIRER_ID_VALIDATION_RULES,
    MID_VALIDATION_RULES,
    TID_VALIDATION_RULES,
    POSTAL_CODE_VALIDATION_RULES,
    DOMAIN_VALIDATION_RULES,
    PHONE_VALIDATION_RULES,
    MOBILE_PHONE_VALIDATION_RULES,
    FAX_VALIDATION_RULES,
    DATE_FORMAT_VALIDATION_RULE,
    regionRoleValidationRules,
  };
};
