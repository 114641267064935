import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Button from '../../components/shared/Button';
import EducationDocumentEntryForm from '../../modules/education/UI/EducationDocumentEntryForm';
import Meta from '../../components/layout/Meta';
import PageLayout from '../../layouts/PageLayout';

import { EDUCATION_PATH } from '../../constants';
import { PermissionsEnum } from '../../types';
import { usePermission } from '../../hooks/usePermission';
import { useRedirect } from '../../hooks/useRedirect';

function EducationDocumentEntryPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { slug, id } = useParams();

  const pageTitle = id
    ? t('documents.edit_document')
    : t('documents.document_entry');

  const currentType = id ? slug : location?.state?.currentType;

  /* Permissions */
  const canViewDocuments = usePermission([PermissionsEnum.DocumentData]);
  const canCreateDocuments = usePermission([PermissionsEnum.DocumentsCreate]);
  const canEditDocument = usePermission([PermissionsEnum.DocumentUpdate]);

  const shouldRedirect =
    (!id && canCreateDocuments === false) ||
    (id && canEditDocument === false) ||
    false;

  useRedirect({
    redirect: shouldRedirect,
    message:
      !id && canCreateDocuments === false
        ? t('documents.no_permission_for_document_creation')
        : t('documents.no_permission_for_document_update'),
    path: `${EDUCATION_PATH}/${currentType}`,
  });

  return (
    <>
      <p
        onClick={() => navigate(`${EDUCATION_PATH}/${currentType}`)}
        className="mb-8 flex gap-2 items-center w-fit justify-center b sm:flex-none focus:outline-none group"
      >
        <Button type="button" variant="back" tabIndex={-1}>
          {t('global.back_to_list')}
        </Button>
      </p>
      <Meta title={pageTitle} />
      <PageLayout title={pageTitle}>
        {(!id || (id && canViewDocuments)) && (
          <EducationDocumentEntryForm currentType={currentType} />
        )}
      </PageLayout>
    </>
  );
}

export default EducationDocumentEntryPage;
