import { useTranslation } from 'react-i18next';
import {
  Link,
  LoaderFunctionArgs,
  useLoaderData,
  useNavigate,
  useParams,
} from 'react-router-dom';
import {
  useForm,
  FormProvider,
  SubmitHandler,
  FieldValues,
} from 'react-hook-form';

import Button from '../../../components/shared/Button';
import DropDownSelect from '../../../components/shared/DropdownSelect/DropDownSelectWithIndicators';
import FileInput, {
  ALLOWED_IMAGE_TYPES,
  FileTypeEnum,
} from '../../../components/shared/FileInput';
import Input from '../../../components/shared/Input';
import TextEditor from '../../../components/shared/TextEditor';
import TransitionLoader from '../../../components/shared/TransitionLoader/TransitionLoader';

import queryClient from '../../../config/tanstackQueryConfig';
import { NEWS_PATH } from '../../../constants';
import { useRegions } from '../../../context/regions-context';
import { useRedirect } from '../../../hooks/useRedirect';
import { PermissionsEnum } from '../../../types';
import {
  decodeHtmlEntities,
  extractMultipleErrors,
  formatDateWithMinus,
  hasNestedObjects,
  hasPermission,
  showToast,
} from '../../../utils';

import NewsStore, {
  getNewsPhotoQueryOptions,
} from '../../news/services/news.store';
import { useValidationRules } from '../../../hooks/useValidation';
const newsStore = new NewsStore();

const today = new Date();

const NewsForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { availableRegionsDropdownItems } = useRegions();
  const { REQUIRED_VALIDATION_RULE } = useValidationRules();

  const loaderData = useLoaderData() as any;

  /* Permissions */
  const canStoreType = loaderData
    ? [PermissionsEnum.NewsEdit]
    : [PermissionsEnum.NewsCreate];
  const canStoreNews = hasPermission(canStoreType);
  const canViewPhoto = loaderData?.mainPhoto ? true : false;

  useRedirect({
    redirect: id !== undefined && loaderData === null && canStoreNews,
    path: NEWS_PATH,
    message: t('news.no_requested_news_in_region'),
  });

  const methods = useForm<FieldValues>({
    defaultValues: {
      title: loaderData?.title || '',
      headline: loaderData?.headline || '',
      description: loaderData?.description
        ? decodeHtmlEntities(loaderData.description)
        : '',
      mainPhoto: loaderData?.mainPhoto?.imageFile || '',
      publicationDate: formatDateWithMinus(today),
      regionId: loaderData?.regionId || [],
    },
  });

  const {
    handleSubmit,
    reset,
    setError,
    formState: { isSubmitting },
  } = methods;

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    if (!canStoreNews) return;

    let formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    Object.keys(data).forEach((key) => {
      if (!['regionId'].includes(key)) {
        formData.append(key, data[key]);
      }
    });

    if (data.regionId) {
      data.regionId.forEach((regionId: number) => {
        formData.append('regionIds[]', regionId.toString());
      });
    }

    const handleError = (message: any) => {
      if (hasNestedObjects(message)) {
        const errorMessages = extractMultipleErrors(message);
        for (const key in errorMessages) {
          setError(key as any, {
            type: 'backend',
            message: errorMessages[key],
          });
        }
      } else {
        showToast(message, false, true);
      }
    };

    const response = await newsStore.storeNews({
      data: formData,
      id: loaderData?.id,
    });
    const { success, message } = response;

    if (success) {
      showToast(message, success);
      navigate(NEWS_PATH);
    } else {
      handleError(message);
    }
    reset({}, { keepValues: true });
  };

  return (
    <div className="max-w-[500px]">
      {isSubmitting && <TransitionLoader message={t('state.creating')} />}

      <FormProvider {...methods}>
        <form className="pks-layout-col-md" onSubmit={handleSubmit(onSubmit)}>
          <div className="pks-layout-col-md">
            {/* Region and Doc Type */}
            <div className="pks-layout-col">
              {/* Region DropDown */}
              <DropDownSelect
                id="regionId"
                name="regionId"
                label={`${t('regions.region')}*`}
                placeholder={t('regions.choose_region')}
                options={availableRegionsDropdownItems}
                validationRules={REQUIRED_VALIDATION_RULE}
                isDisabled={!canStoreNews}
                hasLock={!canStoreNews}
                multiple
              />
              <Input
                id="title"
                name="title"
                label={`${t('inputs.news_title.label')}*`}
                placeholder={t('inputs.news_title.placeholder')}
                autoComplete="off"
                validationRules={REQUIRED_VALIDATION_RULE}
                disabled={!canStoreNews}
                hasLock={!canStoreNews}
              />
              <Input
                id="headline"
                name="headline"
                label={`${t('inputs.news_subtitle.label')}*`}
                placeholder={t('inputs.news_subtitle.placeholder')}
                autoComplete="off"
                validationRules={REQUIRED_VALIDATION_RULE}
                disabled={!canStoreNews}
                hasLock={!canStoreNews}
              />
            </div>
            {/* Docs File Input */}
            <div className="pks-layout-col">
              <FileInput
                id="mainPhoto"
                required
                name="mainPhoto"
                label={`${t('image.add_main_image')}:*`}
                allowedTypes={ALLOWED_IMAGE_TYPES}
                maxSizeMB={16}
                type={FileTypeEnum.Image}
                disabled={!canStoreNews}
                hasLock={!canStoreNews}
                showFiles={canViewPhoto}
              />
              <p className="text-sm">{t('image.note_image_size')}</p>
            </div>

            {/* Description */}
            <TextEditor
              label={t('global.description')}
              value={methods.watch('description')}
              onChange={(value) => methods.setValue('description', value)}
              disabled={!canStoreNews}
              hasLock={!canStoreNews}
            />
          </div>

          {/* Submit Button and Actions */}
          <div className="w-full inline-flex gap-4 flex-wrap sm:flex-nowrap">
            {canStoreNews && (
              <Button
                type="submit"
                className="flex-grow"
                wide
                disabled={isSubmitting}
              >
                {isSubmitting
                  ? t('state.submitting')
                  : loaderData?.id
                  ? t('news.edit_news')
                  : t('news.enter_new_news')}
              </Button>
            )}
            <Link
              to={NEWS_PATH}
              className="flex-auto sm:flex-none focus:outline-none group"
            >
              <Button type="button" variant="secondary" wide tabIndex={-1}>
                {t('global.cancel')}
              </Button>
            </Link>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default NewsForm;

export async function loader({
  params,
}: LoaderFunctionArgs): Promise<any | null> {
  const { id } = params;

  if (!id) return null;

  if (!hasPermission([PermissionsEnum.NewsData])) {
    // If the user doesn't have permission, return null and don't fetch data
    return null;
  }

  const response = await newsStore.getSingleNews(+id);
  const { success, data } = response;
  // preload file and preselect in file input

  if (success && data) {
    const regions =
      data.regions?.map((region: { id: number; name: string }) => region.id) ||
      [];

    const shouldLoadNewsImage = hasPermission([PermissionsEnum.NewsPhoto]);
    const responseNewsImage = await queryClient.fetchQuery(
      getNewsPhotoQueryOptions({
        id: Number(id),
        enabled: shouldLoadNewsImage,
      })
    );

    const { success: fileSuccess } = responseNewsImage;
    const mainPhoto =
      shouldLoadNewsImage && fileSuccess ? responseNewsImage?.data : '';

    const loaderData = {
      id: data.id,
      title: data.title,
      headline: data.headline,
      description: data.description,
      mainPhoto: mainPhoto,
      publicationDate: formatDateWithMinus(new Date()),
      regionId: regions,
    };

    return loaderData;
  }

  return null; // Return null if parameters are missing
}
