import { useState, useEffect, useRef } from 'react';
import { Controller, RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/react';

import Icon from '../Icon';
import InfoMessage from '../InfoMessage';
import SearchInput from '../../../modules/search/UI/SearchInput';

import { useDebounce } from '../../../hooks/useDebounce';
import useInputContext from '../../../hooks/useInputContext';
import { getListboxButtonClassName, handleListboxButtonKeyDown } from './utils';
import { DropDownItemInterface } from '../../../types';

interface DropDownInputProps {
  id: string | number;
  name: string;
  options: DropDownItemInterface[];
  label?: string;
  defaultValue?: any;
  placeholder?: string;
  validationRules?: RegisterOptions;
  multiple?: boolean; // New prop for multiple selection
  isLoading?: boolean;
  isDisabled?: boolean;
}

const DropDownSelectWithSearch: React.FC<DropDownInputProps> = ({
  id,
  name,
  options,
  defaultValue,
  label = 'Izaberi opciju',
  placeholder = 'Izaberi opciju',
  validationRules,
  multiple = false,
  isLoading = false,
  isDisabled,
}) => {
  const { t } = useTranslation();
  const { control, error, isSubmitted, setValue } = useInputContext(name);

  const listboxButtonRef = useRef<HTMLButtonElement | null>(null);
  const listboxButtonState = error
    ? 'error'
    : isSubmitted
    ? 'submitted'
    : 'initial';

  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(
    options.filter((option) => !option.disabled)
  );

  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  // Keep the original type of the option id while handling the DOM as string
  const getOptionById = (id: string | number) =>
    options.find(
      (option) => option.id === id || option.id.toString() === id.toString()
    );

  const normalizeValue = (value: any) => {
    if (multiple) {
      return Array.isArray(value)
        ? value.map((item) => (typeof item === 'object' ? item.id : item))
        : [];
    } else {
      return typeof value === 'object' ? value?.id : value || '';
    }
  };

  const getSelectedOptionsLabel = (selected: any) => {
    if (Array.isArray(selected) && multiple) {
      const selectedLabels = selected
        .map((id: string | number) => {
          const option = getOptionById(id);
          return option ? option.label : '';
        })
        .filter(Boolean)
        .join(', ');
      return selectedLabels || placeholder;
    } else if (selected !== '') {
      const selectedOption = getOptionById(selected);
      return selectedOption ? selectedOption.label : placeholder;
    }
    return placeholder;
  };

  const handleLabelClick = () => listboxButtonRef.current?.click();

  // Utility to bold matching part of the label
  const highlightMatch = (text: string, searchTerm: string) => {
    if (!searchTerm) return text;
    const regex = new RegExp(`(${searchTerm})`, 'gi');
    const parts = text.split(regex);
    return parts.map((part, index) =>
      part.toLowerCase() === searchTerm.toLowerCase() ? (
        <strong key={index} className="font-bold">
          {part}
        </strong>
      ) : (
        part
      )
    );
  };

  // Use debounced search term for filtering
  useEffect(() => {
    const lowerTerm = debouncedSearchTerm.toLowerCase();
    const filtered = options.filter(
      (option) =>
        !option.disabled && option.label.toLowerCase().includes(lowerTerm)
    );
    setFilteredOptions(filtered);
  }, [debouncedSearchTerm, options]);

  useEffect(() => {
    if (defaultValue) {
      const normalizedDefaultValue = normalizeValue(defaultValue);
      setValue(name, normalizedDefaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, control, name]);

  return (
    <div className="w-full">
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={validationRules || undefined}
        render={({ field: { onChange, onBlur, value } }) => (
          <Listbox
            value={normalizeValue(value)}
            onChange={(newValue) => onChange(newValue)}
            multiple={multiple}
          >
            {({ open }) => {
              if (open) {
                // Polling mechanism to ensure the input exists before focusing
                const focusInterval = setInterval(() => {
                  if (searchInputRef.current) {
                    searchInputRef.current.focus();
                    clearInterval(focusInterval); // Stop polling once the input is focused
                  }
                }, 10); // Check every 10ms
              }
              return (
                <>
                  <Label
                    onClick={handleLabelClick}
                    className="block mb-1 hover:cursor-pointer"
                  >
                    {label}
                  </Label>
                  <div className="relative">
                    <ListboxButton
                      tabIndex={0}
                      ref={listboxButtonRef}
                      id={id.toString()}
                      //onBlur={onBlur}
                      disabled={isLoading || isDisabled}
                      onKeyDown={(e) => {
                        handleListboxButtonKeyDown(e, listboxButtonRef);
                      }}
                      className={getListboxButtonClassName({
                        open: open,
                        state: listboxButtonState,
                        isDisabled: isDisabled,
                      })}
                    >
                      <span
                        className={`block truncate pr-6 ${
                          isDisabled
                            ? 'text-secondary-300'
                            : !value || value.length === 0
                            ? 'text-secondary-300'
                            : ''
                        }`}
                      >
                        {getSelectedOptionsLabel(normalizeValue(value))}
                      </span>

                      {!isDisabled && (
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4">
                          {isLoading ? (
                            <Icon
                              name="searchSpinner"
                              className="absolute top-0 right-4 h-full flex items-center [&>svg]:h-5 [&>svg]:w-5 animate-spin"
                            />
                          ) : (
                            <Icon
                              name="chevronDown"
                              className={`transition-transform ${
                                open ? 'rotate-180' : ''
                              }`}
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      )}
                    </ListboxButton>
                    <ListboxOptions
                      modal={false}
                      transition
                      className="absolute z-10 overflow-auto w-full max-h-60 mt-2 rounded-lg bg-white border border-secondary-300 focus:outline-none"
                    >
                      <div className="px-4 mb-1.5 z-10 pt-2 bg-white sticky top-0">
                        <SearchInput
                          ref={searchInputRef}
                          value={searchTerm}
                          isFetching={isLoading}
                          clearIconClassName="search-input-clear"
                          onSearchTermChange={setSearchTerm}
                        />
                        {filteredOptions.length === 0 && searchTerm !== '' && (
                          <div className="mt-2">
                            <InfoMessage
                              icon="danger"
                              message={t('state.no_hits_search')}
                            />
                          </div>
                        )}
                      </div>
                      {filteredOptions.map((option, i) => (
                        <ListboxOption
                          key={`${option.id}-${i}`}
                          id={option.id.toString()}
                          value={option.id}
                          className="group mb-1.5 relative select-none py-1.5 px-4 data-[focus]:bg-secondary-100 cursor-pointer"
                        >
                          <span className="block truncate font-normal">
                            {highlightMatch(option.label, searchTerm)}
                          </span>
                        </ListboxOption>
                      ))}
                    </ListboxOptions>
                  </div>
                </>
              );
            }}
          </Listbox>
        )}
      />
      {error && <div className="text-danger">{error.message}</div>}
    </div>
  );
};

export default DropDownSelectWithSearch;
