import NewsPreview from '../../modules/news/UI/Preview';
import { usePermission } from '../../hooks/usePermission';
import { PermissionsEnum, ViewEnum } from '../../types';

const NewsSidebar = () => {
  const canListNews = usePermission([PermissionsEnum.NewsList]);

  return (
    <>
      {canListNews && (
        <div className="pks-layout-col-xl">
          <NewsPreview view={ViewEnum.Detailed} />
        </div>
      )}
    </>
  );
};

export default NewsSidebar;
