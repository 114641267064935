export const EDUCATION_HEADINGS = [
  {
    id: 'regular_sessions',
    title: 'education.regular_forum_sessions',
  },
  {
    id: 'regional_sessions',
    title: 'education.regional_forum_sessions',
  },
  {
    id: 'conferences',
    title: 'education.forum_participation_conferences',
  },
  {
    id: 'seminars_by_forum',
    title: 'education.forum_organized_seminars',
  },
  {
    id: 'seminars_participation',
    title: 'education.forum_participation_seminars',
  },
  {
    id: 'education',
    title: 'education.forum_organized_trainings',
  },
  {
    id: 'east',
    title: 'education.east',
  },
  {
    id: 'laws',
    title: 'education.laws_and_regulations',
  },
];
