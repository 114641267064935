import { useTranslation } from 'react-i18next';

import Icon from '../Icon';

import { TableCellInterface } from '../../../types';
import { Link } from 'react-router-dom';

interface TableCellProps {
  cell: TableCellInterface;
}

const TableCell: React.FC<TableCellProps> = ({ cell }) => {
  const { t } = useTranslation();
  const renderCellContent = (cell: TableCellInterface) => {
    const { content, type, className, link, isLoadingFile, onClick, ...rest } =
      cell;

    const handleClick = (...args: any[]) => {
      if (!isLoadingFile && onClick) {
        onClick(...args); // Call onClick if not loading
      }
    };

    switch (type) {
      case 'doc':
        return (
          <span
            className={`relative group h-full ${className || ''}`.trim()}
            onClick={handleClick}
          >
            <Icon name="file" />
            {!isLoadingFile && (
              <p className="absolute z-10 top-full whitespace-nowrap bg-gray-500 rounded-md px-2 text-white text-sm hidden group-hover:inline-block">
                {t('files.open_file')}
              </p>
            )}
          </span>
        );
      case 'edit':
        return (
          <div className={`relative group h-full" ${className || ''}`.trim()}>
            <Link
              to={link || '/'}
              className="block transform scale-125"
              onClick={(e) => e.stopPropagation()}
              {...rest}
            >
              <Icon className="hover:text-primary-300" name="pencil" />
            </Link>
          </div>
        );
      case 'delete':
        return (
          <span
            className={`relative group h-full ${className || ''}`.trim()}
            onClick={handleClick}
          >
            <Icon name="trash" />
          </span>
        );
      case 'heading':
        return (
          <span
            className={`block min-w-[300px] sm:min-w-auto lg:max-w-full ${
              className || ''
            }`.trim()}
          >
            {content}
          </span>
        );
      case 'truncate':
        return (
          <div
            className={`block truncate min-w-[300px] max-w-[300px] text-secondary-400 sm:min-w-auto lg:max-w-full ${
              className || ''
            }`.trim()}
          >
            {content}
          </div>
        );
      case 'custom':
        return (
          <div
            className={`block text-secondary-400 ${
              className ? className : ''
            }`.trim()}
          >
            {content}
          </div>
        );
      default:
        return <span className={`block text-secondary-400`}>{content}</span>;
    }
  };

  return renderCellContent(cell);
};

export default TableCell;
