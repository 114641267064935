import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/react';

import Icon from '../Icon';

import { getListboxButtonClassName, handleListboxButtonKeyDown } from './utils';
import { DropDownItemInterface } from '../../../types';

interface DropDownProps {
  label?: string;
  options: DropDownItemInterface[];
  onChange: (value: string) => void;
  selectedOptionIndex?: number;
  multi?: boolean;
  isLoading?: boolean;
  onRemoveFilter?: () => void;
}

const DropDown: React.FC<DropDownProps> = ({
  label,
  options,
  selectedOptionIndex,
  multi = false,
  isLoading = false,
  onRemoveFilter,
  onChange,
}) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<any>(() => {
    if (
      selectedOptionIndex !== undefined &&
      selectedOptionIndex < options.length
    ) {
      return multi
        ? [options[selectedOptionIndex].id]
        : options[selectedOptionIndex].id;
    }
    return multi ? [] : '';
  });

  const listboxButtonRef = useRef<HTMLButtonElement | null>(null);

  const isFirstOptionPlaceholder = multi
    ? options[0]?.index &&
      selected.length === 1 &&
      selected[0] === options[0]?.id
    : options[0]?.index && selected === options[0]?.id;

  const getOptionById = (id: string) =>
    options.find((option) => option.id === id);

  const getSelectedOptionsLabel = () => {
    if (multi && Array.isArray(selected)) {
      return selected.length > 0
        ? selected.map((id) => getOptionById(id)?.label).join(', ')
        : `${t('inputs.dropdown.label')}...`;
    } else if (!multi && typeof selected === 'string') {
      const selectedOption = getOptionById(selected);
      return selectedOption
        ? selectedOption.label
        : `${t('inputs.dropdown.label')}...`;
    }
    return `${t('inputs.dropdown.label')}...`;
  };

  const getSelectedOptionsImage = () => {
    const selectedOption = getOptionById(selected);
    return selectedOption ? selectedOption?.image || undefined : undefined;
  };

  useEffect(() => {
    if (
      selectedOptionIndex !== undefined &&
      selectedOptionIndex < options.length
    ) {
      setSelected(
        multi
          ? [options[selectedOptionIndex].id]
          : options[selectedOptionIndex].id
      );
    }
  }, [selectedOptionIndex, options, multi]);

  const handleLabelClick = () => {
    listboxButtonRef.current?.click();
  };

  const handleSelectionChange = (selectedType: string) => {
    setSelected(selectedType);
    onChange(selectedType);
  };

  return (
    <div className="w-full">
      <Listbox
        value={selected}
        onChange={handleSelectionChange}
        multiple={multi}
      >
        {({ open }) => {
          return (
            <>
              <Label
                onClick={handleLabelClick}
                className="block mb-1 hover:cursor-pointer"
              >
                {label || t('inputs.dropdown.label')}
              </Label>
              <div className="flex items-center justify-center  gap-4 ">
                <div className="relative w-full">
                  <ListboxButton
                    ref={listboxButtonRef}
                    tabIndex={0}
                    disabled={isLoading}
                    onKeyDown={(e) =>
                      handleListboxButtonKeyDown(e, listboxButtonRef)
                    }
                    className={getListboxButtonClassName({
                      open,
                    })}
                  >
                    <span
                      className={`block truncate pr-6 ${
                        isFirstOptionPlaceholder || !selected.length
                          ? 'text-secondary-300'
                          : ''
                      }`}
                    >
                      <div className="flex gap-2 items-center">
                        {!multi &&
                          typeof selected === 'string' &&
                          getSelectedOptionsImage() && (
                            <img
                              src={getSelectedOptionsImage()}
                              alt={getSelectedOptionsLabel()}
                            />
                          )}
                        {getSelectedOptionsLabel()}
                      </div>
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4">
                      {isLoading ? (
                        <Icon
                          name="searchSpinner"
                          className="absolute top-0 right-4 h-full flex items-center [&>svg]:h-5 [&>svg]:w-5 animate-spin"
                        />
                      ) : (
                        <Icon
                          name={'chevronDown'}
                          className={`transition-transform ${
                            open ? 'rotate-180' : ''
                          }`}
                          aria-hidden="true"
                        />
                      )}
                    </span>
                  </ListboxButton>
                  <ListboxOptions
                    modal={false}
                    transition
                    className="absolute z-10 overflow-auto w-full max-h-60 mt-2 pt-2 rounded-lg bg-white border border-secondary-300 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-150 data-[leave]:ease-in"
                  >
                    {options.map((option) => (
                      <ListboxOption
                        key={option.id}
                        value={option.id}
                        className="group mb-1.5 relative select-none py-1.5 px-4 data-[focus]:bg-secondary-100 cursor-pointer"
                      >
                        <div className="flex gap-2 items-center">
                          {option?.image && (
                            <img src={option.image} alt={option.label}></img>
                          )}
                          <span className="block truncate font-normal">
                            {option.label}
                          </span>
                        </div>
                      </ListboxOption>
                    ))}
                  </ListboxOptions>
                </div>
                {selected !== 'all' && onRemoveFilter && (
                  <span
                    className="size-4 cursor-pointer"
                    onClick={onRemoveFilter}
                  >
                    <Icon name="cross" />
                  </span>
                )}
              </div>
            </>
          );
        }}
      </Listbox>
    </div>
  );
};

export default DropDown;
