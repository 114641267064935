import { useTranslation } from 'react-i18next';

const ForumMemberPreview = ({ forumMember }: any) => {
  const { t } = useTranslation();

  const forumMemberItemClassName = 'h3 font-normal flex flex-col gap-1 ';
  const emptyPropText = '/';

  const { allRegions, regions } = forumMember['regions_list'];

  const regionsContent = allRegions ? (
    <li className={forumMemberItemClassName}>
      <b>{t('regions.regions')}</b>
      <span>{t('regions.all_regions')}</span>
    </li>
  ) : regions.length > 0 ? (
    <li className={forumMemberItemClassName}>
      <b>{regions.length <= 1 ? t('regions.region') : t('regions.regions')}</b>
      <div className="flex flex-wrap gap-2">
        {regions.map((role: any, i: number) => (
          <p key={i} className="bg-primary-100 w-fit py-1 px-2 rounded-lg">
            {role.name}
          </p>
        ))}
      </div>
    </li>
  ) : (
    <li className={forumMemberItemClassName}>
      <b>{t('regions.region')}</b> {emptyPropText}
    </li>
  );

  return (
    <ul className="space-y-3">
      <li className={forumMemberItemClassName}>
        <b>{t('institutions.institution')}</b> {forumMember?.organization?.name}
      </li>
      <li className={forumMemberItemClassName}>
        <b>{t('inputs.name.label')}</b> {forumMember.first_name}
      </li>
      <li className={forumMemberItemClassName}>
        <b>{t('inputs.surname.label')}</b> {forumMember.last_name}
      </li>
      <li className={forumMemberItemClassName}>
        <b>{t('inputs.email.label')}</b> {forumMember.email}
      </li>
      <li className={forumMemberItemClassName}>
        <b>
          {forumMember.roles.length === 1 ? t('roles.role') : t('roles.roles')}
        </b>
        <div className="flex flex-wrap gap-2">
          {forumMember.roles.map((role: any, i: number) => (
            <p key={i} className="bg-secondary-100 w-fit py-1 px-2 rounded-lg">
              {role.name}
            </p>
          ))}
        </div>
      </li>
      {regionsContent}
      <li className={forumMemberItemClassName}>
        <b>{t('inputs.phone.label')}</b> {forumMember.phone || emptyPropText}
      </li>
      <li className={forumMemberItemClassName}>
        <b>{t('inputs.mobile_phone.label')}</b>{' '}
        {forumMember.mobile_phone || emptyPropText}
      </li>
      <li className={forumMemberItemClassName}>
        <b>{t('inputs.function.label')}</b>{' '}
        {forumMember.organization_function || emptyPropText}
      </li>
    </ul>
  );
};

export default ForumMemberPreview;
