import { Outlet } from 'react-router-dom';
import { ScrollRestoration } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { LanguageProvider } from '../../context/language-context';

/**
 * MainLayout component that wraps children components with additional features.
 *
 * This layout includes:
 * - `ToastContainer` for displaying toast notifications.
 * - `ScrollRestoration` to manage scroll position on route changes.
 *
 * @returns {React.JSX.Element} The rendered layout component with toast notifications and scroll restoration.
 */
const MainLayout = (): React.JSX.Element => {
  return (
    <LanguageProvider>
      {/* Preload logo */}
      <div className="pks-logo-preload"></div>

      {/* Render the child components */}
      <Outlet />

      {/* ToastContainer for displaying toast notifications */}
      <ToastContainer position="bottom-right" />

      {/* ScrollRestoration to manage scroll position on route changes */}
      <ScrollRestoration />
    </LanguageProvider>
  );
};

export default MainLayout;
