import { useTranslation } from 'react-i18next';

import Button from '../../../components/shared/Button';
import FraudCasesGenerationForm from './FraudCasesGenerationForm';

import { useModal } from '../../../context/modal-context';

const FraudCasesGenerationButton = () => {
  const { t } = useTranslation();

  const { openModal, closeModal } = useModal();

  const handleOpenModal = (e: any) => {
    e.stopPropagation();
    openModal(
      <FraudCasesGenerationForm onClose={closeModal} />,
      t('fraud_cases.generating_report')
    );
  };

  return (
    <div>
      <Button wide onClick={handleOpenModal}>
        {t('fraud_cases.generate_report')}
      </Button>
    </div>
  );
};

export default FraudCasesGenerationButton;
