import React, { forwardRef, ForwardedRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '../../../components/shared/Icon';
import Button from '../../../components/shared/Button';

// Define the props type
interface SearchInputProps {
  value: string;
  isFetching?: boolean;
  clearIconClassName?: string;
  onSearchTermChange: (term: string) => void; // New prop for callback
  onSubmit?: () => void;
}

// Forward ref with proper types
const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  (
    {
      value,
      isFetching,
      clearIconClassName = '',
      onSearchTermChange,
      onSubmit,
    },
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const { t } = useTranslation();
    const showCloseBtn = value.length > 0;
    const hasSubmitBtn = clearIconClassName ? false : true;

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === ' ' || e.key === 'Spacebar') {
        e.stopPropagation(); // Prevent the event from reaching the Listbox
      }
    };

    /**
     * Updates the search term based on user input.
     * @param {React.ChangeEvent<HTMLInputElement>} e - The input change event.
     */
    const handleSearchInputChange = (
      e: React.ChangeEvent<HTMLInputElement>
    ) => {
      e.stopPropagation();
      onSearchTermChange(e.target.value);
    };

    const handleSearchInputClear = () => {
      onSearchTermChange('');
      if (ref && typeof ref !== 'function') {
        ref.current?.focus();
      }
    };

    useEffect(() => {
      onSearchTermChange?.(value);
    }, [value, onSearchTermChange]);

    const handleSubmit = (e: any) => {
      e.preventDefault();
      onSubmit?.();
    };

    const Wrapper = onSubmit ? 'form' : 'div'; // Choose between form and div

    return (
      <Wrapper
        onSubmit={onSubmit ? handleSubmit : undefined}
        className="flex gap-2"
      >
        <div className="relative flex-1">
          <Icon
            name="search"
            className="absolute w-5 h-100 transform top-1/2 -translate-y-1/2 ml-4"
          />

          <input
            ref={ref}
            type="text"
            placeholder={`${t('search.placeholder_search')}...`}
            className="pks-input pks-input-icon-left"
            value={value}
            onChange={handleSearchInputChange}
            onKeyDown={handleKeyDown}
          />

          {showCloseBtn && !isFetching && (
            <div
              onClick={handleSearchInputClear}
              className={`cursor-pointer absolute top-1 bottom-1 right-1 w-12 flex items-center justify-center bg-white ${clearIconClassName}`}
              style={{ boxShadow: '-2px 0 2px white' }}
            >
              <Icon name="cross" className="w-4 pointer-events-none" />
            </div>
          )}

          {isFetching && (
            <div
              className="absolute top-1 bottom-1 right-1 w-12 flex items-center justify-center bg-white"
              style={{ boxShadow: '-2px 0 2px white' }}
            >
              <Icon name="searchSpinner" className="w-6 animate-spin" />
            </div>
          )}
        </div>
        {hasSubmitBtn && (
          <Button type="submit">{t('search.placeholder_search')}</Button>
        )}
      </Wrapper>
    );
  }
);

export default SearchInput;
