import { UploadFileInterface, UploadPercentage } from '../../../types';
import http from '../../../utils/http';

import { FraudCaseReportEnum } from './fraudCases.types';

class FraudCasesApi {
  // Get Fraud Cases
  async getFraudCases(page?: number, category?: string, organization?: any) {
    const params = new URLSearchParams();

    if (page) {
      params.append('page', page.toString());
    }

    if (category) {
      params.append('typeId', category);
    }

    if (organization) {
      params.append('organizationIds[]', organization);
    }

    // Construct the full URL with the query string
    const url = `/fraudcases${
      params.toString() ? `?${params.toString()}` : ''
    }`;

    return http.get(url);
  }

  // Get Fraud Case
  async getFraudCase(id?: number) {
    return http.get(`/fraudcases/${id}`);
  }

  // Add Fraud Case
  async addFraudCase(
    data: FormData,
    onUpload?: (
      percentage: UploadPercentage,
      fileNames?: UploadFileInterface[]
    ) => void
  ) {
    const files: UploadFileInterface[] = [];

    // Extract documents from FormData
    const documents = data.getAll('documents[]') as File[];

    // Process each document
    documents.forEach((file: File) => {
      const sizeInMB = file.size / 1024 / 1024;
      files.push({
        name: file.name,
        size: sizeInMB,
      });
    });

    if (files.length === 0 || !onUpload) {
      return http.post(`/fraudcases`, data);
    }

    let filesAdded = false;

    return http.post(`/fraudcases`, data, {
      onUploadProgress: (progressEvent) => {
        if (
          typeof progressEvent.loaded !== 'undefined' &&
          typeof progressEvent.total !== 'undefined'
        ) {
          if (!filesAdded) {
            filesAdded = true;
            onUpload?.(0, [...files]);
          }

          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          onUpload?.(percentCompleted, files);
        } else {
          onUpload?.(null, []);
        }
      },
    });
  }

  // Add Fraud Case
  async editFraudCase(id: number, data: any) {
    return http.post(`/fraudcases/${id}`, data);
  }

  // Get Fraud Case Comments
  async getFraudCaseComments(
    id?: number,
    page?: number,
    rowsPerPage: number = 5
  ) {
    const params = new URLSearchParams();

    if (page) {
      params.append('page', page.toString());
    }

    params.append('rowsPerPage', rowsPerPage.toString());

    const url = `/fraudcases/${id}/comments${
      params.toString() ? `?${params.toString()}` : ''
    }`;

    return http.get(url);
  }

  // Add Fraud Case Comment
  async addFraudCaseComment(id: number, data: any) {
    return http.post(`/fraudcases/${id}/comments`, data);
  }

  // Delete Fraud Case Comment
  async deleteFraudCaseComment(id: string) {
    return http.delete(`/fraudcases/comments/${id}`);
  }

  // Get Fraud Case Files
  async getFraudCaseFiles({ id, page }: { id: number; page?: number }) {
    const params = new URLSearchParams();

    if (page && page !== 1) {
      params.append('page', page.toString());
    }

    const url = `/fraudcases/${id}/files${
      params.toString() ? `?${params.toString()}` : ''
    }`;

    return http.get(url);
  }

  // Get Fraud Case Files
  async getFraudCaseFile(fileId: number) {
    return http.get(`/fraudcases/files/${fileId}`, { responseType: 'blob' });
  }

  //  Delete Fraud Case File
  async deleteFraudCaseFile(fileId: number) {
    return http.delete(`/fraudcases/files/${fileId}`);
  }

  // Add Fraud Case Files
  async addFraudCaseFiles(
    id: number,
    data: any,
    onUpload?: (
      percentage: UploadPercentage,
      files?: UploadFileInterface[]
    ) => void
  ) {
    const files: UploadFileInterface[] = [];

    data?.documents.forEach((value: File) => {
      const sizeInMB = value.size / 1024 / 1024;

      if (value instanceof File) {
        files.push({
          name: value.name,
          size: sizeInMB,
        });
      }
    });

    let filesAdded = false;

    return http.post(`/fraudcases/${id}/files`, data, {
      onUploadProgress: (progressEvent) => {
        if (
          typeof progressEvent.loaded !== 'undefined' &&
          typeof progressEvent.total !== 'undefined'
        ) {
          if (!filesAdded) {
            filesAdded = true;
            onUpload?.(0, [...files]);
          }

          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          onUpload?.(percentCompleted, files);
        } else {
          onUpload?.(null, []);
        }
      },
    });
  }

  // Get Fraud Cases Types
  async getFraudCasesTypes() {
    return http.get('/fraudcases/types');
  }

  // Get Fraud Cases Card Types
  async getFraudCasesCardTypes() {
    return http.get('/fraudcases/cardtypes');
  }

  // Get Fraud Cases Terminal Types
  async getFraudCasesTerminalTypes() {
    return http.get('/fraudcases/terminaltypes');
  }

  // Get Fraud Case Report
  async getFraudCaseReports(payload: FormData) {
    const params = new URLSearchParams();

    payload.forEach((value, key) => {
      params.append(key, value.toString());
    });

    return http.get(`/fraudcases/reportperregion`, {
      responseType: 'blob',
      params, // Pass the FormData as query parameters
    });
  }

  // Get Fraud Case Report
  async getFraudCaseReport({
    id,
    type,
  }: {
    id: string;
    type: FraudCaseReportEnum;
  }) {
    return http.get(`/fraudcases/${id}/report/${type}`, {
      responseType: 'blob',
    });
  }
}

export default FraudCasesApi;
