import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/shared/Button';
import DateRangePicker from '../../../components/shared/DateRangePicker';

import { useValidationRules } from '../../../hooks/useValidation';
import { formatToISOTYmd, showToast } from '../../../utils';

import FraudCaseStore from '../services/fraudCases.store';
const fraudCaseStore: FraudCaseStore = new FraudCaseStore();

const FraudCasesGenerationForm = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const { DATE_FORMAT_VALIDATION_RULE } = useValidationRules();

  const methods = useForm<FieldValues>({
    defaultValues: {
      dateFrom: '',
      dateTo: '',
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const handleSuccess = async (
    data: Blob,
    dateFrom: string,
    dateTo: string
  ) => {
    const fileUrl = window.URL.createObjectURL(data);

    const link = document.createElement('a');
    link.href = fileUrl;

    // Set the desired filename for the download ADD NAME
    link.download = `${t('fraud_cases.fraud_case_reports')}_${t(
      'global.from'
    )}_${dateFrom}_${t('global.to')}_${dateTo}.pdf`;
    // Trigger the click event to start the download automatically
    link.click();

    // Revoke the Blob URL after download to free up memory
    URL.revokeObjectURL(fileUrl);
  };

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    const formData = new FormData();

    // Iterate over the properties of the `data` object
    Object.keys(data).forEach((key) => {
      // If the field is a date, format it
      if (key.includes('date')) {
        // You can check for specific date field names or conditions here
        formData.append(key, formatToISOTYmd(data[key])); // Assuming `formatToISO` is your date formatting function
      } else {
        formData.append(key, data[key]); // For non-date fields, append them as-is
      }
    });

    const response = await fraudCaseStore.getFraudCaseReports({
      payload: formData,
    });
    const { success, message } = response;

    if (success) {
      const responseData = response.data;
      if (!responseData) return;
      const { dateFrom, dateTo } = data;
      handleSuccess(responseData, dateFrom, dateTo);
      // a place to download data create a dfunv
    }

    showToast(message, success);
    onClose();
  };

  return (
    <>
      <FormProvider {...methods}>
        <form className="pks-layout-col-md" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-2 sm:flex-row">
            <DateRangePicker
              idFrom="dateFrom"
              nameFrom="dateFrom"
              labelFrom={`${t('global.date_from')}*`}
              validationRulesFrom={DATE_FORMAT_VALIDATION_RULE}
              idTo="dateTo"
              nameTo="dateTo"
              labelTo={`${t('global.date_to')}*`}
              validationRulesTo={DATE_FORMAT_VALIDATION_RULE}
            />
          </div>

          <Button
            type="submit"
            wide
            disabled={isSubmitting}
            isDisabled={isSubmitting}
          >
            {isSubmitting
              ? t('state.submitting')
              : t('fraud_cases.generate_report')}
          </Button>
        </form>
      </FormProvider>
    </>
  );
};

export default FraudCasesGenerationForm;
