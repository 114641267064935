import { translate as t } from '../config/i18n.config';
import { EMAIL_REGEX, PASSWORD_REGEX } from './regex';

const REQUIRED_TEXT = t('validation.required');

export const REQUIRED_VALIDATION_RULE = {
  required: REQUIRED_TEXT,
};

export const NUMBERS_VALIDATION_RULES = {
  pattern: {
    value: /^[0-9]+$/,
    message: t('validation.numbers_only'),
  },
};

export const LETTERS_VALIDATION_RULES = {
  pattern: {
    value: /^([A-Za-zČĆŽŠĐLjNjDžčćžšđljnjdžЉЊЏЂЖША-Яа-яёЁ0-9 ]*)$/,
    message: t('validation.letters_only'),
  },
};

export const TITLE_VALIDATION_RULE = {
  ...REQUIRED_VALIDATION_RULE,
  pattern: {
    value: /^[A-Za-zА-Яа-яЂђЖжЉљЊњЏџЧчЋћШшĐđŽžČčĆćŠš0-9\s-]*$/,
    message: t('validation.title_pattern'),
  },
};

export const LETTERS_AND_NUMBERS_VALIDATION_RULES = {
  pattern: {
    value: /^[A-Za-zА-Яа-яЂђЖжЉљЊњЏџЧчЋћШшĐđŽžČčĆćŠš0-9]*$/,
    message: t('validation.letters_and_numbers_only'),
  },
};

export const EMAIL_VALIDATION_RULES = {
  ...REQUIRED_VALIDATION_RULE,
  pattern: {
    value: EMAIL_REGEX,
    message: t('validation.email_invalid'),
  },
};

export const PASSWORD_VALIDATION_RULES = {
  ...REQUIRED_VALIDATION_RULE,
  minLength: {
    value: 12,
    message: t('validation.password_min_length'),
  },
  maxLength: {
    value: 50,
    message: t('validation.password_max_length'),
  },
  pattern: {
    value: PASSWORD_REGEX,
    message: t('validation.password_pattern'),
  },
};

export const BIN_VALIDATION_RULES = {
  ...NUMBERS_VALIDATION_RULES,
  maxLength: {
    value: 8,
    message: t('validation.bin_max_length'),
  },
};

export const ACQUIRER_ID_VALIDATION_RULES = {
  ...REQUIRED_VALIDATION_RULE,
  ...NUMBERS_VALIDATION_RULES,
  maxLength: {
    value: 6,
    message: t('validation.acquirer_id_max_length'),
  },
};

export const MID_VALIDATION_RULES = {
  ...LETTERS_AND_NUMBERS_VALIDATION_RULES,
  maxLength: {
    value: 20,
    message: t('validation.mid_max_length'),
  },
};

export const TID_VALIDATION_RULES = {
  ...LETTERS_AND_NUMBERS_VALIDATION_RULES,
  maxLength: {
    value: 10,
    message: t('validation.tid_max_length'),
  },
};

export const POSTAL_CODE_VALIDATION_RULES = {
  pattern: {
    value: /^\d{5}(-\d{4})?$/,
    message: t('validation.postal_code_invalid'),
  },
};

export const DOMAIN_VALIDATION_RULES = {
  ...REQUIRED_VALIDATION_RULE,
  pattern: {
    value: /^(https?:\/\/)?([a-zA-Z0-9.-]{2,}\.[a-zA-Z]{2,})$/,
    message: t('validation.domain_invalid'),
  },
};

export const PHONE_VALIDATION_RULES = {
  ...REQUIRED_VALIDATION_RULE,
  pattern: {
    value: /^(\+?\d{8,15}|(0|00)?\d{8,15})$/,
    message: t('validation.phone_invalid'),
  },
};

export const MOBILE_PHONE_VALIDATION_RULES = {
  ...REQUIRED_VALIDATION_RULE,
  pattern: {
    value: /^\+?\d{8,15}$/,
    message: t('validation.mobile_phone_invalid'),
  },
};

export const FAX_VALIDATION_RULES = {
  ...REQUIRED_VALIDATION_RULE,
  pattern: {
    value: /^[0-9+()-]{7,20}$/,
    message: t('validation.fax_invalid'),
  },
};

export const regionRoleValidationRules = {
  regionId: {
    required: REQUIRED_TEXT,
  },
  roleIds: {
    validate: (value: any) => {
      // You can perform a custom validation here
      if (!value || value.length === 0) {
        return REQUIRED_TEXT; // Custom error message
      }
      return true;
    },
  },
};
