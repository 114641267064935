import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Meta from '../../components/layout/Meta';
import Button from '../../components/shared/Button';
import NewsForm from '../../modules/news/UI/NewsForm';
import PageLayout from '../../layouts/PageLayout';

import { PermissionsEnum } from '../../types';
import { usePermission } from '../../hooks/usePermission';

function NewsEntryPage() {
  const { t } = useTranslation();
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  /* Page Title */
  const pageTitle = id ? t('news.edit_news') : t('news.add_new_news');

  /* Permissions */
  const canViewNewsData = usePermission([PermissionsEnum.NewsData]);
  const canCreateNews = usePermission([PermissionsEnum.NewsCreate]);
  const showForm =
    (!id && canCreateNews === true) || (id && canViewNewsData === true);

  const handleBack = () => {
    const backTo = location.state?.backTo || '/education/news';
    navigate(backTo);
  };

  return (
    <>
      <span className="mb-8 flex gap-2 items-center w-fit justify-center b sm:flex-none focus:outline-none group">
        <Button onClick={handleBack} type="button" variant="back" tabIndex={-1}>
          {t('news.back_to_news')}
        </Button>
      </span>
      <Meta title={pageTitle} />
      {showForm && (
        <PageLayout title={pageTitle}>
          <NewsForm />
        </PageLayout>
      )}
    </>
  );
}

export default NewsEntryPage;
