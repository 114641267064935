import { useEffect } from 'react';
import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Form, useNavigate, useSearchParams } from 'react-router-dom';

import Button from '../../../components/shared/Button';
import Input from '../../../components/shared/Input';

import { LOGIN_PAGE_LINK } from '../../../constants';
import { useValidationRules } from '../../../hooks/useValidation';
import {
  extractMultipleErrors,
  hasNestedObjects,
  showToast,
} from '../../../utils';

import AuthStore from '../services/auth.store';
const authStore: AuthStore = new AuthStore();

const CreatePassword: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { PASSWORD_VALIDATION_RULES } = useValidationRules();
  const methods = useForm({
    defaultValues: {
      token: '',
      newPassword: '',
      confirmPassword: '',
    },
  });

  useEffect(() => {
    const token = searchParams.get('token');
    if (token) setValue('token', token);
  }, []);

  const {
    handleSubmit,
    formState: { isSubmitting, isValid },
    setValue,
    watch,
    setError,
  } = methods;

  const onSubmit: SubmitHandler<FieldValues> = async (data: FieldValues) => {
    if (isValid) {
      const response = await authStore.createPassword(
        data.token,
        data.newPassword,
        data.confirmPassword
      );
      const { success, message } = response;

      if (success) {
        const resData = response.data;
        if (!resData) {
          showToast(message, !success);
          return;
        }
        showToast(message, true);
        navigate(LOGIN_PAGE_LINK);
      } else {
        if (hasNestedObjects(message)) {
          // Set input fields errors
          const errorMessages = extractMultipleErrors(message);
          for (const key in errorMessages) {
            setError(key as 'newPassword' | 'confirmPassword', {
              type: 'backend',
              message: errorMessages[key],
            });
          }
        } else {
          // Show toast error
          showToast(message, success, true);
        }
      }
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <Form
          method="post"
          className="pks-layout-col-xl w-full"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="pks-layout-col">
            <Input
              type="password"
              id="newPassword"
              name="newPassword"
              label={`${t('inputs.password.label')} *`}
              placeholder={t('inputs.password.placeholder')}
              autoFocus
              autoComplete="off"
              validationRules={PASSWORD_VALIDATION_RULES}
            />
            <Input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              label={`${t('inputs.confirm_password.label')} *`}
              placeholder={t('inputs.confirm_password.placeholder')}
              autoComplete="off"
              validationRules={{
                ...PASSWORD_VALIDATION_RULES,
                validate: (value: string): any => {
                  if (watch('newPassword') !== value) {
                    return t('validation.password_equality');
                  }
                },
              }}
            />
          </div>
          {/* Submit Button */}
          <Button type="submit" wide disabled={isSubmitting}>
            {isSubmitting ? t('state.submitting') : t('state.submit')}
          </Button>
        </Form>
      </FormProvider>
    </>
  );
};

export default CreatePassword;
