import { useTranslation } from 'react-i18next';
import {
  LoaderFunctionArgs,
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import Button from '../../../components/shared/Button';
import PageTitleWithActions from '../../../layouts/PageLayout/TitleWithActions';
import FraudCaseSingleDetails from './FraudCaseSingleDetails';
import FraudCaseSingleComments from './FraudCaseSingleComments';
import FraudCaseSingleReports from './FraudCaseSingleReports';

/* import { formatDateWithPoints } from '../../../utils'; */
import { usePermission } from '../../../hooks/usePermission';
import { PermissionsEnum } from '../../../types';
import { hasPermission } from '../../../utils';

import FraudCaseStore from '../services/fraudCases.store';
const fraudCaseStore: FraudCaseStore = new FraudCaseStore();

const FraudCaseSingle = () => {
  const { id } = useParams();
  const fraudCaseDetailsData: any = useLoaderData();
  const { t } = useTranslation();
  /* Permissions */
  const canViewCommentsData = usePermission([
    PermissionsEnum.FraudcaseCommentsList,
  ]);
  const canAddComment = usePermission([PermissionsEnum.FraudcaseCommentAdd]);
  const canDownloadReport = usePermission([
    PermissionsEnum.FraudCaseReportData,
  ]);

  const navigate = useNavigate();
  const location = useLocation();

  const handleBack = () => {
    const backTo = location.state?.backTo || '../';
    navigate(backTo);
  };

  return (
    <>
      <div className="">
        <span className="flex gap-2 items-center w-fit justify-center b sm:flex-none focus:outline-none group">
          <Button
            onClick={handleBack}
            type="button"
            variant="back"
            tabIndex={-1}
          >
            {t('global.back_to_list')}
          </Button>
        </span>
      </div>
      <div className="flex flex-col gap-8 lg:flex-row">
        <div className="pks-layout-col-xl lg:flex-1 lg:pr-8 lg:border-r lg:border-transparent">
          {/* Page Title With Actions */}
          <PageTitleWithActions title={fraudCaseDetailsData?.title}>
            {canDownloadReport && id && (
              <FraudCaseSingleReports
                id={id}
                title={fraudCaseDetailsData?.title}
              />
            )}
          </PageTitleWithActions>

          {/* Fraud Case */}
          <FraudCaseSingleDetails id={id} data={fraudCaseDetailsData} />
        </div>

        {(canViewCommentsData || canAddComment) && (
          <div className="lg:w-[358px] py-4 border-t border-t-secondary-400">
            <div className="pks-layout-col-xl">
              <FraudCaseSingleComments id={id} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default FraudCaseSingle;

export async function loader({ params }: LoaderFunctionArgs) {
  const id = Number(params.id);
  if (!hasPermission([PermissionsEnum.FraudcaseData])) {
    return null;
  }
  const data = await fraudCaseStore.preloadFraudCase(id);
  return data;
}
