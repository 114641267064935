import { useEffect, useState } from 'react';
import { getPermissionsFromLocalStorage } from '../utils';
import { PermissionsEnum } from '../types';
import { useRegions } from '../context/regions-context';

// Hook to track permission changes
function usePermission(requiredPermissions: PermissionsEnum[]) {
  const { isRefetching } = useRegions();
  const [hasPerm, setHasPerm] = useState<boolean | null>(() =>
    checkPermissions(requiredPermissions)
  );

  function checkPermissions(requiredPermissions: PermissionsEnum[]): boolean {
    const { allPermissions, permissions } = getPermissionsFromLocalStorage();
    if (allPermissions) return true;
    if (!permissions) return false;
    return requiredPermissions.some((permission) =>
      permissions.includes(permission)
    );
  }

  useEffect(() => {
    if (!isRefetching) {
      const updatedPerm = checkPermissions(requiredPermissions);
      if (updatedPerm !== hasPerm) {
        setHasPerm(updatedPerm);
      }
    } else {
      setHasPerm(null);
    }
  }, [hasPerm, isRefetching, requiredPermissions]);

  return hasPerm;
}

export { usePermission };
