import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import AuthContentWithRedirect from './AuthContentWithRedirect';
import FloatingActionButton from '../FloatingActionButton';
import Footer from '../../components/layout/Footer';
import InactivityModal from '../InactivityModal';
import MainNavigation from '../../components/layout/MainNavigationNew';
import PageLoader from '../PageLayout/Loader';

import { useAuth } from '../../context/auth-context';
import { useRegions } from '../../context/regions-context';

import { globalWS } from '../../init';
import { hasPermission } from '../../utils';
import { PermissionsEnum } from '../../types';

/**
 * AuthLayout Component
 *
 * This component handles the layout for authenticated users. It uses context and hooks to
 * manage authentication state and conditional rendering based on that state.
 *
 * @component
 * @returns {React.JSX.Element} - The JSX element representing the authenticated layout.
 */

const AuthLayout = (): React.JSX.Element => {
  // State to determine if redirection to login should occur
  const [shouldRedirect, setShouldRedirect] = useState<boolean | null>(null);

  // Destructuring auth-related states and functions from the custom hook
  const { isAuth, user, userId, updateAuthState, token } = useAuth();
  const canListUsers = hasPermission([PermissionsEnum.UserData]);

  const { availableRegionsData } = useRegions();
  const hasAvailableRegionsDropdown = availableRegionsData?.regions?.length
    ? true
    : false;

  const location = useLocation();
  const hasNoFooterPages = ['/chat'];
  const hasNoContainerPages = ['/chat'];
  useEffect(() => {
    const initializeConnection = async () => {
      if (!isAuth) {
        updateAuthState();
        setShouldRedirect(true);
      } else {
        setShouldRedirect(false);
        try {
          if (
            token &&
            userId &&
            availableRegionsData?.activeRegion &&
            canListUsers
          ) {
            await globalWS.connect(
              token,
              userId,
              String(availableRegionsData?.activeRegion)
            );
          }
        } catch (error) {
          console.error('WebSocket connection or login failed:', error);
        }
      }
    };

    initializeConnection();
  }, [
    availableRegionsData,
    isAuth,
    token,
    updateAuthState,
    userId,
    canListUsers,
  ]);

  if (shouldRedirect === null) return <></>;

  // Conditional rendering of content based on authentication state
  let content = <>{shouldRedirect && <AuthContentWithRedirect />}</>;

  const contentClassName = `
  ${
    hasNoContainerPages.includes(location.pathname)
      ? `lg:relative w-full h-screen
   ${hasAvailableRegionsDropdown ? 'pt-36' : 'pt-28'}

      `
      : `pks-container  flex flex-col gap-8 lg:flex-row   pb-8 md:pb-12 lg:pt-40 lg:pb-40   min-h-screen
   ${hasAvailableRegionsDropdown ? 'pt-36' : 'pt-28'}
      `
  }




`.trim();

  // If the user is authenticated, render the main content and navigation
  if (isAuth) {
    content = (
      <>
        <div className="bg-secondary-50">
          <MainNavigation userName={user?.name} userId={userId} />
          <div className={contentClassName}>
            <AuthContentWithRedirect isAuth={isAuth} />
          </div>
          {!hasNoFooterPages.includes(location.pathname) && <Footer />}
        </div>
        <FloatingActionButton />
        <InactivityModal />
      </>
    );
  }

  // Render content
  return (
    <>
      <PageLoader init />
      {content}
    </>
  );
};

export default AuthLayout;
