import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoaderFunctionArgs, useSearchParams } from 'react-router-dom';

import DropDownSelect from '../../../components/shared/DropdownSelect';
import Pagination from '../../../components/shared/Pagination';
import FraudCasesList from './FraudCasesList';

import { DropDownItemInterface, PermissionsEnum } from '../../../types';
import { hasPermission } from '../../../utils';

import { usePermission } from '../../../hooks/usePermission';
import FraudCaseStore from '../services/fraudCases.store';

const fraudCasesStore: FraudCaseStore = new FraudCaseStore();

const FraudCases = ({
  onTitleChange,
}: {
  onTitleChange: (title: string) => void;
}) => {
  const { t } = useTranslation();
  // Permissions
  const canListFraudCaseTypes = usePermission([
    PermissionsEnum.FraudcaseTypesList,
  ]);

  const [searchParams, setSearchParams] = useSearchParams();

  // Parse URL search parameters
  const page = Number(searchParams.get('page')) || 1;
  const category = searchParams.get('category') || '';
  const organization = searchParams.get('organizationIds[]') || '';

  const { data: fraudCasesData } = fraudCasesStore.useGetFraudCases(
    page,
    category,
    organization
  );

  const fraudCases = fraudCasesData?._embedded?.fraudcases || [];
  const currentPage = fraudCasesData?._page || 1;
  const totalPages = fraudCasesData?._page_count || 1;

  const { data: fraudCaseTypesData, isLoading } =
    fraudCasesStore.useGetFraudCasesTypes(canListFraudCaseTypes);

  const [availableFraudCaseOptions, setAvailableFraudCaseOptions] = useState<
    DropDownItemInterface[]
  >([]);

  useEffect(() => {
    if (!fraudCaseTypesData || isLoading) return;

    const fraudCaseOptions: DropDownItemInterface[] = [
      {
        id: 'all',
        label: t('fraud_cases.all_fraud_types'),
      },
      ...(fraudCaseTypesData
        ? Object.keys(fraudCaseTypesData).map((key) => ({
            id: key,
            label: fraudCaseTypesData[key],
          }))
        : []),
    ];

    setAvailableFraudCaseOptions(fraudCaseOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fraudCaseTypesData, isLoading]);

  /**
   * Handles page changes in pagination.
   * Updates the URL with the new page number.
   *
   * @param {number} newPage - The new page number to navigate to.
   */
  const handlePageChange = (newPage: number) => {
    if (newPage.toString() !== currentPage) {
      searchParams.set('page', newPage.toString()); // Update the page parameter

      // Conditionally set category if it exists
      if (category) {
        searchParams.set('category', category);
      }
      if (organization) {
        searchParams.set('organizationIds[]', organization);
      }

      // Update the search parameters in the URL
      setSearchParams(searchParams);
    }
  };

  const selectedCategoryIndex = availableFraudCaseOptions.findIndex(
    (option) => option.id === category
  );

  const pageTitle = availableFraudCaseOptions[selectedCategoryIndex]?.label;

  useEffect(() => {
    onTitleChange?.(pageTitle || '');
  }, [pageTitle, onTitleChange]);

  // Handler for changing the category
  const handleFilterChange = (selectedFilter: string, paramName: string) => {
    searchParams.set('page', '1');
    if (selectedFilter === 'all') {
      searchParams.delete(paramName);
    } else {
      searchParams.set(paramName, selectedFilter);
    }
    setSearchParams(searchParams);
  };

  const handleCategoryChange = (selectedCategory: string) => {
    handleFilterChange(selectedCategory, 'category');
  };

  const handleCategoryDelete = () => {
    searchParams.delete('category');
    searchParams.set('page', '1');
    setSearchParams(searchParams);
  };

  return (
    <>
      {/* Fraud Cases List with Dropdown per Fraud Cases Types  and Pagination */}
      <div className="pks-layout-col-md">
        {canListFraudCaseTypes !== false && (
          <div className=" flex lg:flex-row flex-col items-center lg:gap-8 gap-1 mb-2 w-full">
            <span className="lg:w-6/12 w-full">
              <DropDownSelect
                label={t('fraud_cases.choose_fraud_case_type')}
                options={availableFraudCaseOptions}
                onChange={handleCategoryChange}
                onRemoveFilter={handleCategoryDelete}
                selectedOptionIndex={
                  selectedCategoryIndex > -1 ? selectedCategoryIndex : 0
                }
                isLoading={isLoading}
              />
            </span>
          </div>
        )}
        <FraudCasesList preview={false} data={fraudCases} />
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default FraudCases;

export const loader = async ({ request }: LoaderFunctionArgs): Promise<any> => {
  const { searchParams } = new URL(request.url);
  const page = Number(searchParams.get('page')) || 1;
  const category = searchParams.get('category') || '';
  const organization = searchParams.get('organizationIds[]') || '';

  if (!hasPermission([PermissionsEnum.FraudcasesList])) {
    return null;
  }

  await fraudCasesStore.preloadFraudCases(page, category, organization);
  return null;
};
