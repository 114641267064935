import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Meta from '../../components/layout/Meta';
import PageLayout from '../../layouts/PageLayout';
import FraudCaseForm from '../../modules/fraudCases/UI/FraudCaseForm';
import Button from '../../components/shared/Button';

import { FRAUD_CASE_PATH } from '../../constants';
import { useRedirect } from '../../hooks/useRedirect';
import { usePermission } from '../../hooks/usePermission';
import { PermissionsEnum } from '../../types';

function FraudCaseFormPage() {
  const { t } = useTranslation();
  const pageTitle = t('fraud_cases.fraud_case_entry');
  // Permissions
  const canListFraudCases = usePermission([PermissionsEnum.FraudcasesList]);
  const canCreateFraudCase = usePermission([PermissionsEnum.FraudcaseCreate]);
  const shouldRedirectToFraudCaseList =
    canCreateFraudCase === false && canListFraudCases;

  useRedirect({
    redirect: canCreateFraudCase === false,
    path: shouldRedirectToFraudCaseList ? FRAUD_CASE_PATH : undefined,
    message: shouldRedirectToFraudCaseList
      ? t('fraud_cases.no_permission_to_add_fraud_case')
      : undefined,
  });

  return (
    <>
      <span className="mb-8 flex gap-2 items-center w-fit justify-center b sm:flex-none focus:outline-none group">
        <Link to={FRAUD_CASE_PATH}>
          <Button type="button" variant="back" tabIndex={-1}>
            {t('global.back_to_list')}
          </Button>
        </Link>
      </span>
      <Meta title={pageTitle} />
      <PageLayout title={pageTitle}>
        {canCreateFraudCase && <FraudCaseForm />}
      </PageLayout>
    </>
  );
}

export default FraudCaseFormPage;
