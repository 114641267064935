import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Meta from '../../components/layout/Meta';
import PageLayout from '../../layouts/PageLayout';
import PageTitleWithActions from '../../layouts/PageLayout/TitleWithActions';
import Button from '../../components/shared/Button';
import Organizations from '../../modules/organizations/UI';

import { ORGANIZATION_ADD_PATH } from '../../constants';
import { PermissionsEnum } from '../../types';
import { usePermission } from '../../hooks/usePermission';
import { useRedirect } from '../../hooks/useRedirect';

function OrganizationsPage() {
  const { t } = useTranslation();

  const pageTitle = t('institutions.institutions');
  /* Permissions */
  const canListOrganizations = usePermission([
    PermissionsEnum.OrganizationsList,
  ]);
  const canCreateOrganization = usePermission([
    PermissionsEnum.OrganizationCreate,
  ]);
  const shouldRedirect =
    canListOrganizations === false && canCreateOrganization === false;

  useRedirect({ redirect: shouldRedirect });

  return (
    <>
      <Meta title={pageTitle}></Meta>
      <PageLayout>
        <PageTitleWithActions title={pageTitle}>
          <div className="inline-flex flex-wrap gap-4">
            {canCreateOrganization && (
              <Link
                to={ORGANIZATION_ADD_PATH}
                className="flex-auto focus:outline-none group"
              >
                <Button wide tabIndex={-1}>
                  {t('institutions.add_institution')}
                </Button>
              </Link>
            )}
            {/* <Link
							to={REGIONS_PATH}
							className='flex-auto focus:outline-none group'>
							<Button wide tabIndex={-1}>
								Upravljaj regionima
							</Button>
						</Link> */}
          </div>
        </PageTitleWithActions>
        {canListOrganizations && <Organizations />}
      </PageLayout>
    </>
  );
}

export default OrganizationsPage;
