import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ToggleSwitch from '../../../components/shared/ToggleSwitch';

import { showToast } from '../../../utils';

import ForumMembersStore from '../services/forumMembers.store';
const forumMembersStore: ForumMembersStore = new ForumMembersStore();

const UserStatusToggleSwitch = ({ id }: { id: number }) => {
  const { t } = useTranslation();

  const [checked, setChecked] = useState(false);

  const activateMember = async (id: number) => {
    setChecked(true);

    const { success, message } = await forumMembersStore.activateMember({
      id: id,
    });

    showToast(message, success);
    !success && setChecked(false);
  };

  return (
    <ToggleSwitch
      className="toggle-switch"
      id={id.toString()}
      label={t('forum_members.activate_member')}
      checked={checked}
      onChange={() => activateMember(id)}
    />
  );
};

export default UserStatusToggleSwitch;
