import Icon from '../Icon';
import ToggleSwitch from '../ToggleSwitch';

interface CheckboxAccordionProps {
  groupId: string;
  label: string;
  labelChecked: boolean;
  labelDisabled?: boolean;
  disabledText?: string;
  showToggle?: boolean | null;
  showItemsCount?: boolean;
  items: {
    id: number;
    name: string;
    checked?: boolean;
    disabled?: boolean;
    disabledText?: string;
    showToggle?: boolean | null;
  }[];
  isOpen: boolean;
  onCheckboxChange?: (
    groupId: string,
    itemId: number,
    checked: boolean
  ) => void;
  onLabelCheckboxChange?: (groupId: string, checked: boolean) => void;
  onToggleAccordion: () => void; // Function to handle toggle
}

const CheckboxAccordion: React.FC<CheckboxAccordionProps> = ({
  groupId,
  label,
  labelChecked,
  labelDisabled = false,
  disabledText,
  showToggle = true,
  showItemsCount,
  items,
  isOpen,
  onCheckboxChange,
  onLabelCheckboxChange,
  onToggleAccordion,
}) => {
  const checkedItemCount = items.filter((item) => item.checked).length;

  // Handles `Label` Checkbox
  const handleLabelCheckboxChange = (checked: boolean) => {
    if (onLabelCheckboxChange) {
      onLabelCheckboxChange(groupId, checked);
    }
  };

  // Handles `Item` Checkbox
  const handleItemCheckboxChange = (itemId: number, checked: boolean) => {
    if (onCheckboxChange) {
      onCheckboxChange(groupId, itemId, checked);
    }
  };

  return (
    <div
      className={`pks-card-bg pks-card-bg-hover overflow-hidden rounded-lg cursor-pointer group ${
        isOpen ? 'pks-card-bg-active' : ''
      }`}
      role="region"
      aria-labelledby={`${groupId}-accordion-label`}
    >
      <div className="bg-white">
        <div
          className="flex justify-between px-4 py-3 cursor-pointer w-full group"
          onClick={onToggleAccordion}
          tabIndex={0}
          role="button"
          aria-expanded={isOpen}
          aria-controls={`${groupId}-content`}
        >
          <ToggleSwitch
            checked={labelChecked}
            id={`${groupId}-label-toggle`}
            isTitle
            label={label}
            disabled={labelDisabled}
            disabledText={disabledText}
            showToggle={showToggle}
            onChange={handleLabelCheckboxChange}
          />
          <div className="flex gap-2 items-center">
            {showItemsCount && checkedItemCount > 0 && (
              <span className="flex items-center justify-center w-6 h-6 rounded-full bg-primary-300 text-white text-xs font-bold">
                {checkedItemCount}
              </span>
            )}
            {isOpen ? (
              <Icon
                className="group-hover:text-primary-300"
                name="minus"
                ariaLabel="Collapse"
              />
            ) : (
              <Icon
                className="group-hover:text-primary-300"
                name="plus"
                ariaLabel="Expand"
              />
            )}
          </div>
        </div>
        {isOpen && (
          <ul id={`${groupId}-content`} className="px-4 py-3 cursor-default">
            {items.map((item) => (
              <li key={item.id}>
                <ToggleSwitch
                  checked={item.checked || false}
                  id={`${groupId}-item-${item.id}`}
                  label={item.name}
                  showToggle={showToggle}
                  onChange={(checked) =>
                    handleItemCheckboxChange(item.id, checked)
                  }
                  disabled={item.disabled}
                  disabledText={item.disabledText}
                />
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default CheckboxAccordion;
