import Icon from '../Icon';

interface LabelProps {
  id?: string;
  label: string;
  disabled?: boolean;
  hasLock?: boolean;
  onClick?: () => void;
  title?: string;
}

const Label: React.FC<LabelProps> = ({
  id,
  label,
  onClick,
  disabled = false,
  hasLock = false,
  title = '',
}) => {
  return id ? (
    <label
      htmlFor={id}
      className={`flex gap-2 items-center mb-1 ${
        !disabled ? 'hover:cursor-pointer' : ''
      }`}
      title={title}
    >
      {hasLock && <Icon name="lock" />}
      {label}
    </label>
  ) : (
    <span className="block mb-1" onClick={onClick}>
      {label}
    </span>
  );
};

export default Label;
