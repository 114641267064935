import { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import Icon from '../Icon';

import {
  UploadDetailsInterface,
  UploadFileInterface,
  UploadPercentage,
} from '../../../types';
import { useTranslation } from 'react-i18next';

/**
 * Initial state for upload details.
 * @type {UploadDetailsInterface}
 */
const INIT_UPLOAD_STATE: UploadDetailsInterface = {
  percentage: null,
  error: { status: null, code: undefined },
  files: [],
};

/**
 * Custom hook for managing upload progress state.
 * @returns {{
 *   uploadDetails: UploadDetailsInterface,
 *   handleUploadProgress: (percentage: UploadPercentage, files: UploadFileInterface[]) => void,
 *   handleUploadResponse: ({success: boolean | null, code: number | null | undefined }) => void,
 *   resetUploadProgress: () => void,
 * }}
 */
const useUploadProgress = (): {
  uploadDetails: UploadDetailsInterface;

  handleUploadProgress: (
    percentage: UploadPercentage,
    files?: UploadFileInterface[]
  ) => void;

  handleUploadResponse: ({
    success,
    code,
  }: {
    success: boolean | null;
    code?: number | null | undefined;
  }) => void;

  resetUploadProgress: () => void;
} => {
  const [uploadDetails, setUploadDetails] =
    useState<UploadDetailsInterface>(INIT_UPLOAD_STATE);

  /**
   * Updates the upload progress.
   * @param {number | null} percentage - Current upload progress in percentage.
   * @param {FileDetails[]} [files] - List of uploaded file names.
   */
  const handleUploadProgress = (
    percentage: number | null,
    files?: UploadFileInterface[]
  ) => {
    setUploadDetails((prevState) => ({
      ...prevState,
      error: { status: null, code: undefined }, // Reset error properly
      percentage, // Update percentage
      files, // Update files
    }));
  };

  /**
   * Updates the upload response based on success.
   * @param {boolean} success - Indicates if the upload was successful.
   */
  const handleUploadResponse = ({
    success,
    code,
  }: {
    success: boolean | null;
    code?: number | null | undefined;
  }) => {
    setUploadDetails((prevState) => ({
      ...prevState,
      error: { status: !success, code }, // Corrected 'succes' to 'status'
    }));
  };

  /**
   * Resets the upload progress to initial state.
   */
  const resetUploadProgress = useCallback(() => {
    setUploadDetails(INIT_UPLOAD_STATE);
  }, []);

  return {
    uploadDetails,
    handleUploadProgress,
    handleUploadResponse,
    resetUploadProgress,
  };
};

/**
 * Modal component to display upload progress.
 * @param {UploadDetailsInterface} props - Upload details properties.
 * @returns {JSX.Element} - Rendered modal for upload progress.
 */
const UploadProgressModal = ({
  percentage,
  error,
  files,
  onReset,
  onSubmit,
}: UploadDetailsInterface): JSX.Element => {
  const { t } = useTranslation();
  const dialogRef = useRef<HTMLDialogElement>(null);

  /**
   * Messages to display based on upload status.
   */
  const uploadMessages = {
    success: {
      single: t('documents.document_successfully_saved'),
      multiple: t('documents.documents_successfully_saved'),
    },
    error: {
      single: t('documents.documents_add_failed'),
      multiple: t('documents.documents_add_failed'),
    },
  };

  const uploadRangeClassName = `h-1.5 ${
    error.status === null
      ? 'bg-primary-300'
      : error.status === false
      ? 'bg-success'
      : 'bg-danger'
  }`;

  /**
   * Closes the modal and resets upload progress.
   */
  const handleCloseModal = useCallback(() => {
    onReset?.();
    document.body.classList.remove('overflow-hidden');
    dialogRef.current?.close();
  }, [onReset]);

  /**
   * Closes the modal when clicking outside of it.
   * @param {React.MouseEvent<HTMLDialogElement>} event - The click event.
   */
  const handleBackdropClick = (event: React.MouseEvent<HTMLDialogElement>) => {
    if (event.target === dialogRef.current && error.status !== null) {
      handleCloseModal();
    }
  };

  useEffect(() => {
    dialogRef.current?.showModal();
    document.body.classList.add('overflow-hidden');
  }, []);

  useEffect(() => {
    if (error.status === null) return;

    const timeoutID = setTimeout(() => {
      handleCloseModal();
    }, 2500);

    return () => clearTimeout(timeoutID);
  }, [error, handleCloseModal]);

  return createPortal(
    <dialog
      ref={dialogRef}
      className="pks-card p-4 max-h-[80vh] w-11/12 sm:max-w-[548px] rounded-lg"
      onClose={handleCloseModal}
      onClick={handleBackdropClick}
      aria-labelledby="upload-progress"
      aria-label={t('state.upload_progress')}
    >
      <div className="pks-layout-col-md">
        <h2 id="upload-progress" className="sr-only">
          {t('state.upload_progress')}
        </h2>
        {/* Close Button */}
        <div className="flex justify-end">
          <button
            className={`w-4 py-0.25 ${
              error.status === null
                ? 'text-secondary-200 pointer-events-none'
                : ''
            }`}
            onClick={handleCloseModal}
          >
            <Icon name="cross" />
          </button>
        </div>

        {/* Upload Progress Content */}
        <div className="flex gap-4 sm:gap-8 items-center rounded-lg border border-secondary-200 p-4">
          <Icon
            name={error.status === true ? 'uploadIconDanger' : 'uploadIcon'}
          />

          <div className="flex-1 min-w-0 flex-grow flex-shrink basis-0 flex flex-col gap-1">
            {/* Files Info */}
            <div className="flex gap-3 items-center justify-between">
              <div className="overflow-hidden flex-shrink flex-grow basis-0">
                {files &&
                  files.map((file: UploadFileInterface, index: number) => (
                    <span
                      key={index}
                      className="block font-bold text-ellipsis overflow-hidden whitespace-nowrap"
                    >
                      {file.name}
                    </span>
                  ))}
              </div>
              {error.status === false && (
                <Icon name="checkmark" className="min-w-4" />
              )}
            </div>

            {/* Range */}
            <div className="relative h-1.5 w-full bg-gray-200 rounded-[3px] overflow-hidden">
              <div
                className={uploadRangeClassName}
                style={{ width: `${percentage}%` }}
              />
            </div>

            {/* Errors and Percentage */}
            <div className="flex gap-3 text-sm flex-wrap">
              <div className="flex-1 block">
                {error.status === null && files && files?.length > 0 && (
                  <p>
                    {(() => {
                      const totalSize = files.reduce(
                        (total, file) => total + file.size,
                        0
                      );
                      return Number.isInteger(totalSize)
                        ? `${Math.round(totalSize)} MB`
                        : `${totalSize.toFixed(2)} MB`;
                    })()}
                  </p>
                )}
                {error.status === false && files?.length && (
                  <p>{`${
                    files?.length > 1
                      ? uploadMessages.success.multiple
                      : uploadMessages.success.single
                  }`}</p>
                )}
                {error.status === true && files?.length && (
                  <p>
                    {`${
                      files?.length > 1
                        ? uploadMessages.error.multiple
                        : uploadMessages.error.single
                    }`}
                  </p>
                )}
              </div>
              {error.code !== undefined && error.code !== 400 ? (
                <button
                  onClick={onSubmit}
                  className="flex items-center gap-1 text-primary-300"
                >
                  <span>{t('global.retry')}</span>
                  <Icon name="searchSpinner" className="w-4" />
                </button>
              ) : (
                <span className="block">
                  {percentage !== null ? `${percentage}%` : '0%'}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </dialog>,
    document.getElementById('modal')!
  );
};

export default UploadProgressModal;

export { useUploadProgress, INIT_UPLOAD_STATE };
