import { AxiosError, AxiosResponse } from 'axios';
import {
  keepPreviousData,
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { translate as t } from '../../../config/i18n.config';
import queryClient from '../../../config/tanstackQueryConfig';
import { ActionResponse, ErrorResponse } from '../../../types';
import { handleErrors } from '../../../utils';

import { ResetPasswordFormDataInterface } from './forumMembers.types';

import ForumMembersApi from './forumMembers.api';
const api: ForumMembersApi = new ForumMembersApi();

const QUERY_KEY = 'forum-members';
const QUERY_KEY_AVATAR = 'forum-member-avatar';

/* const MINUTE: number = 1000 * 60; */

class ForumMembersStore {
  /* public forumMembers: any[] = [];
	public forumMember: any = null; */

  preloadForumMembers = async ({
    page,
    searchTerm,
    region,
    organization,
    invalid,
    enabled,
  }: {
    page?: number;
    searchTerm?: string;
    region?: string;
    organization?: string;
    invalid?: boolean;
    enabled?: boolean;
  }) => {
    return await queryClient.fetchQuery(
      forumMembersOptions({
        page,
        searchTerm,
        region,
        organization,
        invalid,
        enabled,
      })
    );
  };

  useGetForumMembers = ({
    page,
    searchTerm,
    region,
    organization,
    invalid,
    enabled,
  }: {
    page?: number;
    searchTerm?: string;
    region?: string;
    organization?: string;
    invalid?: boolean;
    enabled?: boolean;
  }) => {
    return useQuery(
      forumMembersOptions({
        page,
        searchTerm,
        region,
        organization,
        invalid,
        enabled,
      })
    );
  };

  getForumMember = async (id: number): Promise<ActionResponse<any>> => {
    try {
      const res = await api.getForumMember(id);
      const resData = res.data;
      return {
        success: true,
        message: resData?.message || t('auth.password_reset_success'),
        data: resData,
      };
    } catch (error) {
      const { message, success } = handleErrors(error);
      return { message, success };
    }
  };

  preloadForumMember = this.getForumMember;

  addForumMember = async (payload: any): Promise<ActionResponse<any>> => {
    try {
      const res = await api.addForumMember(payload);
      const resData = res.data;
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      return {
        success: true,
        message:
          resData?.message || t('forum_members.member_successfully_added'),
        data: resData,
      };
    } catch (error) {
      const { message, success } = handleErrors(error);
      return { message, success };
    }
  };

  getForumMemberAvatar = async (id: number): Promise<ActionResponse<any>> => {
    try {
      const res = await api.getForumMemberAvatar(id);
      const resData = res.data;
      const imageBlob = new Blob([res.data], {
        type: res.headers['content-type'],
      });

      const imageFile = new File([imageBlob], `avatar_${id}`, {
        type: res.headers['content-type'],
        lastModified: Date.now(),
      });

      return {
        success: true,
        message: resData?.message,
        data: imageFile,
      };
    } catch (error) {
      const { message, success } = handleErrors(error);
      return { message, success };
    }
  };

  setForumMemberAvatar = async (
    id: number,
    payload: any
  ): Promise<ActionResponse<any>> => {
    try {
      const res = await api.setForumMemberAvatar(id, payload);
      const resData = res.data;

      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_AVATAR, id] });

      return {
        success: true,
        message: resData?.message || t('avatar.avatar_successfully_changed'),
        data: resData,
      };
    } catch (error) {
      const resError = error as AxiosError;
      const data = resError?.response?.data;
      const code = resError?.response?.request?.status || null;

      if (data) {
        const { message, success } = data as ErrorResponse;
        const newMessage = message || data;
        return {
          message: newMessage || t('error'),
          success,
          code,
        };
      } else {
        return {
          message: t('error'),
          success: false,
        };
      }
    }
  };

  useGetForumMemberAvatar = ({
    id,
    enabled = true,
  }: {
    id: number;
    enabled?: boolean;
  }): UseQueryResult<any, Error> => {
    return useQuery(forumMemberAvatarOptions({ id, enabled }));
  };

  updateForumMember = async (id: number, payload: any) => {
    try {
      const res = await api.updateForumMember(id, payload);
      const resData = res.data;
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_AVATAR, id] });

      return {
        success: true,
        message:
          resData?.message || t('forum_members.member_successfully_updated'),
        data: resData,
      };
    } catch (error) {
      const resError = error as AxiosError;
      const data = resError?.response?.data;
      const code = resError?.response?.request?.status || null;

      if (data) {
        const { message, success } = data as ErrorResponse;
        const newMessage = message || data;
        return {
          message: newMessage || t('error'),
          success,
          code,
        };
      } else {
        return {
          message: t('error'),
          success: false,
        };
      }
    }
  };

  deleteForumMember = async (id: number) => {
    try {
      const res = await api.deleteForumMember(id);
      const resData = res.data;
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      return {
        success: true,
        message: t('forum_members.delete_forum_member_success'),
        data: resData,
      };
    } catch (error) {
      const { message, success } = handleErrors(error);
      return { message, success };
    }
  };

  setForumMemberRoles = async (id: number, data: any) => {
    try {
      const res = await api.setForumMemberRoles(id, data);
      const resData = res.data;
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      return {
        success: true,
        message: resData?.message || t('roles.roles_successfully_added'),
        data: resData,
      };
    } catch (error) {
      const { message, success } = handleErrors(error);
      return { message, success };
    }
  };

  getForumMemberRole = async ({
    id,
    enabled = true,
  }: {
    id: number;
    enabled?: boolean;
  }) => {
    try {
      if (!enabled) return;

      const res = await api.getForumMemberRole(id);
      const resData = res.data;
      return {
        success: true,
        message: resData?.message || t('roles.roles_successfully_fetched'),
        data: resData,
      };
    } catch (error) {
      const { message, success } = handleErrors(error);
      return { message, success };
    }
  };

  activateMember = async ({ id }: { id: number }) => {
    try {
      const res = await api.activateMember({ id });
      const resData = res.data;
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      const successMessage = t('forum_members.member_successfully_activated');

      return {
        success: true,
        message: successMessage,
        data: resData,
      };
    } catch (error) {
      const { message, success } = handleErrors(error);
      return { message, success };
    }
  };

  setNewPassword = async (
    data: ResetPasswordFormDataInterface
  ): Promise<ActionResponse<any>> => {
    try {
      const res = await api.setNewPassword(data);
      const resData = res.data;

      return {
        success: true,
        message: resData?.message || t('auth.password_reset_success'),
      };
    } catch (error) {
      const resError = error as AxiosError;
      const data = resError?.response?.data;
      const code = resError?.response?.request?.status || null;

      if (data) {
        const { message, success } = data as ErrorResponse;
        const newMessage = message || data;
        return {
          message: newMessage || t('error'),
          success,
          code,
        };
      } else {
        return {
          message: t('error'),
          success: false,
        };
      }
    }
  };
}

export default ForumMembersStore;

/**
 * Returns query options for fetching / prefetching forum memberss.
 */
export const forumMembersOptions = ({
  page = 1,
  searchTerm,
  region,
  organization,
  invalid = false,
  enabled = true,
}: {
  page?: number;
  searchTerm?: string;
  region?: string;
  organization?: string;
  invalid?: boolean;
  enabled?: boolean;
}): UseQueryOptions<any, Error> => ({
  queryKey: [
    QUERY_KEY,
    { page, searchTerm, region, organization, invalid },
  ] as QueryKey,
  queryFn: async (): Promise<any> => {
    const response: AxiosResponse<any> = await api.getForumMembers({
      page,
      searchTerm,
      region,
      organization,
      invalid,
    });
    return response.data;
  },
  enabled: enabled,
  placeholderData: keepPreviousData,
});

export const forumMemberAvatarOptions = ({
  id,
  enabled = true,
}: {
  id?: number;
  enabled?: boolean;
}): UseQueryOptions<any, Error> => ({
  queryKey: [QUERY_KEY_AVATAR, id] as QueryKey,
  queryFn: async (): Promise<any> => {
    try {
      const res: AxiosResponse<any | ErrorResponse> =
        await api.getForumMemberAvatar(id!);
      const imageBlob = new Blob([res.data], {
        type: res.headers['content-type'],
      });
      const imageFile = new File([imageBlob], `avatar_${id}`, {
        type: res.headers['content-type'],
        lastModified: Date.now(),
      });

      return {
        success: true,
        message: t('avatar.avatar_successfully_fetched'),
        data: imageFile,
      };
    } catch (error) {
      const resError = error as AxiosError;
      const data = resError?.response?.data;
      const code = resError?.response?.request?.status || null;

      if (data) {
        const { message, success } = data as ErrorResponse;
        const newMessage = message || data;

        return {
          message: newMessage || t('error'),
          success,
          code,
        };
      } else {
        return {
          message: t('error'),
          success: false,
        };
      }
    }
  },
  enabled: Boolean(id) && enabled,
  placeholderData: undefined,
});
