import {
  keepPreviousData,
  QueryKey,
  queryOptions,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { translate as t } from '../../../config/i18n.config';
import queryClient from '../../../config/tanstackQueryConfig';
import { handleErrors } from '../../../utils';
import RolesApi from './roles.api';

import { ActionResponse } from '../../../types';
import {
  RoleInterface,
  RoleFormInterface,
  RolesResponseDataInterface,
  PermissionsResponseDataInterface,
} from './role.types';

const QUERY_KEY = 'roles';
const QUERY_KEY_PERMISSIONS = 'permissions';
const QUERY_KEY_PERMISSIONS_PER_ROLE = 'permissions-per-role';

const api: RolesApi = new RolesApi();

class RolesStore {
  preloadRoles = async ({
    page,
    enabled = true,
  }: {
    page?: number;
    enabled?: boolean;
  }) => {
    return await queryClient.fetchQuery(rolesQueryObject({ page, enabled }));
  };

  useGetRoles = ({
    page,
    enabled = true,
  }: {
    page?: number;
    enabled?: boolean;
  }) => {
    return useQuery(rolesQueryObject({ page, enabled }));
  };

  addRole = async ({
    name,
    allPermissions,
    status,
  }: RoleFormInterface): Promise<ActionResponse<RoleInterface>> => {
    try {
      const res = await api.addRole({ name, allPermissions, status });
      const resData = res.data;
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      return {
        success: true,
        message: resData?.message || t('roles.role_successfully_added'),
        data: resData,
      };
    } catch (error) {
      const { message, success } = handleErrors(error);
      return { message, success };
    }
  };

  updateRole = async ({
    id,
    name,
    allPermissions,
    status,
  }: RoleFormInterface): Promise<ActionResponse<RoleInterface>> => {
    try {
      const res = await api.updateRole({ id, name, allPermissions, status });
      const resData = res.data;
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      return {
        success: true,
        message: resData?.message || t('roles.role_successfully_edited'),
        data: resData,
      };
    } catch (error) {
      const { message, success } = handleErrors(error);
      return { message, success };
    }
  };

  deleteRole = async (id: number) => {
    try {
      const res = await api.deleteRole(id);
      const resData = res.data;
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      return {
        success: true,
        message: resData?.message || t('roles.role_successfully_deleted'),
        data: resData,
      };
    } catch (error) {
      const { message, success } = handleErrors(error);
      return { message, success };
    }
  };

  useGetPermissions = (page?: number, enabled?: boolean) => {
    return useInfiniteQuery(permissionsInfiniteQueryObject(page, enabled));
  };

  useGetPermissionsPerRole = (id?: number, enabled?: boolean) => {
    return useQuery(permissionsPerRoleQueryObject(id, enabled));
  };

  updateRolePermissions = async (id: number, permissionsIds: number[]) => {
    try {
      const res = await api.updateRolePermissions(id, permissionsIds);
      const resData = res.data;
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_PERMISSIONS_PER_ROLE, { id }],
      });
      return {
        success: true,
        message:
          resData?.message || t('permissions.permissions_successfully_edited'),
        data: resData,
      };
    } catch (error) {
      const { success, message } = handleErrors(error);
      return {
        message: message,
        success,
      };
    }
  };
}

/**
 * Returns query options for fetching roles with a standard query.
 *
 * @param {number} [page] - The page number to fetch. Defaults to `undefined` if not provided.
 * @returns {UseQueryOptions<any, Error>} The query options object for use with `useQuery`.
 */
export const rolesQueryObject = ({
  page,
  enabled = true,
}: {
  page?: number;
  enabled?: boolean;
}): UseQueryOptions<RolesResponseDataInterface, Error> =>
  queryOptions({
    queryKey: [QUERY_KEY, { page }] as QueryKey,
    queryFn: async (): Promise<any> => {
      const response: AxiosResponse<RolesResponseDataInterface> =
        await api.getRoles(page);
      return response.data;
    },
    placeholderData: keepPreviousData,
    enabled: enabled,
  });

/**
 * Returns query options for fetching permissions with an infinite query.
 *
 * @param {number} [page] - The initial page number to fetch. Defaults to `1` if not provided.
 * @returns {UseInfiniteQueryOptions<any, Error>} The query options object for use with `useInfiniteQuery`.
 */
export const permissionsInfiniteQueryObject = (
  page?: number,
  enabled = true
): UseInfiniteQueryOptions<any, Error> => ({
  queryKey: [QUERY_KEY_PERMISSIONS, { page }], // Include page in the query key for caching
  queryFn: async ({ pageParam = page ?? 1 }) => {
    const pageToFetch = typeof pageParam === 'number' ? pageParam : page ?? 1;
    const response: AxiosResponse<PermissionsResponseDataInterface> =
      await api.getPermissions(pageToFetch);
    return response.data;
  },
  initialPageParam: page ?? 1, // Use page or default to 1
  getNextPageParam: (lastPage) => {
    const { _page, _page_count } = lastPage;
    return _page < _page_count ? _page + 1 : undefined;
  },
  enabled: enabled,
});

/**
 * Returns query options for fetching permissions per role with a standard query.
 *
 * @param {number} id - The id of selected role.
 * @returns {UseQueryOptions<any, Error>} The query options object for use with `useQuery`.
 */
export const permissionsPerRoleQueryObject = (
  id?: number,
  enabled = true
): UseQueryOptions<any, Error> =>
  queryOptions({
    queryKey: [QUERY_KEY_PERMISSIONS_PER_ROLE, { id }] as QueryKey,
    queryFn: async (): Promise<any> => {
      const response: AxiosResponse<any> = await api.getPermissionsPerRole(id!);
      return response.data;
    },
    placeholderData: keepPreviousData,
    enabled: enabled && !!id,
  });

export default RolesStore;
