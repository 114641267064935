import {
  keepPreviousData,
  QueryKey,
  queryOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import SearchApi from './search.api';
import {
  SearchApiInterface,
  SearchItemInterface,
  SearchItemSourceInterface,
  SearchResultsInterface,
} from './search.types';
import { AxiosResponse } from 'axios';
import { translate as t } from '../../../config/i18n.config';
import queryClient from '../../../config/tanstackQueryConfig';

const api: SearchApi = new SearchApi();

export const QUERY_KEY = 'search_term';

class SearchStore {
  preloadSearchResults = async ({
    term = '',
    page = 1,
    rowsPerPage = 10,
    regionIds = [],
  }) => {
    return await queryClient.fetchQuery(
      searchQueryObject({
        term,
        page,
        rowsPerPage,
        regionIds,
      })
    );
  };

  useGetSearchResults = ({
    term = '',
    page = 1,
    rowsPerPage = 10,
    regionIds = [],
    enabled = true,
  }: SearchApiInterface) => {
    return useQuery(
      searchQueryObject({
        term,
        page,
        rowsPerPage,
        regionIds,
        enabled,
      })
    );
  };

  getDocument = async (url: string) => {
    try {
      const res: any = await api.getDocument(`${url}/file`);
      const fileUrl = window.URL.createObjectURL(res.data);
      return { url: fileUrl };
    } catch (error) {
      return { error: t('documents.document_loading_failed') };
    }
  };
}

const searchQueryObject = ({
  term,
  page,
  rowsPerPage,
  regionIds,
  enabled = true,
}: SearchApiInterface): UseQueryOptions<SearchResultsInterface, Error> =>
  queryOptions({
    queryKey: [
      QUERY_KEY,
      { term: term.toLowerCase(), page, rowsPerPage, regionIds },
    ] as QueryKey,
    queryFn: async (): Promise<SearchResultsInterface> => {
      const response: AxiosResponse<SearchResultsInterface> =
        await api.getSearchResults({
          term: term.toLowerCase(),
          page,
          rowsPerPage,
          regionIds,
        });

      const total = response.data?.total || 0;
      const hits: SearchItemSourceInterface[] = Array.isArray(
        response.data.hits
      )
        ? (response.data.hits as unknown as SearchItemInterface[]).map(
            (hit: SearchItemInterface) => ({
              id: hit._source.id,
              title: hit._source.title,
              content: hit._source.content,
              type: hit._source.type,
              region: hit._source.region,
              url: hit._source.url,
            })
          )
        : [];

      if (total === 0) {
        return { hits: [], total };
      }

      return { hits, total };
    },
    enabled: enabled === true && term.length > 3,
    placeholderData: keepPreviousData,
  });

export default SearchStore;
