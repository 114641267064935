import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import PageLayout from '../../layouts/PageLayout';
import Button from '../../components/shared/Button';
import Meta from '../../components/layout/Meta';
import ForumMemberForm from '../../modules/forumMembers/UI/ForumMembersForm';

import { FORUM_MEMBERS_PATH } from '../../constants';
import { useAuth } from '../../context/auth-context';
import { usePermission } from '../../hooks/usePermission';
import { PermissionsEnum } from '../../types';

const ForumMemberFormPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { userId } = useAuth();
  const pageTitle = id
    ? t('forum_members.edit_member')
    : t('forum_members.add_member');

  const canViewUserData = usePermission([PermissionsEnum.UserData]);
  const canCreateUser = usePermission([PermissionsEnum.UserCreate]);
  const showForm =
    (!id && canCreateUser === true) || (id && canViewUserData === true);

  return (
    <>
      <span className="mb-8 flex gap-2 items-center w-fit justify-center b sm:flex-none focus:outline-none group">
        <Button
          onClick={() => navigate(FORUM_MEMBERS_PATH)}
          type="button"
          variant="back"
          tabIndex={-1}
        >
          {t('global.back_to_list')}
        </Button>
      </span>
      <Meta title={pageTitle} />
      {showForm && (
        <PageLayout
          className="w-full"
          title={userId?.toString() === id ? undefined : pageTitle}
        >
          <ForumMemberForm />
        </PageLayout>
      )}
    </>
  );
};

export default ForumMemberFormPage;
