import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

import Button from '../../components/shared/Button';
import EducationReports from '../../modules/education/UI';
import Meta from '../../components/layout/Meta';
import PageLayout from '../../layouts/PageLayout';
import PageTitleWithActions from '../../layouts/PageLayout/TitleWithActions';

import { EDUCATION_HEADINGS, EDUCATION_PATH } from '../../constants';
import { PermissionsEnum } from '../../types';
import { usePermission } from '../../hooks/usePermission';
import { useRedirect } from '../../hooks/useRedirect';

function EducationReportsPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { slug } = useParams<{ slug: string }>();
  const currentPage = EDUCATION_HEADINGS.find((heading) => heading.id === slug);
  const pageTitle = currentPage
    ? t(currentPage.title)
    : t('education.education_and_useful_information');

  /* Permissions */
  const canCreateDocument = usePermission([PermissionsEnum.DocumentsCreate]);
  const canListDocuments = usePermission([PermissionsEnum.DocumentsList]);
  const shouldRedirect =
    canCreateDocument === false && canListDocuments === false;

  useEffect(() => {
    !currentPage && navigate(`${EDUCATION_PATH}/${EDUCATION_HEADINGS[0].id}`);
  }, [currentPage, navigate]);

  useRedirect({
    redirect: shouldRedirect,
  });

  return (
    <>
      <Meta title={t('education.education_and_useful_information')} />
      <PageLayout>
        <PageTitleWithActions title={pageTitle}>
          {canCreateDocument && (
            <Link
              to="/education/add-document"
              state={{ currentType: slug }}
              replace
              className="focus:outline-none group"
            >
              <Button tabIndex={-1}>{t('documents.new_document')}</Button>
            </Link>
          )}
        </PageTitleWithActions>
        {canListDocuments && <EducationReports slug={slug} />}
      </PageLayout>
    </>
  );
}

export default EducationReportsPage;
