import http from '../../../utils/http';

import { OrganizationFormInterface } from './organizations.types';

class OrganizationsApi {
  // Fetch Organizations
  async getOrganizations(page?: number, searchTerm?: string) {
    const params = new URLSearchParams();

    if (page) {
      params.append('page', page.toString());
    }

    if (searchTerm) {
      params.append('name', searchTerm);
    }

    // Construct the full URL with the query string
    const url = `/organizations${
      params.toString() ? `?${params.toString()}` : ''
    }`;

    return http.get(url);
  }

  // Fetch Organization
  async getOrganization(id: number) {
    return http.get(`/organizations/${id}`);
  }

  // Add Organization
  async addOrganization(data: OrganizationFormInterface) {
    return http.post(`/organizations`, data);
  }

  // Update Organziation
  async updateOrganization(id: number, data: OrganizationFormInterface) {
    return http.post(`/organizations/${id}`, data);
  }

  async deleteOrganization(id: number) {
    return http.delete(`/organizations/${id}`);
  }

  //Fetch Organization Logo
  async getOrganizationLogo(id: number) {
    return http.get(`/organizations/${id}/logo`, { responseType: 'blob' });
  }
}

export default OrganizationsApi;
