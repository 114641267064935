import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '../Icon';

interface SearchInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value: string;
  placeholder?: string;
  autofocus?: boolean;
  isFetching?: boolean;
  onSearch: (debouncedValue: string) => void;
}

/**
 * SearchInput component for rendering a styled search input field with an icon and debounce mechanism.
 * Handles user input for searching with a built-in debounce feature to prevent excessive updates during typing.
 * Maintains input value consistency even with ongoing API calls.
 *
 * @component
 * @param {SearchInputProps} props - The props object for this component.
 * @param {string} props.value - The current value of the search input.
 * @param {string} [props.placeholder] - The placeholder text displayed in the input.
 * @param {boolean} [props.autofocus=false] - Determines if the input should automatically focus when the component mounts.
 * @param {React.InputHTMLAttributes<HTMLInputElement>} rest - Additional attributes to be applied to the input element.
 * @param {function(string): void} props.onSearch - The function to call with the debounced input value.
 *
 * @returns {JSX.Element} The rendered SearchInput component.
 */
const SearchInput = ({
  value,
  onSearch,
  placeholder,
  autofocus = false,
  isFetching,
  ...rest
}: SearchInputProps): JSX.Element => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState(value);
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    const newValue = e.target.value;
    setInputValue(newValue);

    debounceTimeout.current = setTimeout(() => {
      onSearch(newValue);
    }, 300); // Adjust the debounce delay as needed
  };

  return (
    <div className="relative">
      <input
        type="search"
        value={inputValue}
        onChange={handleChange}
        placeholder={placeholder || `${t('search.placeholder_search')}...`}
        className="pks-input pks-input-initial"
        aria-label="Search"
        autoFocus={autofocus}
        {...rest}
      />
      {isFetching ? (
        <Icon
          name="searchSpinner"
          className="absolute top-0 right-4 h-full flex items-center [&>svg]:h-5 [&>svg]:w-5 animate-spin"
        />
      ) : (
        <Icon
          name="search"
          className="absolute top-0 right-4 h-full flex items-center [&>svg]:h-5 [&>svg]:w-5"
        />
      )}
    </div>
  );
};

export default SearchInput;
