import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useNavigation } from 'react-router-dom';

import TransitionLoader from '../../components/shared/TransitionLoader/TransitionLoader';
import { useRegions } from '../../context/regions-context';

interface PageLoaderProps {
  init?: boolean;
}

/**
 * PageLoader component that displays different loaders based on the state of the page.
 *
 * This component manages two types of loading states:
 * 1. **Initial Loader**: Shown when `init` is `true` and the component is in its initial loading phase.
 * 2. **Transition Loader**: Shown during page transitions when `init` is `false` and the component is not in its initial loading phase.
 *
 * The component also manages the overflow of the body to prevent scrolling during loading.
 *
 * @component
 * @param {PageLoaderProps} props - The props object for this component.
 * @param {boolean} [props.init=false] - Determines if the initial loader should be displayed.
 *
 * @returns {JSX.Element} The rendered PageLoader component.
 */
const PageLoader = ({ init = false }: PageLoaderProps): JSX.Element => {
  const { state } = useNavigation();
  const [isInit, setIsInit] = useState(true);
  const { isRefetching } = useRegions();

  // Simulate a loading state and initialize after 500ms
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsInit(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  // Manage overflow based on initialization state
  useEffect(() => {
    if (init) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }

    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [init]);

  // Loader for the initial state
  const initialLoader = ((init && isInit && state !== 'loading') ||
    isRefetching) && (
    <div className="fixed top-0 left-0 z-50 h-full w-full bg-primary-300 bg-center bg-no-repeat bg-auto pks-loader-img flex items-center justify-center">
      {isRefetching && (
        <span className="font-bold pt-24 text-white">
          Učitavanje izabranog regiona u toku...
        </span>
      )}
    </div>
  );

  // Loader for page transitions
  const transitionLoader = !init && !isInit && state === 'loading' && (
    <TransitionLoader />
  );

  return (
    <>
      {initialLoader &&
        createPortal(initialLoader, document.getElementById('loader')!)}
      {transitionLoader &&
        createPortal(transitionLoader, document.getElementById('loader')!)}
    </>
  );
};

export default PageLoader;
