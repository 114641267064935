import { Link } from 'react-router-dom';

interface WrapperProps {
  children: React.ReactNode;
  canPreview: boolean | null;
  linkProps: {
    to: string;
    state: Record<string, unknown>;
    className: string;
    title?: string;
  };
}

const PermissionLinkWrapper: React.FC<WrapperProps> = ({
  children,
  canPreview,
  linkProps,
}) => {
  if (canPreview) {
    return <Link {...linkProps}>{children}</Link>;
  }
  return <div className={linkProps.className}>{children}</div>;
};

export default PermissionLinkWrapper;
