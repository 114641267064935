import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import pksLogo from '../../../assets/images/logos/logo.png';

interface LogoProps {
  src?: string;
  alt?: string;
  isLink?: boolean;
  className?: string;
  preload?: boolean;
}

const Logo: React.FC<LogoProps> = ({
  src = pksLogo,
  isLink = true,
  className,
  alt,
}) => {
  const { t } = useTranslation();
  const logoImg = (
    <img
      src={src}
      alt={alt || `${t('website.pks')} ${t('image.logo')}`}
      width={154}
      height={70}
      loading="eager"
    />
  );

  if (isLink) {
    return (
      <Link to="/" className={`lg:flex-shrink-0 ${className}`}>
        {' '}
        {/* SWITCH TO HOME */}
        {logoImg}
      </Link>
    );
  }

  return <div className={className}>{logoImg}</div>;
};

export default Logo;
