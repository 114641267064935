import { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import Icon from '../../components/shared/Icon';

import { FRAUD_CASE_ADD_PATH } from '../../constants';
import { usePermission } from '../../hooks/usePermission';
import { PermissionsEnum } from '../../types';

const SCROLL_DELTA = 5;
const SCROLL_THRESHOLD = 80;

const FloatingActionButton = ({ link = FRAUD_CASE_ADD_PATH, text = '' }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const floatingButtonRef = useRef<HTMLAnchorElement>(null);
  const lastScrollYRef = useRef(0);
  const [isShrunk, setIsShrunk] = useState(false);

  /* Permissions */
  const canCreateFraudCase = usePermission([PermissionsEnum.FraudcaseCreate]);

  useEffect(() => {
    const handleScroll = () => {
      if (!canCreateFraudCase) return;

      const currentScrollY = window.scrollY;
      const scrollDelta = Math.abs(currentScrollY - lastScrollYRef.current);

      if (scrollDelta > SCROLL_DELTA) {
        if (
          currentScrollY > lastScrollYRef.current &&
          currentScrollY > SCROLL_THRESHOLD &&
          !isShrunk
        ) {
          setIsShrunk(true);
        } else if (
          currentScrollY < lastScrollYRef.current &&
          currentScrollY <= SCROLL_THRESHOLD &&
          isShrunk
        ) {
          setIsShrunk(false);
        }

        lastScrollYRef.current = currentScrollY;
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [canCreateFraudCase, isShrunk]);

  if (
    location.pathname === '/chat' ||
    location.pathname === link ||
    !canCreateFraudCase
  ) {
    return null;
  }

  return (
    <Link
      to={link}
      ref={floatingButtonRef}
      className={`fixed z-10 right-4 bottom-4 lg:right-5 lg:bottom-5 min-h-12 px-3 py-2.5 text-white rounded-3xl shadow-floatingButton bg-primary-300 hover:bg-primary flex align-center items-center gap-3 ${
        isShrunk ? 'w-12 overflow-hidden' : 'w-auto'
      }`}
    >
      <Icon name="plusRounded" />
      {!isShrunk && (
        <span className={`pr-1.5`}>
          {text || t('fraud_cases.new_fraud_case_link')}
        </span>
      )}
    </Link>
  );
};

export default FloatingActionButton;
