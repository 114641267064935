// import StatusIndicator from '../../../components/shared/StatusIndicator';

import { useTranslation } from 'react-i18next';

const OrganizationPreview = ({ organization, regionName }: any) => {
  const { t } = useTranslation();
  const organizationItemClassName = 'h3 font-normal flex flex-col gap-1';
  const emptyPropText = '/';

  return (
    <ul className="flex flex-col gap-3" id={organization.region_id}>
      <li className={organizationItemClassName}>
        <div className="flex flex-row items-center gap-2 sm:flex-col sm:items-start">
          {/* <div className='w-auto'>
						{organization.status === 'active' || 1 ? (
							<StatusIndicator label={'Aktivna'} active />
						) : (
							<StatusIndicator label={'Neaktivna'} />
						)}
					</div> */}
          <b>{t('inputs.institution.label')}</b>
        </div>{' '}
        {organization.name}
      </li>
      <li className={organizationItemClassName}>
        <b>{t('inputs.address.label')}</b>{' '}
        {organization.address || emptyPropText}
      </li>
      <li className={organizationItemClassName}>
        <b>{t('inputs.city.label')}</b> {organization.city || emptyPropText}
      </li>
      <li className={organizationItemClassName}>
        <b>{t('inputs.postal_code.label')}</b>{' '}
        {organization.postal_code || emptyPropText}
      </li>
      <li className={organizationItemClassName}>
        <b>{t('inputs.state.label')}</b> {organization.country || emptyPropText}
      </li>
      <li className={organizationItemClassName}>
        <b>{t('inputs.domain.label')}</b> {organization.domain || emptyPropText}
      </li>
      <li className={organizationItemClassName}>
        <b>{t('regions.region')}</b> {regionName || emptyPropText}
      </li>
    </ul>
  );
};

export default OrganizationPreview;
