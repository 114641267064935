import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from '../../components/shared/Button';
import TransitionLoader from '../../components/shared/TransitionLoader/TransitionLoader';
import PageLayout from '../../layouts/PageLayout';
import dummyNewsLargeImg from '../../assets/images/dummy/newsLarge.png';

import { NEWS_PATH } from '../../constants';
import { useRedirect } from '../../hooks/useRedirect';
import { usePermission } from '../../hooks/usePermission';
import { formatDateWithPoints } from '../../utils';
import { PermissionsEnum } from '../../types';

import NewsStore from '../../modules/news/services/news.store';
const newsStore = new NewsStore();

interface NewsItem {
  date_created: any;
  title: string;
  description: string;
  publication_datetime: string;
  date: string;
  headline: string;
}

const NewsDetailsPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  /* Permissions */
  const canViewNewsData = usePermission([PermissionsEnum.NewsData]);
  const canViewNewsPhoto = usePermission([PermissionsEnum.NewsPhoto]);
  const shouldRedirect = canViewNewsData === false;
  useRedirect({ redirect: shouldRedirect });

  const [newsItem, setNewsItem] = useState<NewsItem | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const { data: imageQueryData, isLoading: isImageLoading } =
    newsStore.useGetNewsPhoto({
      id: Number(id),
      enabled: id && canViewNewsPhoto && canViewNewsData ? true : false,
    });

  const mainImage =
    (imageQueryData?.success && imageQueryData?.data?.fileUrl) || undefined;

  useEffect(() => {
    if (!canViewNewsData) return;

    const fetchArticle = async () => {
      return await newsStore.getSingleNews(Number(id));
    };

    const fetchData = async () => {
      try {
        const response = await fetchArticle();
        const { success, data } = response;
        if (success) {
          setNewsItem(data);
          setLoading(false);
        } else {
          setError(t('errors.title_error'));
        }
      } catch (error) {
        setError(t('errors.title_error'));
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [canViewNewsData, id]);

  // Decoding for correct view of news
  const htmlDecode = function (content: string): string {
    let e = document.createElement('div');
    e.innerHTML = content;
    return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue || '';
  };

  const handleBack = () => {
    const backTo = location.state?.backTo || '/education/news';
    navigate(backTo);
  };

  useEffect(() => {
    if (error) {
      navigate(NEWS_PATH, { replace: true });
      toast.error(t('news.no_requested_news_in_region'));
    }
  }, [error, navigate, t]);

  return (
    <>
      <span className="mb-8 flex gap-2 items-center w-fit justify-center b sm:flex-none focus:outline-none group">
        <Button onClick={handleBack} type="button" variant="back" tabIndex={-1}>
          {t('news.back_to_news')}
        </Button>
      </span>
      {canViewNewsData && (
        <PageLayout isCms>
          <div className="flex justify-center items-center h-full  w-full">
            {loading ? (
              <TransitionLoader />
            ) : newsItem ? (
              <div className="flex flex-col gap-4 w-full">
                <p className="text-sm">
                  {formatDateWithPoints(
                    newsItem?.publication_datetime || newsItem.date_created.date
                  )}
                </p>
                <h1>{newsItem.title}</h1>
                <h2>{newsItem.headline}</h2>
                {canViewNewsPhoto && (
                  <div>
                    {
                      isImageLoading && (
                        <img
                          src={dummyNewsLargeImg}
                          className="animate-pulse"
                          alt=""
                        />
                      )
                      // <div className='w-ful  aspect-video mb-4 bg-gray-300 rounded'>Loading</div>
                    }
                    {!isImageLoading && (
                      <div className="relative overflow-hidden bg-gray-300 aspect-video">
                        <img
                          className="object-cover animate-fade-in w-full h-full"
                          src={mainImage || dummyNewsLargeImg}
                          alt=""
                        />
                      </div>
                    )}
                  </div>
                )}
                <div className="pks-layout-col-md flex flex-col">
                  <section
                    dangerouslySetInnerHTML={{
                      __html: htmlDecode(newsItem.description) as string,
                    }}
                    className=""
                  />
                </div>
              </div>
            ) : (
              <div className="text-red-500"></div>
            )}
          </div>
        </PageLayout>
      )}
    </>
  );
};

export default NewsDetailsPage;
