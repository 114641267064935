import { useTranslation } from 'react-i18next';
import { NEWS_PAGE_LINK } from '../../../constants';

import NewsList from './List';
import NewsSkeleton from './ListSkeleton';
import InfoMessage from '../../../components/shared/InfoMessage';
import SeeMoreLink from '../../../components/shared/SeeMoreLink';

import { ViewType, ViewEnum } from '../../../types';
import NewsStore from '../services/news.store';
const newsStore = new NewsStore();

/**
 * NewsPreviewProps Interface
 *
 * Defines the props for the NewsPreview component.
 * @interface
 * @property {string} [title] - The title of the news section.
 * @property {ViewType} [view] - The view type for displaying the news, either compact or expanded.
 */
interface NewsPreviewProps {
  title?: string;
  view?: ViewType;
  count?: number;
}

/**
 * NewsPreview Component
 *
 * This component displays a preview of the latest news. It handles
 * fetching the news data, displaying loading and error states, and
 * rendering the news list along with a "See More" link.
 *
 * @param {NewsPreviewProps} props - The props object for the component.
 * @returns {React.JSX.Element} The rendered NewsPreview component.
 */

const NewsPreview: React.FC<NewsPreviewProps> = ({
  title,
  view = ViewEnum.Compact,
  count = 2,
}: NewsPreviewProps): React.JSX.Element => {
  const { t } = useTranslation();
  // Fetch data
  const { data, isLoading, isRefetching, error } = newsStore.useGetNews({});
  // Conditional content
  let content = null;
  if (isLoading || isRefetching) {
    content = <NewsSkeleton view={view} count={count} />;
  } else if (error || !data?._embedded?.news?.length) {
    const message = error ? error.message : t('news.no_new_news_to_display');
    const icon = error ? 'danger' : 'info';
    content = <InfoMessage message={message} icon={icon} />;
  } else if (data?._embedded?.news.length) {
    content = (
      <>
        <NewsList news={data?._embedded?.news.slice(0, count)} view={view} />
        <SeeMoreLink url={NEWS_PAGE_LINK} label={t('news.news_link')} />
      </>
    );
  }

  return (
    <>
      <aside
        className={
          view === ViewEnum.Compact ? 'pks-layout-col-md' : 'pks-layout-col-xl'
        }
      >
        <h3 className="h2">{title || t('news.latest_news_title')}</h3>
        <div className="pks-layout-col-md">{content}</div>
      </aside>
    </>
  );
};

export default NewsPreview;
