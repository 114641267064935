import FraudCasesPreview from '../../modules/fraudCases/UI/FraudCasesPreview';
import StatisticsList from '../../modules/statistics/UI/List';

import { usePermission } from '../../hooks/usePermission';
import { PermissionsEnum } from '../../types';

import StatisticsStore from '../../modules/statistics/services/statistics.store';
const statisticsStore = new StatisticsStore();

const PreviewWithStatisticsSidebar = () => {
  /* Permissions */
  const canListFraudcases = usePermission([PermissionsEnum.FraudcasesList]);
  const canPreviewRegionStatistics = usePermission([
    PermissionsEnum.FraudcaseRegionStatistics,
  ]);

  const { data: statistics } = statisticsStore.useGetStatistics(
    canPreviewRegionStatistics
  );

  return (
    <div className="pks-layout-col-xl">
      {statistics && canPreviewRegionStatistics && (
        <StatisticsList data={statistics} />
      )}
      {canListFraudcases && <FraudCasesPreview count={4} />}
    </div>
  );
};

export default PreviewWithStatisticsSidebar;
