import { To } from 'react-router-dom';
import { MegaMenuProps, MenuDataProps } from '../../../types';
import MainLink from './MainLink';
import { menuData } from './menuData';
import { hasPermission } from '../../../utils';
import { Fragment } from 'react/jsx-runtime';
import { useTranslation } from 'react-i18next';

const MegaMenu = ({
  handleToggle,
  handleToggleSubmenu,
  clicked,
  setIsDrawerOpen,
  clickedSubMenu,
}: MegaMenuProps) => {
  const { t } = useTranslation();
  return (
    <nav className="  lg:h-full ">
      <ul className=" flex flex-col lg:flex-row h-full w-full items-start lg:items-center justify-center  ">
        {menuData.map(
          (
            {
              label,
              href,
              children,
              pathName,
              iconName,
              permissions,
            }: MenuDataProps,
            index
          ) => {
            const showLink = !permissions?.length || hasPermission(permissions);
            return (
              <Fragment key={index}>
                {showLink && (
                  <MainLink
                    to={href as To}
                    iconName={iconName}
                    pathName={pathName}
                    label={t(label)}
                    childrenMenu={children as unknown as MenuDataProps}
                    setIsDrawerOpen={setIsDrawerOpen}
                    onToggle={() => handleToggle && handleToggle(index)}
                    onToggleSubmenu={handleToggleSubmenu}
                    active={clicked === index}
                    clickedSubMenu={clickedSubMenu}
                  />
                )}
              </Fragment>
            );
          }
        )}
      </ul>
    </nav>
  );
};

export default MegaMenu;
