import NewsPreview from '../../modules/news/UI/Preview';
import StatisticsList from '../../modules/statistics/UI/List';

import { PermissionsEnum, ViewEnum } from '../../types';
import { usePermission } from '../../hooks/usePermission';

import StatisticsStore from '../../modules/statistics/services/statistics.store';
const statisticsStore = new StatisticsStore();

const PreviewNewsStatisticsSidebar = () => {
  /* Permissions */
  const canListNews = usePermission([PermissionsEnum.NewsList]);
  const canPreviewRegionStatistics = usePermission([
    PermissionsEnum.FraudcaseRegionStatistics,
  ]);

  const { data: statistics } = statisticsStore.useGetStatistics(
    canPreviewRegionStatistics
  );

  return (
    <div className="pks-layout-col-xl">
      {canListNews && <NewsPreview view={ViewEnum.Detailed} />}
      {canPreviewRegionStatistics && statistics && (
        <StatisticsList data={statistics} />
      )}
    </div>
  );
};

export default PreviewNewsStatisticsSidebar;
